import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { PartnerService } from 'src/app/Service/partner.service';
import { UserService } from 'src/app/Service/user.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';
@Component({
  selector: 'app-partner-new-order',
  templateUrl: './partner-new-order.component.html'
})
export class PartnerNewOrderComponent implements OnInit {
  storedData: any;
  result: any;
  userInfo: any;
  commonObj: any;
  locationData: any = [];
  lockerNotFree = false;
  loadingData = true;
  disableButton = true;
  constructor(
    public _router: Router,
    private readonly _partnerservice: PartnerService,
    private readonly _userService: UserService,
    private readonly _deliverypointservice: DeliverypointsService,
    private readonly _spinner: SpinnerService,
    private readonly _partnerService: PartnerService,
    public _authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {
    this._spinner.show();

    this.storedData = JSON.parse(window.localStorage.getItem('storedValue'));
    this.getPartnerResponse();

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.userInfo = user;
      this.commonObj = this.userInfo?.partner.name;
    });
    
    this.setLocation();
    // this.parterCheck()
    //this.fetchLocation()

  }
  fetchLocation() {
    this._partnerService.fetchPartnerDeliverypointDetails(this.userInfo.partner.id).subscribe((res: any) => {

      if (res.hasCapacity) {
        this.disableButton = false;
      }
      else {
        this.disableButton = true;
        this.loadingData = false;
      }
    }, (error) => {
      this.loadingData = false;
      this.disableButton = true;
    });
  }

  getPartnerResponse() {
    this._partnerService.getPartnerInfo().pipe(map((orders: Array<any>) => {
      return orders.map((order: any) => {
        const retObj = { ...order };
        retObj.orderDate = order.orderDate ? order.orderDate : '';
        retObj.reservePeriodFrom = order.reservePeriodFrom ? order.reservePeriodFrom : '';
        retObj.reservePeriodTo = order.reservePeriodTo ? order.reservePeriodTo : '';
        return retObj;
      });
    }))
      .subscribe(res => {
        this.result = res;
      });
  }
  newOrder() {
    if (this.commonObj && this.commonObj == 'J&J') {
      this._router.navigate(['partnertools/createBarcode/']);
    } else {
      // this._router.navigate(['partnertools/createBarcode'])
      this._router.navigate(['partnertools/createOrder/']);
    }
  }
  parterCheck() {
    this._userService.fetchMany({}).subscribe((res: any) => {
      this.commonObj = res.find(item => item.email == this.userInfo.email);
    });
  }
  editOrder(response: any) {

    this._router.navigate(['partnertools/partnerOrder/', 'edit'], {
      state: { data: response }
    });


  }
  goToOrderDetails(index: any) {
    this._router.navigate(['partnertools/partnerOrder/', index + 1]);
  }

  setLocation() {
    const obj = { checkCapacity: 1 };
    this._spinner.show();
    this._partnerService.fetchPartnerDeliverypointDetailswithPartnerId(this.userInfo.partner.id,obj).subscribe(
      (res: any) => {
        this._spinner.hide();
        if (res.hasCapacity) {
          this.lockerNotFree = false;
          this.disableButton = false;
        }
        else {
          this.lockerNotFree = true;
          this.disableButton = true;
        }
        this.loadingData = false;
      },
      err => {
        this._spinner.hide();
        console.error(err);
        this.loadingData = false;
        this.disableButton = false;
        if (err.error.code === 'DDS_NOT_FOUND') {
          this.lockerNotFree = true;
        }
      }
    );
  }

}
