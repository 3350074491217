import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { PartnerService } from '../../../Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.scss']
})
export class PartnerAddComponent implements OnInit {
  partnerForm: FormGroup;
  submitted: boolean;
  translateData: any;

  constructor(
    private readonly _toastr: ToastrService,
    private readonly _formBuilder: FormBuilder,
    private readonly _appService: AppService,
    private readonly _location: Location,
    public _router: Router,
    private readonly _partnerService: PartnerService,
    private readonly _langtransService: LangtransService,
    private readonly _spinner: SpinnerService
  ) {
    this.partnerForm = this._formBuilder.group({
      partnerName: ['', Validators.required],
      country: ['', Validators.required],
      active: ['', Validators.required],
      courier: ['', Validators.required],
      prsId: ['', Validators.pattern('^[0-9]*$')]
    });
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
  }

  get formValidators() {
    return this.partnerForm.controls;
  }

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'success') {
          this._toastr.success(res);
        }
        if (toastrstatus === 'error') {
          this._toastr.error(res, 'Error');
        }
      }
    );
  }

  onSubmit() {

    this.submitted = true;

    if (this.partnerForm.valid) {
      this._spinner.show();
      const addPartner = {
        'name': this.partnerForm.controls.partnerName.value,
        'country': this.partnerForm.controls.country.value,
        'active': this.partnerForm.controls.active.value,
        'courier': this.partnerForm.controls.courier.value,
        'prsId': parseInt(this.partnerForm.controls.prsId.value)
      };
      this._partnerService.addNewPartner(addPartner).subscribe((res) => {
        if (res) {
          this._spinner.hide();
          this._router.navigate(['/partners']);
          this.langaugeFetch(this.translateData, 'PartnerAdded', 'success');
        }
      }, (err: any) => {
        this._spinner.hide();
        if (err.error && err.error.error) {
          console.error(err.error.error, 'add partner error');
          this.langaugeFetch(this.translateData, 
            'AlreadyExistPartner', 'error');
        } else {
          this.langaugeFetch(this.translateData, 'AddPartnerFailed', 'error');
        }

      });
    }
  }

  cancel(){
    this._location.back();
  }

}
