import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'datatables.net';
@Component({
  selector: 'app-partner-edit-deliverypoints',
  templateUrl: './partner-edit-deliverypoints.component.html',
  styleUrls: ['./partner-edit-deliverypoints.component.scss']
})
export class PartnerEditDeliverypointsComponent implements OnInit {
  showSpinner = false;
  partnerDetails: any;
  partnerDeliverypointDetails: any;
  dpdsByAcCodeData: any;
  dpdsByPostalcodeData: any;
  deleteDeliveryPoint:any;
  model: any = {};
  partnerId: number;
  @ViewChild('closeAcCodeModal') private readonly closeAcCodeModal: ElementRef;
  @ViewChild('closePostalCodeModal') private readonly closePostalCodeModal: 
  ElementRef;
  lockerList: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  requiredField = false;
  lockerNamedropdownList = [];
  dtOptions:Config ={};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  historyOfObj: any;
  langKey: any;
  addAcCodeForm: FormGroup;
  submitted: boolean;
  translateData: any;
  deleteCode: boolean;
  errorInAddDp: any = null;
  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _formBuilder: FormBuilder,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _spinner: SpinnerService,
    private readonly _translateService: TranslateService
  ) {
    this.addAcCodeForm = this._formBuilder.group({
      lockerAcCode: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
    };
    this.lockerList = [];
    this.partnerId = this._activatedRoute.snapshot.params.id;
    this.getPartnerDetails(this.partnerId);
    const parcelLockerNameList = [];
    this.loading(true)
      .then(() => this._deliverypointsService.fetchMany({}))
      .then(res => {
        this.lockerList = res;
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'type',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          allowSearchFilter: true,
          itemsShowLimit: 3,
          closeDropDownOnSelection: true
        };
        for (let i = 0; i < this.lockerList.length; i++) {
          if (this.lockerList[i].lockerName && this.lockerList[i].id) {
            parcelLockerNameList[i] = { 
              id: `${this.lockerList[i].id}`, 
              type: 
            `${this.lockerList[i].id} - ${this.lockerList[i].lockerName}` };
          }
        }
        this.lockerNamedropdownList = parcelLockerNameList;
      })
      .catch(ex => {
        console.error(ex);
      })
      .finally(() => {
        this.loading(false);
      });

    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
  }

  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    if (state === true) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    return Promise.resolve(state);
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }

  /*
  * Functionality used to fetch partner details
  */
  getPartnerDetails(partnerId): void {
    this.showSpinner = true;
    this._partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res;
        this.showSpinner = false;
        this.getPartnerDeliverypointDetails(partnerId);
      },
      err => {
        this.showSpinner = false;
        this.translator(this.langKey, 'failedToFetch', false);
        console.error(err);
      }
    );
  }

  /*
  * Functionality used to fetch partner delivery point details
  */
  getPartnerDeliverypointDetails(partnerId) {
    this.loading(true);
    this._partnerService.fetchPartnerDeliverypointDetails(partnerId).subscribe(
      res => {
        this.partnerDeliverypointDetails = res;
        if(this.partnerDeliverypointDetails && 
          this.partnerDeliverypointDetails.dpdsByAcCode &&
          this.partnerDeliverypointDetails.dpdsByAcCode.list
        ){
          this.dpdsByAcCodeData = 
          this.partnerDeliverypointDetails.dpdsByAcCode.list;
          this.dpdsByPostalcodeData = 
          this.partnerDeliverypointDetails.dpdsByPostalcode.list;
        }else{
          this.dpdsByAcCodeData =[];
        }
       
        this.loading(false);
      },
      err => {
        this.loading(false);
        this.dpdsByAcCodeData =[];
        this.translator(this.langKey, 'failedToFetch', false);
        console.error(err);
      }
    );
  }

  /*
  * Functionality used to delete AC Code
  */

  get formValidators() {
    return this.addAcCodeForm.controls;
  }

  addAcCode() {
    this.submitted = true;
    if (this.addAcCodeForm.valid === true) {
      const selectedIDs = this.selectedItems.map(item => item.id);
      const reqBody = {
        'purpose': 'add',
        'id': selectedIDs
      };
      this.updateDeliverypointSchemas(reqBody);
      this.resetInputValues();
    }
  }

  setStatus() {
    (this.selectedItems.length > 0) ? 
      this.requiredField = true : this.requiredField = false;
  }

  /*
  * Functionality used to add AC Code
  */
  addPostalCode() {
    const reqBody = {
      'purpose': 'add',
      'postalCode': this.model.postalCode.trim()
    };
    this.updateDeliverypointSchemas(reqBody);
    this.resetInputValues();
  }

  /*
  * Functionality used to add accode
  */
  deleteAcCode(id) {
    const reqBody = {
      'purpose': 'remove',
      'id': id
    };
    this.updateDeliverypointSchemas(reqBody);
  }

  /*
  * Functionality used to delete Postal Code
  */
  deletePostalCode(dpdsByPostalcode) {
    const reqBody = {
      'purpose': 'remove',
      'postalCode': dpdsByPostalcode.trim()
    };
    this.updateDeliverypointSchemas(reqBody);
  }

  /*
  * Functionality used to update partner deliverypoint schemas
  */
  updateDeliverypointSchemas(reqBody) {
    this._partnerService.updatePartnerDeliveryPoints(
      this.partnerId, reqBody).subscribe(
      (res: any) => {
        if (res === 'nlCode updated') {
          this.translator(this.langKey, 'NlCodeUpdate', true);
        }
        else if (res === 'Duplicate NL code not allowed') {
          this.translator(this.langKey, 'NlCodeDuplicate', false);

        }
        else if (this.deleteCode) {
          this.translator(this.langKey, 'ACcoderemovedsuccessfully', true);
          this.deleteCode = false;

        }
        else if(res && ['deliverypointHasOwner','deliverypointAlreadyAssigned'].includes(res.error)){
          this.errorInAddDp = this._translateService.instant(res.error);
          this.errorInAddDp += this._translateService.instant('\n Ids')+': '+res.invalidDeliverypoints.join(',');
        }
        else {
          this.translator(this.langKey, 'NlCodeSuccess', true);
          this.errorInAddDp = null; 
        }
        this.getPartnerDetails(this.partnerId);
        if(this.errorInAddDp == null){
          this.closeAcCodeModal.nativeElement.click();
          this.closePostalCodeModal.nativeElement.click();
        }
      },
      err => {
        if (err.error.error.message === 'Record already present') {
          this.translator(this.langKey, 'ACCodeAlreadyExists', false);
        }
        else {
          this.translator(this.langKey, 'Pleasetryagain', false);
          console.error(err);
        }
      }
    );
  }

  goToPartnerDetails() {
    this._router.navigate(['/partners/', this.partnerId]);
  }

  resetInputValues() {
    this.model = {};
    this.selectedItems = [];
    this.addAcCodeForm.reset();
    this.submitted = false;
    this.errorInAddDp = null;
  }

  getDeliverypointDetails(id: any) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/deliverypoints/${id}/details`])
    );

    window.open(url, '_blank');
  }
  editTableData(data) {
    this.historyOfObj = JSON.stringify(data);
    this.dpdsByAcCodeData.forEach(obj => {
      obj.isShowInput = false;
    });
    data.isShowInput = true;
  }
  saveTableData(data) {
    const beforeObj = JSON.parse(this.historyOfObj);
    const reqBody = {
      'purpose': 'mirroring',
      'id': data.id,
      'partnerId': data.partnerId,
      'lmCapacity': data.LMCapacity ? data.LMCapacity : null
    };
    if (data.nlCode !== beforeObj.nlCode) {
      reqBody['nlCode'] = data.nlCode;
    }
    if(data.nlCode===''){
      reqBody['nlCode'] = null;
    }
    if (data.partnerId) {
      this.updateDeliverypointSchemas(reqBody);
    } else {
      this.translator(this.langKey, 'NlandLmMandatory', false);
    }
  }
  resetTableData(data) {
    data.isShowInput = false;
    const beforeObj = JSON.parse(this.historyOfObj);
    data.nlCode = beforeObj.nlCode;
    data.lmCapacity = beforeObj.lmCapacity;
  }

  saveId(data){
    this.deleteDeliveryPoint=data;
  }
  removeTableData() {
    const data=this.deleteDeliveryPoint;
    this.deleteCode = true;
    this.deleteAcCode(data.deliveryPointId);
  }
  translator(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this._toastr.success(res);
      } else {
        this._toastr.error(res);
      }
    });
  }

}
