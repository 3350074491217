import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReservationserviceService } from '../../Service/reservationservice.service';
import { ParcelService } from '../../Service/parcel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { AppService } from 'src/app/Service/app.service';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, AfterViewInit {

  Barcode: any;
  DeliveryDate: any;
  result: any;
  plainResult: any;
  attempted: boolean;
  hasError: boolean;
  error: any;
  barcodeResult: any;
  submitted: boolean;
  isFirstMile = false;
  displayDate: any;
  public filterForm;
  response: any;
  body: any;
  test: boolean = true;
  userInfo: any;
  @ViewChild('parcelLastmile', { static: false }) parcelLastmile: ElementRef;
  @ViewChild('parcelFirstmile', { static: false }) parcelFirstmile: ElementRef;
  constructor(
    private readonly appService: AppService,
    public router: Router,
    private readonly _reservationservice: ReservationserviceService,
    private readonly _ParcelService: ParcelService,
    private readonly _toastr: ToastrService,
    public _datepipe: DatePipe,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService,
    public _authorizationService: AuthorizationService) { }


  ngOnInit(): void {
    this.filterForm = new FormGroup({
      Barcode: new FormControl('', [Validators.required]),
      DeliveryDate: new FormControl('', [])
    });

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.userInfo = user;
    });

    const currentDate = new Date();
    const formattedDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    this.displayDate = this._datepipe.transform(currentDate, 'dd-MM-yyyy');
    this.filterForm.patchValue({
      DeliveryDate: formattedDate
    });
    
    const routeParams = this._activatedRoute.snapshot.paramMap;
    this.isFirstMile = false;
    const action = routeParams.get('action');
    if (action && action === 'firstmile') {
      this.isFirstMile = true;
    }
  }
  get formControl() {
    return this.filterForm.controls;
  }
  performReservation() {
    this.hasError = false;
    this.error = undefined;
    this.attempted = false;
    this._spinner.show();
    this._reservationservice.performReservation(this.validate()).subscribe(
      res => {
        this._spinner.hide();
        this.attempted = true;
        this.plainResult = JSON.stringify(res);
        this.result = res;
      },
      err => {
        this._spinner.hide();
        this.hasError = true;
        this.error = 'Something went wrong.';
        console.error(err);
      }
    );
  }

  ngAfterViewInit() {
    const parcellatlist = this.parcelLastmile.nativeElement;
    const parcellastliststyle = window.getComputedStyle(parcellatlist);
    const parcellistlastdisplay = parcellastliststyle?.display;

    const parcelfirslatlist = this.parcelFirstmile.nativeElement;
    const parcelfirstliststyle = window.getComputedStyle(parcelfirslatlist);
    const parcellistfirstdisplay = parcelfirstliststyle?.display;

    const routeParams = this._activatedRoute.snapshot.paramMap;
    let firstmile: any;
    const action = routeParams.get('action');
    if (action && action === 'firstmile') {
      firstmile = true;
    }
    else {
      firstmile = false;
    }
    if (firstmile) {
      if (parcellistfirstdisplay === 'none') {
        this.test = false;
      }
    }
    else if (parcellistlastdisplay === 'none') {
      this.test = false;
    }
  }
  performFirstMileReservation = () => {
    let acCode = this.barcodeResult[0].deliveryPoint.acCode;
    const provider = this.barcodeResult[0].deliveryPoint.provider;
    if (provider && provider === 'Lean') {
      this._toastr.info(this._translate.instant(
        'Action only suppported for classic lockers'));
      return;
    }
    this.hasError = false;
    this.error = undefined;
    this.attempted = false;
    const reqObj = this.constructFileMileREquest();
    if (this.barcodeResult && this.barcodeResult.length && 
      this.barcodeResult[0].deliveryPoint) {
      acCode = this.barcodeResult[0].deliveryPoint.acCode;
    }
    this._spinner.show();
    this._reservationservice.performFirstMileReservation(
      reqObj, acCode).subscribe(
      res => {
        this.attempted = true;
        this.plainResult = JSON.stringify(res);
        this.result = res;
        if (this.result.reservation.reservationattemptedat != null) {
          const FMDate = this.result.reservation.reservationattemptedat;
          const firstMileReserveDate = FMDate.split('T');
          this.result.reservation.reservationattemptedat = 
          moment(firstMileReserveDate[0], 'YYYY-M-D').format('DD-MM-YYYY');
        }
        this._spinner.hide();
      },
      err => {
        this._spinner.hide();
        this.hasError = true;
        this.error = 'Something went wrong.';
        this.result = {
          itemBarcode:reqObj.barcode,
          acCode:acCode,
          error:{code:500}
        };
        console.error(err);
      }
    );
  };

  validate() {
    const res = { Barcode: this.filterForm.value.Barcode };
    if (this.filterForm.value.DeliveryDate) { Object.assign(
      res, { DeliveryDate: this.filterForm.value.DeliveryDate }); }
    //new change to set userid in the reservation request
    if (this.userInfo) {
      res['userId'] = this.userInfo.uid;
    }
    return res;
  }

  onSubmit() {
    if (this.isFirstMile) {
      this.performFirstMileReservation();
    } else {
      this.performReservation();
    }

  }
  searchBarcode() {
    if (this.filterForm.valid) {
      this._spinner.show();
      const request = { itemBarcode: this.filterForm.value.Barcode };
      this._reservationservice.checkBarcode(request).then(
        res => {
          this.barcodeResult = res;
          this.result = null;
          this._spinner.hide();
        },
        err => {
          console.error('Error while calling API', err);
          this._spinner.hide();
        }
      );
    } else {
      this.submitted = true;
    }
  }

  constructFileMileREquest : any = () => {
    const reqObj = {
      'purpose': 'PERFORM_RESERVATION',
      'flow': 'FIRSTMILE',
      'barcode': this.filterForm.value.Barcode,
      'source': 'iot-locker',
      'publish': true,
      'userId': this.userInfo.uid,
      'size': {
        'width': 100,
        'height': 100,
        'depth': 100
      }
    };
    return reqObj;
  };

  fmFailCall(reservationId) {
    this.body = {
      text: 'FM-Reservation-Failed'
    };
    this._ParcelService.insertCommentsToDB(reservationId, this.body)
      .subscribe((res) => {
        this.response = res;
      });
  }
}
