<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div [appViewController]="{report:'downTimeReport'}">
  <form [formGroup]="lockerdownForm" class="container mat-card mb-6 mt-4 p-3 shadow bg-white rounded">
    <p class="h4 text-center">{{'Downtime Report'|translate}}</p>
    <div class="row">
      <div class="col-4">
        <label class="form-label">{{'Location'|translate}}</label>
        <ng-multiselect-dropdown 
          class="form-control-sm"
          formControlName="dropDown"
          [placeholder]="'-All-'|translate"
          [settings]="locationDropdownSetttings"
          [data]="locationdropdownList"
          [(ngModel)]="locationSelectedItems">
        </ng-multiselect-dropdown>
        <small
          *ngIf="formValidators.dropDown.errors?.required"
          class="form-text text-muted col-sm-8 pl-0"
        >{{'Please select the location'|translate}}</small>
      </div>
      <div class="col-4">
        <label for="orderDate" class="form-label">{{'Log date from'|translate}}</label>
        <div class="input-group">
          <input
            type="text"
            placeholder="dd-mm-yyyy"
            class="form-control"
            formControlName="logDateFrom"
            [maxDate]="maxDate"
            #logDateFromInput
            bsDatepicker
            [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-calendar-alt" (click)="openDatePicker('logDateFromInput')"></i>
              </span>
            </div>
        </div>
      </div>
      <div class="col-4">
        <label for="orderDate" class="form-label">{{'Log date to'|translate}}</label>
        <div class="input-group">
          <input
            type="text"
            placeholder="dd-mm-yyyy"
            class="form-control"
            formControlName="logDateTo"
            [maxDate]="maxDate"
            #logDateToInput
            bsDatepicker
            [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-calendar-alt" (click)="openDatePicker('logDateToInput')"></i>
              </span>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button class="btn btn-danger me-3" type="submit" (click)="runExport()">{{'Export'|translate}}</button>
        <button class="btn btn-outline-danger" (click)="onReset()">{{'Reset'|translate}}</button>
      </div>
    </div>
  </form>
</div>
