import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-edit-info',
  templateUrl: './partner-edit-info.component.html',
  styleUrls: ['./partner-edit-info.component.scss']
})
export class PartnerEditInfoComponent implements OnInit {

  showSpinner = false;
  partnerDetails: any;
  partnerForm: FormGroup;
  submitted: boolean;
  partnerId: any;

  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _translate: TranslateService
  ) {
    this.partnerForm = this._formBuilder.group({
      partnerName: ['', Validators.required],
      country: ['', Validators.required],
      active: ['', Validators.required],
      prsId:['',Validators.pattern('^[0-9]*$')]
    });
  }

  ngOnInit(): void {
    const partnerId = this._activatedRoute.snapshot.params.id;
    this.partnerId = partnerId;
    this.getPartnerDetails(partnerId);
  }

  get formValidators() {
    return this.partnerForm.controls;
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this._partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res;
        this.showSpinner = false;

        this.partnerForm.patchValue({
          partnerName: this.partnerDetails.name,
          country: this.partnerDetails.country,
          active: this.partnerDetails.active,
          prsId: this.partnerDetails.prsId
        });
      },
      err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      }
    );
  }


  onSubmit() {
    this.submitted = true;
    if (this.partnerForm.valid) {
      const editPartner = {
        'id': parseInt(this.partnerId),
        'active': typeof(this.partnerForm.controls.active.value) == 'boolean' ? 
          (this.partnerForm.controls.active.value).toString() : 
          this.partnerForm.controls.active.value,
        'name': this.partnerDetails.name,
        'prsId': parseInt(this.partnerForm.controls.prsId.value)
      };
      this._partnerService.editPartner(editPartner).subscribe((res) => {
        if (res) {
          this._toastr.success(this._translate.instant(
            'Partner Updated Successfully!'), 
          this._translate.instant('Success'));
        }
      }, (err: any) => {
        this._toastr.info(err.error.message, this._translate.instant('Failed'));
      });
    }
  }

  cancel() {
    this._router.navigate(['/partners/', this.partnerId]);
  }

}
