import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ddsEnvironment, lrsEnvironment, losEnvironment } from './../../environments/environment';
import { AuthorizationService } from '../Service/authorization.service';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  //API_KEY;
  constructor(
    private readonly _authorizationService: AuthorizationService,
    private readonly _toastr: ToastrService,
    private readonly _translateService: TranslateService,
  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): 
  Observable<HttpEvent<any>> {

    // if the url is one of the authid provider (like pingID), just execute it as is !
    /*
      For ac1, there are 4 url to be checked:
      pingAuthorizationUrl: "https://wiam-np.bpost.cloud/as/authorization.oauth2",
      pingTokenUrl: "https://wiam-np.bpost.cloud/as/token.oauth2",
      pingUserProfileUrl: "https://wiam-np.bpost.cloud/idp/userinfo.openid",
      pingLogout: 'https://wiam-np.bpost.cloud/idp/startSLO.ping',
    */
  
    const t = window.localStorage.getItem('token');
    if (t) {
      if (!this.isNLPEndpoint(httpRequest.url) || httpRequest.headers.has('skipInterCeptor') || 
      httpRequest.url.includes('assets/i18n')) {
        const headers = httpRequest.headers.delete('skipInterCeptor');
        return next.handle(httpRequest.clone({ headers }));
      }
      else {
        return next.handle(
          httpRequest.clone({
            setHeaders: {
              'x-api-key': ddsEnvironment.apiKey,
              'token': window.localStorage.getItem('token'),
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Origin': 
              `https://parcellockers-${environment.basic_env}.bpost.cloud`,
              'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
              'Strict-Transport-Security': 
              'max-age=31536000; includeSubDomains',
              'Content-Security-Policy': 'script-src \'self\'',
              'X-XSS-Protection': '1; mode=block',
              'Authorization' :  `Bearer ${t}`,
            }
          })
        )
          .pipe(
            tap((event: HttpEvent<any>) => {
            // Operation failed; error is an HttpErrorResponse
            },
            (_error: any) => {
              console.error(_error);
              if(httpRequest.method !== 'OPTIONS'){
                if(_error.status === 0){
                  this._toastr.error(this._translateService.instant(
                    'Network Error, Please Try again later.'));
                }
              }
            }
            ));
      }
    } else {
      return next.handle(httpRequest);
    }
  }

  isNLPEndpoint(url){
    return url.includes(ddsEnvironment.baseUrl) 
      || url.includes(lrsEnvironment.baseUrl) 
      || url.includes(losEnvironment.baseUrl);
  }
}


