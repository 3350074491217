<app-header></app-header>
<app-lockers-menu [id]="id" ></app-lockers-menu>
<div class="container">
  <div class="row">
    <div class="col-12">

      <form [formGroup]="cameraOperationForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
        <p class="h4 mt-2 text-center">{{'Download Camera Images'|translate}}</p>
        <div class="form-row d-flex ms-5 " >
          <div class="form-group col-md-3 date-picker" >
            <label for="dateFrom">{{'Date From' | translate}}</label>
            <div class="card">         
              <div class="container">
                <div class="row">
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="dd-mm-yyyy"
                      class="form-control"
                      formControlName="dateFrom"
                      [maxDate]="maxDate"
                      #dateFromInput
                      bsDatepicker
                      [bsConfig]="{ isAnimated: true, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' }">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker(dateFromInput)"></i>
                        </span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col text-center mb-3 mt-3">
                <button class="btn btn-outline-danger mb-3 me-3" type="submit" (click)="onSubmit()">{{'Download'| translate}}</button>
                <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'| translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="table-responsive">
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table table-sm mb-3 table-bordered table-hover row-border hover text-center">
          <thead class="table-dark">
          </thead>
        </table>
      </div>
    </div>
  </div>
</div>
