import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/Service/app.service';

@Component({
  selector: 'app-langgtoggle',
  templateUrl: './langgtoggle.component.html'
})
export class LanggtoggleComponent {
  selectedVal: any;
  //selectedVal='en'
  defaultLang: any;
  constructor(
    private readonly _translate: TranslateService,
    private readonly _appService: AppService
  ) {
    _translate.setDefaultLang('en');
    this._appService.languagetoggle$.subscribe(current=> {
      this.selectedVal = current || 'en';
      this._translate.use(this.selectedVal);
    });
  }

  changeLanguage(lang: string) {
    this._appService.languagetoggle$.next(lang);
  }
}
