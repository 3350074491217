import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../Service/authenticaion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { authEnvironmentExternal } from 'src/environments/environment';

@Component({
  selector: 'app-ping-external',
  templateUrl: './ping-external.component.html'
})
export class PingExternalComponent implements OnInit {

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    public _AuthenticationService: AuthenticationService,
    public _router: Router,
  ) { }

  ngOnInit(): void {
    const code = this._activatedRoute.snapshot.queryParams.code;
    if(code) {
      this._AuthenticationService.handleCode();
    }
  }

  signOut(){
    const token = window.localStorage.getItem('token');
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = 
    authEnvironmentExternal.pingLogout + '?id_token='+token+'&TargetResource=' + authEnvironmentExternal.redirect_uri + '&client_id=' + authEnvironmentExternal.client_id;
  }

}
