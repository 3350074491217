import { Component } from '@angular/core';

@Component({
  selector: 'app-un-authorizedpage',
  templateUrl: './un-authorizedpage.component.html'
})
export class UnAuthorizedpageComponent {


}
