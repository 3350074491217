<app-header></app-header>
<form id="form-area" class="login-form" >
  <span>
    <span>
      <img class="logo d-inline-block" src="/assets/img/Bpost_icon.ico" alt="icon">
    </span>
  </span><br><br>
  <br />
</form>

