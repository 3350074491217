<app-header></app-header>
<div class="container-fluid mb-2 mt-2" #myDiv [appViewController]="{users:'showGroup'}">
  <div
    class="col-12 d-flex justify-content-end">
    <button
      class="btn btn-danger"
      (click)="goTogroupadd()"
      [appViewController]="{users:'createGroup'}">{{'Add rights group'|translate}}</button>
  </div>
</div>
<div class="container-fluid">
  <div class="table-responsive">
    <table class="table table-sm table-bordered table-hover row-border hover">
      <caption></caption>
      <thead class="thead-dark text-center">
        <tr>
          <th scope="col">{{'Group Name'|translate}}</th>
          <th scope="col">{{'Functions'|translate}}</th>
          <th scope="col">{{'Actions'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of userGrop">
          <td class="text-center align-middle">
            {{item.title}}
          </td>
          <td class="d-flex flex-row text-nowrap">
            <tr *ngFor="let element of item.checklistData; let i = index" class="w-100">
              <div class="text-center text-bg-secondary h6 border border-dark">
                {{titleKeys[i] | titlecase}}
              </div>
              <div *ngFor="let key of getObjectKeys(element,i)">
                <td class="d-block">
                  <span class="text-adjustment me-2" matTooltip="{{key}}">{{key | titlecase}}</span>
                  <img
                    *ngIf="element[titleKeys[i]][key]"
                    src="../../../../assets/img/greencheck.png"
                    width="20"
                    height="20" alt="greencheck"
                    class="float-right" />
                  <img
                    *ngIf="!element[titleKeys[i]][key]"
                    src="../../../../assets/img/cancel.png"
                    width="20"
                    height="20" alt="greencheck"
                    class="float-right" />
                </td>
              </div>
            </tr>
          </td>
          <td class="align-middle">
            <div class="text-center d-flex mt-2" >
              <button
                type="button"
                class="btn btn-primary w-75" 
                *ngIf="item.title != 'Administrators'"
                (click)="goTogroupedit(item.title)"
                [appViewController]="{users:'modifyGroup'}">{{'Edit'| translate}}</button>
              <button
                type="button"
                class="btn btn-danger ms-5 w-75"
                *ngIf="item.title != 'Administrators'"
                (click)="savedelete(item.title)"
                data-toggle="modal"
                data-target="#deleteUserModal"
                type="button"
                data-toggle="modal"
                data-target="#deleteUserModal"
                [appViewController]="{users:'deleteGroup'}">{{'Delete'| translate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="container">
  <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{'Delete'|translate}}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to delete the user group'|translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">{{'Confirm'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
