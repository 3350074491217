import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../Service/authorization.service';
import { environment} from 'src/environments/environment';
@Component({
  selector: 'app-ping-bpost',
  templateUrl: './ping-bpost.component.html'
})
export class PingBpostComponent implements OnInit  {
  constructor(
    public _authorizationService: AuthorizationService
  ) { }
 
  ngOnInit() {
    if (
      !window.localStorage.getItem('accessToken') &&
      !this._authorizationService.getParameterByName('code', window.location.href)
    ) {
      console.log('1')
      this._authorizationService.loginReuseService();
    } else {
      console.log('2')
      this._authorizationService.getAccessToken();
    }
  }
}

  
