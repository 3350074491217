import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  id: string;
  public DpSettingsForm;
  DpSettings: any;
  showSpinner = true;


  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _toastr: ToastrService,
    private readonly _translate : TranslateService,
    private readonly _router : Router
  ) { }

  ngOnInit(): void {
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this.DpSettingsForm = new FormGroup({
      lidl_image: new FormControl(false,[Validators.required])
    });
    this._deliverypointsService.fetchwithId(this.id).then(res=>{
      this.DpSettings = res;
      if(this.DpSettings.settings && this.DpSettings.settings.lidlEndImage){
        this.DpSettingsForm.patchValue({
          'lidl_image':this.DpSettings.settings.lidlEndImage
        });
      }
      this.showSpinner = false;
    }).catch(ex=>{
      this.showSpinner = false;
      this._toastr.error(this._translate.instant('failedToFetch'),
        this._translate.instant('Failed'));
    });

  }
  onSubmit(){
    this.showSpinner= true;
    const lidl_Check = this.DpSettingsForm.get('lidl_image').value;
    const updateSettings = {'lidlEndImage':lidl_Check};
    this._deliverypointsService.updateLockerSettings(
      this.id, updateSettings).then((response)=>{
      this._toastr.success(this._translate.instant('Locker settings updated'));
      this.showSpinner = false;
    }).catch((error)=>{
      this._toastr.error(this._translate.instant('Failedtoupload'));
      this.showSpinner = false;
      console.error(error);
    }).finally(()=>{
      this._router.navigate(['/deliverypoints/' + this.id]);
    });

  }

}
