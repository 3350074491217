export function utcFormatter(date) {
  if (!date)  { return null; }
  const BelgiumDate = new Date(date);
  const UTCDateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  });
  return UTCDateFormatter.format(BelgiumDate);
}