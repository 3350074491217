import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DeliverypointsService } from '../../Service/deliverypoints.service';
import { LogsService } from '../../Service/logs.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../Service/app.service';
import { ReportsService } from '../../Service/reports.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-lockerdownreport',
  templateUrl: './lockerdownreport.component.html',
  styleUrls: ['./lockerdownreport.component.scss']
})
export class LockerdownreportComponent implements OnInit {
  lockerdownForm: any;
  filterCriteria: { id: number, location: string }[] = [];
  locationdropdownSettings: IDropdownSettings;
  logdetails: any;
  resultarray: any[];
  duration: any;
  locationdropdownList: any[];
  locationDropdownSetttings: IDropdownSettings;
  locationId: any;
  location: any;
  list: { id: number; location: string; }[];
  fromDate: any;
  toDate: any;
  Search: any;
  @ViewChild('logDateFromInput') logDateFromInput: ElementRef;
  @ViewChild('logDateToInput') logDateToInput: ElementRef;
  maxDate = new Date();

  //new objs
  locationSelectedItems = [];
  startDownData: any[];
  endDownData: any[];
  startDown: any[];
  endDown: any[];
  differenceArray: number[] = [];
  //end new objs
  constructor(
    private readonly _http: HttpClient,
    private readonly _appService: AppService,
    private readonly _reportsService: ReportsService,
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _logservice: LogsService,
    private readonly _datePipe: DatePipe,
    private readonly _renderer: Renderer2,
    private readonly _spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this._http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.locationDropdownSetttings = {
          singleSelection: false,
          itemsShowLimit: 3,
          allowSearchFilter: true,
          searchPlaceholderText: data.Search,
          noDataAvailablePlaceholderText: data.nodataText,
          selectAllText: data.selectAll,  
        };
      });
    });
    this.lockerdownForm = new FormGroup({
      dropDown: new FormControl('', [Validators.required]),
      logDateFrom: new FormControl('', []),
      logDateTo: new FormControl('', []),

    });
    this.fetchresponse();
  }
  fetchresponse() {
    this._spinner.show();
    this._deliverypointsService.fetchMany({
      provider: 'bpost'
    })
      .then(res => {
        this._spinner.hide();
        this.list = res.map(locker => {
          return { id: locker.id, text: locker.lockerName, checked: false };
        });
        this.locationdropdownList = this.list;
      })
      .catch(ex => {
        this._spinner.hide();
      });
  }
  
  runExport() {
    this._spinner.show();
    const lockerDown = this.lockerdownForm.value.dropDown;

    const selectedIds = lockerDown.map(selection => selection.id);

    if (this.lockerdownForm.value.logDateFrom) {
      const Date1 = 
      new Date(this.lockerdownForm.value.logDateFrom).setHours(0, 0, 0);
      this.fromDate = this._datePipe.transform(Date1, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.fromDate = null; }
    if (this.lockerdownForm.value.logDateTo) {
      const Date2 = 
      new Date(this.lockerdownForm.value.logDateTo).setHours(23, 59, 59);
      this.toDate = this._datePipe.transform(Date2, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.toDate = null; }

    this._reportsService.lockerDown({
      ids: selectedIds,
      fromdate: this.fromDate,
      todate: this.toDate
    })
      .then((res: any) => {
        this._spinner.hide();
        const fields = ['id', 'terminalId', 'startdate', 
          'enddate', 'reason', 'notified', 'duration', 'title'];

        res = res.map((item: any) => {
        // Object to array
          const arr = [];
          for (const field of fields) {
            arr.push(item[field]);
          }
          return arr;
        });

        const Today = this._datePipe.transform(new Date(), 'dd-MM-yyyy');
        const downtimeReport = `Downtime_Report_${Today}.xls`;
        this.exportToExcel(fields, res, downtimeReport);
      }, err => {
        this._spinner.hide();
        console.error(err);
      });
  }

  exportToExcel(heading, data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([heading, ...data]);
    const colwidth = heading.map(() => ({ wch: 25 }));
    ws['!cols'] = colwidth; // Adjust column widths if needed
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const textColor = { font: { color: { rgb: '0000FF' } } };
    Object.keys(ws).forEach((cell) => {
      if (cell.charAt(0) === 'A') {
        ws[cell].s = textColor;
      }
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }

  convertSeconds(seconds: number): string {
    let days = null;
    if (seconds > 86400) {
      days = Math.floor(seconds / 86400);
      days = days + ' days,';
    }
    const date = new Date(null);
    date.setSeconds(seconds);
    if (days) {
      return `${days} ${date.toISOString().substr(11, 8)}`;
    } else {
      return date.toISOString().substr(11, 8);
    }
  }

  get formValidators() {
    return this.lockerdownForm.controls;
  }

  onReset() {
    this.lockerdownForm.reset();
    Object.keys(this.lockerdownForm.controls).forEach(key => {
      this.lockerdownForm.get(key).setErrors(null);
    });
  }
  openDatePicker(v) {
    if (v === 'logDateFromInput') {
      this._renderer.selectRootElement(
        this.logDateFromInput.nativeElement).click();
    }
    if (v === 'logDateToInput') {
      this._renderer.selectRootElement(
        this.logDateToInput.nativeElement).click();
    }

  }
}
