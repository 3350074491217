import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { LogsService } from '../../../Service/logs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { map } from 'rxjs/operators';
import { LangtransService } from '../../../Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import * as moment from 'moment';
import { Config } from 'datatables.net';
declare var bootstrap:any;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewInit {
  id: any;
  queryStringEvent: any;
  logdetails: any;
  details: any;
  selectedValue: any;
  public filterForm;

  dtOptions: Config ={};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  showUnauthorized = false;
  selectBothdates = false;
  lang: any;
  maxDate: Date;
  @ViewChild('orderDateFromInput') orderDateFromInput:ElementRef;
  @ViewChild('orderDateToInput') orderDateToInput:ElementRef;
  invalidDateRange: boolean;
  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _logservice: LogsService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _appService: AppService,
    private readonly _langtransService: LangtransService,
    private readonly _spinner: SpinnerService,
    private readonly _renderer:Renderer2
  ) {
    this.maxDate=new Date();
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
      search: false,
      order: []
    };
    this.filterForm = new FormGroup({
      logEvent: new FormControl('', []),
      logDateFrom: new FormControl('', []),
      logDateTo: new FormControl('', []),
    });
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data;
    });
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this._spinner.show();
    this._logservice.fetchLogs(this.id)
      .pipe(map((resArray: Array<any>) => {
        return resArray.map((resObj: any) => {
          const dateVal = resObj.date.replace('Z', '');
          const cloneObj = { ...resObj };
          cloneObj.date = dateVal;
          return cloneObj;
        });

      }))
      .subscribe(
        (res: any) => {
          if (res) {
            this.logdetails = res;
            if (this.logdetails.length && this.logdetails.length > 0) {
              this.dtTrigger.next(null);
            } else {
              this.logdetails = [];
            }
          } else {
            this.langaugeFetch(this.lang,'No Matching Data Found !!','info');
          }
          this._spinner.hide();
        },
        err => {
          console.error(err);
          this._spinner.hide();
        }
      );

  }

  ngAfterViewInit() {
    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }
    const tooltipTriggerList=document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(function(tooltipTriggerEl){
      new  bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  onReset() {
    this.invalidDateRange = false;
    const currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
  }
  onSubmit() {
    // Checking if both dates selected or not
    if (this.filterForm.value.logDateFrom === '' && 
      this.filterForm.value.logDateTo === '') {
      this.selectBothdates = true;
    }
    if (this.filterForm.value.logDateFrom !== '') {
      if (this.filterForm.value.logDateTo === '') {
        this.selectBothdates = true;
      }
      else {
        this.selectBothdates = false;
      }
    }

    if (this.filterForm.value.logDateTo !== '') {
      if (this.filterForm.value.logDateFrom === '') {
        this.selectBothdates = true;
      }
      else {
        this.selectBothdates = false;
      }
    }
    this.invalidDateRange = 
    (this.filterForm.value.logDateFrom > this.filterForm.value.logDateTo) && 
    this.filterForm.value.logDateTo !== '';
    if (!this.selectBothdates && !this.invalidDateRange) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 50,
        destroy: true,
        search: false,
        order: []
      };
      this._spinner.show();
      this.rerender();
      this.id = this._activatedRoute.snapshot.paramMap.get('id');
      const queryParams = {
        event: this.filterForm.value.logEvent || undefined,
        fromDate: moment(this.filterForm.value.logDateFrom).format(
          'YYYY-MM-DD') || undefined,
        toDate: moment(this.filterForm.value.logDateTo).format(
          'YYYY-MM-DD') || undefined,
      };
      this._logservice.filterLogs(this.id, queryParams)
        .pipe(map((resArray: Array<any>) => {
          return resArray.map((resObj: any) => {
            const dateVal = resObj.date.replace('Z', '');
            const cloneObj = { ...resObj };
            cloneObj.date = dateVal;
            return cloneObj;
          });
        }))
        .subscribe(
          (res: any) => {
            this._spinner.hide();
            if (res) {
              this.logdetails = res;
              if (res.length && res.length > 0) {
                this.dtTrigger.next(null);
              } else {
                this.logdetails = [];
                this.dtTrigger.next(null);
              }
            } else {
              this.langaugeFetch(this.lang,'No Matching Data Found !!','info');
            }
          },
          err => {
            console.error(err);
            this._spinner.hide();
          }
        );
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then(dtInstance => {
      dtInstance.destroy();
    });
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }
  getMeta = (meta: any) => {
    return JSON.stringify(meta);
  };

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'info') {
          this._toastr.info(res);
        }
      }
    );
  }
  openDatePicker(v){
    if(v==='orderDateToInput'){
      this._renderer.selectRootElement(
        this.orderDateToInput.nativeElement).click();
    }
    if(v==='orderDateFromInput'){
      this._renderer.selectRootElement(
        this.orderDateFromInput.nativeElement).click();
    }
  }
}
