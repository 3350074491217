<body class="bg-dark text-white py-5">
    <div class="container py-5">
         <div class="row">
              <div class="col-md-2 text-center">
                   <p><i class="fa fa-exclamation-triangle fa-5x"></i><br/>Restricted</p>
              </div>
              <div class="col-md-10">
                   <h3>ACCESS FORBIDDEN</h3>
                   <p>Sorry, you dont have access to this page.<br/>Please contact the administrators to provide access to the page.</p>
                  
              </div>
         </div>
    </div>
</body>
