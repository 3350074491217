<app-header></app-header>
<div [appViewController]="{report:'firstmileFailed'}">
    <img src="/assets/img/bg2.jpg" class="bg2" alt="bg2">
<div class="row">
    <div class="col-12">
        <form [formGroup]="reportForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
            <br>
            <p class="h4 text-center bs-custom-mt10 mb-3">{{'First Mile Failed'|translate}}</p>
            <br>
            <div class="row">
                <div class="col-4">
                    <label for="lockername" class="form-label">{{'Country' | translate}}</label>
                    <select name="city" class="form-select"  formControlName='country'>
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.country.errors?.required"
                        class="form-text text-muted col-sm-8 pl-0">{{'Please Fill Country'|translate}}</small>
                </div>
                <div class="col-4">
                    <label for="address" class="form-label">{{'Parcel Aborted'|translate}}</label>
                    <select name="city" class="form-select"  formControlName='softReservation'>
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let reserve of reserves" [value]="reserve.name">{{reserve.name}}</option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.softReservation.errors?.required"
                        class="form-text text-muted col-sm-8 pl-0">{{'Please fill Reserve Lm in Fm safe'|translate}}</small>
                </div>
                <div class="col-4">
                    <label for="acCode"  class="form-label">{{'Business Partners'|translate}}</label>
                    <select name="city"  class="form-select" formControlName='businessesPartner'>
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let business of partnerNamedropdownList" [value]="business.id">{{business.name}}
                        </option>
                    </select>
                    <small *ngIf="submitted &&  formValidators.businessesPartner.errors?.required"
                        class="form-text text-muted col-sm-8 pl-0">{{'Please fill Business Partners'|translate}}</small>
                </div>
            </div>
            <div class="py-2"></div>
            <div class="row">
                <div class="col-4">
                    <label for="lockername" class="form-label">{{'From Date' | translate}}</label>
                          <div class="input-group">
                            <input
                            type="text"
                              placeholder="dd-mm-yyyy"
                              class="form-control"
                              formControlName="fromDate"
                              [maxDate]="maxDate"
                              #fromDateInput
                              bsDatepicker
                              [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fas fa-calendar-alt" (click)="openDatePicker('fromDateInput')"></i>
                                </span>
                              </div>
                          </div>
                       
                    <small *ngIf="submitted &&  formValidators.fromDate.errors?.required"
                        class="form-text text-muted col-8">{{'Please fill From Date'|translate}}</small>
                </div>
                <div class=" col-4">
                    <label for="lockername" class="form-label">{{'To Date' | translate}}</label>
                          <div class="input-group">
                            <input
                              class="bs-custome-h-35"
                              type="text"
                              placeholder="dd-mm-yyyy"
                              class="form-control"
                              formControlName="toDate"
                              [maxDate]="maxDate"
                              #toDateInput
                              bsDatepicker
                              [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fas fa-calendar-alt" (click)="openDatePicker('toDateInput')"></i>
                                </span>
                              </div>
                          </div>
                        
                    <small *ngIf="submitted &&  formValidators.toDate.errors?.required"
                        class="form-text text-muted col-sm-8">{{'Please Fill To Date'|translate}}</small>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <button class="btn btn-danger mx-1 my-3"
                            (click)="exportButton()">{{'Export'|translate}}</button>
                        <button class="btn btn-outline-danger mx-1 my-3" (click)="onReset()">{{'Reset'|translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
