import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../../Service/authorization.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PostmanLoginCodeComponent } from 'src/app/modals/postman-login-code/postman-login-code.component';
import { Subject, Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/Service/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  onlineEvent: Subscription;
  offlineEvent: Subscription;
  subscriptions: Subscription[] = [];
  public userInfo;
  public authorized: boolean;
  public email: string;
  bsModalRef: BsModalRef;
  showDeliverypointHeader: boolean = true;
  showparcelHeader: boolean = true;
  showUserHeader: boolean = true;
  showPostman: boolean = true;
  showReportHeader: boolean = true;
  showpartnerHeader: boolean = true;
  loading: any;
  showSpinner: boolean = false;
  partnerId: number = null;
  @ViewChild('delList', { static: false }) delList: ElementRef;
  @ViewChild('delonBoard', { static: false }) delonBoard: ElementRef;
  @ViewChild('parcelList', { static: false }) parcelList: ElementRef;
  @ViewChild('parcelLastmile', { static: false }) parcelLastmile: ElementRef;
  @ViewChild('parcelFirstmile', { static: false }) parcelFirstmile: ElementRef;
  @ViewChild('userList', { static: false }) userList: ElementRef;
  @ViewChild('userAdd', { static: false }) userAdd: ElementRef;
  @ViewChild('userGroup', { static: false }) userGroup: ElementRef;
  @ViewChild('postMan', { static: false }) postMan: ElementRef;
  @ViewChild('downTime', { static: false }) downTime: ElementRef;
  @ViewChild('firstFail', { static: false }) firstFail: ElementRef;
  @ViewChild('userReport', { static: false }) userReport: ElementRef;
  @ViewChild('partnerlist', { static: false }) partnerlist: ElementRef;
  @ViewChild('partneradd', { static: false }) partneradd: ElementRef;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  constructor(
    public _authorizationService: AuthorizationService,
    public _user: UserService,
    public _router: Router,
    private readonly _modalService: BsModalService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService
  ) {
    this._spinner.showSpinner.subscribe(value => {
      this.showSpinner = value;
    });
    this._user.subject.subscribe(value => {
      if (value) {
        this.hideShow();
      }
    });
    this.checkNetworkStatus();
  }

  ngOnInit() {
    this._authorizationService.currentDdsUser.subscribe(user => {
      if (user) {
        this.authorized = true;
        this.userInfo = user;
        this.email = this.userInfo.Email || this.userInfo.email;
        this.partnerId = user?.partner?.id;
      } else {
        this.authorized = false;
        return;
      }
    });
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if (this.networkStatus !== status) {
          if (status) {
            this._toastr.success(this._translate.instant('You are back Online!'));
          } else {
            this._toastr.error(this._translate.instant('No internet connection!'));
          }
        }
        this.networkStatus = status;
      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  postmanLogin() {
    const initialState = {};
    const modalRef = this._modalService.show(PostmanLoginCodeComponent, {
      initialState,
      backdrop: 'static',
      keyboard: true,
      class: 'modal-md'
    });
    (modalRef.content?.onClose as Subject<any>).subscribe((res) => {
    });
  }

  ngAfterViewInit() {
    this.hideShow();
  }

  hideShow() {
    const dellist = this.delList?.nativeElement;
    const delstyle = window.getComputedStyle(dellist);
    const deldplay = delstyle?.display;

    const delboard = this.delonBoard.nativeElement;
    const delboarstyle = window.getComputedStyle(delboard);
    const delboarddplay = delboarstyle?.display;

    let postMan = this.postMan.nativeElement;
    let postManstyle = window.getComputedStyle(postMan)
    let postMandisplay = postManstyle?.display;

    if (deldplay == 'none' && delboarddplay == "none" && postMandisplay=="none" ) {
      this.showDeliverypointHeader = false
    }

    const parcellist = this.parcelList.nativeElement;
    const parcelliststyle = window.getComputedStyle(parcellist);
    const parcellistdisplay = parcelliststyle?.display;

    const parcellatlist = this.parcelLastmile.nativeElement;
    const parcellastliststyle = window.getComputedStyle(parcellatlist);
    const parcellistlastdisplay = parcellastliststyle?.display;

    const parcelfirslatlist = this.parcelFirstmile.nativeElement;
    const parcelfirstliststyle = window.getComputedStyle(parcelfirslatlist);
    const parcellistfirstdisplay = parcelfirstliststyle?.display;

    if (parcellistdisplay === 'none' && parcellistlastdisplay === 'none' && parcellistfirstdisplay === 'none') {
      this.showparcelHeader = false;
    }

    const userList = this.userList.nativeElement;
    const userListstyle = window.getComputedStyle(userList);
    const userListdisplay = userListstyle?.display;

    const userAdd = this.userAdd.nativeElement;
    const userAddliststyle = window.getComputedStyle(userAdd);
    const userAdddisplay = userAddliststyle?.display;

    const userGroup = this.userGroup.nativeElement;
    const userGroupstyle = window.getComputedStyle(userGroup);
    const userGroupdisplay = userGroupstyle?.display;

    if (userListdisplay === 'none' && userAdddisplay === 'none' && userGroupdisplay === 'none') {
      this.showUserHeader = false;
    }

    if (postMandisplay == 'none') {
      this.showPostman = false
    }

    const downTime = this.downTime.nativeElement;
    const downTimestyle = window.getComputedStyle(downTime);
    const downTimedisplay = downTimestyle?.display;

    const firstFail = this.firstFail.nativeElement;
    const firstFailstyle = window.getComputedStyle(firstFail);
    const firstFaildisplay = firstFailstyle?.display;

    const userReport = this.userReport.nativeElement;
    const userReportstyle = window.getComputedStyle(userReport);
    const userReportdisplay = userReportstyle?.display;

    if (downTimedisplay === 'none' && firstFaildisplay === 'none' && userReportdisplay === 'none') {
      this.showReportHeader = false;
    }

    const partnerlist = this.partnerlist.nativeElement;
    const partnerliststyle = window.getComputedStyle(partnerlist);
    const partnerlistdisplay = partnerliststyle?.display;

    const partneradd = this.partneradd.nativeElement;
    const partneraddstyle = window.getComputedStyle(partneradd);
    const partneradddisplay = partneraddstyle?.display;

    if (partnerlistdisplay === 'none' && partneradddisplay === 'none') {
      this.showpartnerHeader = false;
    }
  }

  signOut() {
    this._authorizationService.signOut();
    localStorage.removeItem('currentLang');
  }
}
