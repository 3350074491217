import { Injectable } from '@angular/core';
import { lrsEnvironment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReservationserviceService {

  constructor(
    private readonly _http: HttpClient
  ) {  }

  performReservation(req: any) {
    return this._http.post(
      `${lrsEnvironment.baseUrl}/test-reservation`, req);
  }
  performFirstMileReservation (req:any,lockerAcCode:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'source_device': lockerAcCode });
    const options = { headers: headers };
    return this._http.post(
      `${lrsEnvironment.baseUrl}/reserve-firstmile`,req,options);
    
  }
  checkBarcode(req: any) {
    return this._http.post(
      `${lrsEnvironment.baseUrl}/parcels?limit=500&offset=0&output=json`, 
      req).toPromise();
  }
}
