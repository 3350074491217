import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/Service/authorization.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public userInfo;
  public authorized: boolean;
  public firstname : string;
  public lastname : string;

  constructor(public _authorizationService: AuthorizationService, ) {}

  ngOnInit(): void {
    this.userInfo = this._authorizationService.currentDdsUser;
    if(this.userInfo){
      this.authorized = true;
      this.firstname = this.userInfo.firstname;
      this.lastname = this.userInfo.lastname;
    }
    else{
      this.authorized = false;
    }
  }

}
