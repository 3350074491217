import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from '../../Service/langtrans.service';
import { AppService } from '../../Service/app.service';
@Component({
  selector: 'app-partner-create-new-order',
  templateUrl: './partner-create-new-order.component.html'
})
export class PartnerCreateNewOrderComponent implements OnInit {

  public newOrderForm;
  submitted = false;
  lang: any;
  constructor(public _router: Router,
    private readonly _partnerservice : PartnerService,
    private readonly _toastr: ToastrService,
    private readonly _appService: AppService,
    private readonly _langtransService: LangtransService,) { }

  ngOnInit(): void {
    this.newOrderForm= new FormGroup({
      email: new FormControl('',[Validators.required,Validators.email]),
      emailConfirm:new FormControl('',[Validators.required,Validators.email])
    });
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data;
    });
  }

  get formValidators() {
    return this.newOrderForm.controls;
  }

  partnerOrder()
  {
    this.submitted=true;
    if(this.newOrderForm.valid)
    {
      if(this.newOrderForm.value.email!==this.newOrderForm.value.emailConfirm)
      {
        this.langaugeFetch(this.lang,
          'Email mismatch, Please try again','warning');
      }
      else{
        this._partnerservice.setEmail(this.newOrderForm.value.email);
        this._router.navigate(['partnertools/partnerOrder/','add']);
      }
    }
  }
  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'warning') {
          this._toastr.warning(res);
        }
      }
    );
  }
}
