import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID, AfterViewInit, OnDestroy } from '@angular/core';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationComponent } from 'src/app/modals/confirmation/confirmation.component';
import * as moment from 'moment';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { Config } from 'datatables.net';

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit, AfterViewInit, OnDestroy {
  id: any;
  dpdetails: any;

  layoutAvailable: boolean;
  nestedArr: any;
  dtOptions: Config ={};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  terminalArr: any;
  sortDir: number;
  compartments: any;
  classList: any;
  compart: any;
  number: any;
  valuesArr: any[];
  key: any;
  reverse = true;
  parcels: any;
  parcelsCount: any;
  reservationsCount: any;
  deliveryCount:any;
  pickupCount:any;
  sortingAllowed = true;
  disableComment: string;
  disabledCompartment: any;
  showUnauthorized = false;
  showSpinner = false;
  error: any;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  constructor(
    @Inject(LOCALE_ID) private readonly _locale: string,
    public _activatedRoute: ActivatedRoute,
    private readonly _deliverypointservice: DeliverypointsService,
    private readonly _modalService: BsModalService,
    private readonly _spinner: SpinnerService
  ) {

  }

  ngOnInit() {
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
      search: false
    };
    this.fetchTerminalDetails();

  }

  ngAfterViewInit() {
    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }
  }

  fetchTerminalDetails() {
    this._spinner.show();
    this._deliverypointservice.fetchwithId(this.id, {
      includeTerminals: true,
      includeCompartments: true
    })
      .then(res => {
        this.dpdetails = res;
        this.terminalArr = this.dpdetails.terminals;
        this.key = 'number';
        if (this.sortingAllowed) {
          this.sort(this.key);
        }
        this.parcels = this.terminalArr[0].compartments;
        this.reservationsCount = 
        this.parcels.filter(obj => obj.status === 'RESERVED').length;
        this.deliveryCount = 
        this.parcels.filter(
          obj => obj.status === 'DELIVERY_LOCKER_FILLED').length;
        this.pickupCount = 
        this.parcels.filter(
          obj => obj.status === 'PICKUP_LOCKER_FILLED').length;

        if (this.dpdetails.length > 0) {
          this.dtTrigger.next(null);
        }

        if (this.dpdetails.type === 'Lean Locker') {
          this.nestedArr = this.dpdetails.terminals;
        }
        this.guessLayoutIsAvailable();
        this._spinner.hide();
      })
      .catch(ex => {
        this._spinner.hide();
        this.error = ex?.error?.error;
        console.error(ex);
      });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  /**
   * Guesses if there is a layout to show based on stats & terminals information
   */
  guessLayoutIsAvailable() {
    this.layoutAvailable =
      this.dpdetails.stats.compartments > 0 &&
      this.dpdetails.terminals.length > 0 &&
      this.dpdetails.terminals.find(t => !!t.compartments.length);
  }



  updateStatus(id: any, x: any, ter: any, com: any, ext: any) {
    let result;
    let initialState = {};
    if (x === 1) {
      result = {
        deliverypoint: id,
        command: 'open',
        terminal: Number(ter),
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type
      };
    } else if (x === 2) {
      result = {
        deliverypoint: id,
        command: 'close',
        terminal: Number(ter),
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type

      };
    } else if (x === 3) {
      result = {
        deliverypoint: id,
        command: 'enable',
        terminal:  Number(ter),
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type

      };
    } else if (x === 4) {
      result = {
        deliverypoint: id,
        command: 'disable',
        terminal:  Number(ter),
        compartment: com,
        ext_id: ext,
        type: this.dpdetails.type,
        remarks: this.disableComment
      };
    }

    initialState = { 'currentCommand': result.command };
    if (result.command === 'open') {
      const modalRef = this._modalService.show(ConfirmationComponent, {
        initialState,
        backdrop: 'static',
        keyboard: true,
        class: 'modal-md'
      });
      (modalRef.content?.onClose as Subject<any>).subscribe((res) => {
        if (res === 'confirm') {

          this.disableComment = '';
          this.deliveryPointUpdateCommand(id, result);
        }
      });
    } else {
      this.deliveryPointUpdateCommand(id, result);
    }
  }

  deliveryPointUpdateCommand(id, result) {
    this._deliverypointservice.updateCommand(id, result).subscribe(
      res => {
        this.sortingAllowed = false;
        this.fetchTerminalDetails();
      },
      err => {
        this.sortingAllowed = true;
        console.error(err);
      }
    );
  }

  sort(key) {
    this.sortingAllowed = true;
    this.key = key;
    this.reverse = !this.reverse;
  }
  disabledValues(id: any, ter: any, com: any, ext: any) {
    this.disabledCompartment = {
      dp: id,
      term: ter,
      comp: com,
      ext_id: ext,
    };
  }
  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) { return null; }
    return moment(formattedDateTime).format('DD-MM-YYYY HH:mm:ss');
  }

  reloadCurrentPage() {
    window.location.reload();
  }
}
