<div class="row mb-3">

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{'Basic details'|translate}}</h4>
        <p class="card-text">{{'Edit details like name and active'|translate}}</p>
        <a [routerLink]="['/partners', partner?.id, 'edit']"  class="card-link">{{'Edit this settings'|translate}}</a>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{'Delivery Points'|translate}}</h4>
        <p class="card-text">{{'Edit details like name and active'|translate}}</p>
        <a [routerLink]="['/partners', partner?.id, 'deliverypoints']" class="card-link">{{'Edit this settings'|translate}}</a>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{'Postman codes'|translate}}</h4>
        <p class="card-text">{{'Edit details like name and active'|translate}}</p>
        <a [routerLink]="['/partners', partner?.id, 'postmans-codes']"  class="card-link">{{'Edit this settings'|translate}}</a>
      </div>
    </div>
  </div>

</div>

<div class="row mb-3">

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{'Postmans'|translate}}</h4>
        <p class="card-text">{{'Edit details like name and active'|translate}}</p>
        <a [routerLink]="['/partners', partner?.id, 'postmans']"  class="card-link">{{'Edit this settings'|translate}}</a>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{'Times'|translate}}</h4>
        <p class="card-text">Edit details like dwell times</p>
        <a [routerLink]="['/partners', partner?.id, 'times']"  class="card-link">{{'Edit this settings'|translate}}</a>
      </div>
    </div>
  </div>

</div>
