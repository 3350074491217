import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../Service/partner.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { TranslateService } from '@ngx-translate/core';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';

@Component({
  selector: 'app-partner-order',
  templateUrl: './partner-order.component.html',
  styleUrls: ['./partner-order.component.scss']
})
export class PartnerOrderComponent implements OnInit {

  showSpinner = false;
  noPackage: any;
  amount: any;
  reference: any;
  location: any;
  queue: any;
  reservePeriodFrom: any;
  reserPeriodFromTime: any;
  reservePeriodTo: any;
  reserPeriodToTime: any;
  barcode: any;
  customerBarcode: any;
  storedValue: any;
  public partenOrderForm;
  action: any;
  btnclick = false;
  isShow = true;
  todos: any;
  locationData: any = [];
  getEmail: any;
  todayDate: any;
  overviewDate: any;
  tomorrowDate: any;
  editResponse: any;
  submitted = false;
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild('reserveDateToInput') reserveDateToInput: ElementRef;
  selectedLocationname: any;
  id: any;
  orderToProcess: any;
  reservationCheck = true;
  editForm = false;
  disablePickupCode = false;
  editBarcode = false;
  lockerNotFree = false;
  whentocollectvalue = '';
  partnerId: any = 1;
  email: any;
  translateData: any;
  language: any;
  customerObj: any;
  customerData: any;
  maxDate: Date;
  userInfo: any;
  
  constructor(
    private FB: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    private readonly _partnerService: PartnerService,
    public _router: Router,
    public _datepipe: DatePipe,
    private readonly _toastr: ToastrService,
    private readonly _route: ActivatedRoute,
    private readonly _translate: TranslateService,
    private readonly _deliverypointservice: DeliverypointsService,
    private readonly _renderer: Renderer2,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _spinner: SpinnerService,
    private readonly _userservice:UserService,
    public _authorizationService: AuthorizationService
    
  ) {
    this.action = this._activatedRoute.snapshot.paramMap.get('action');
    this._route.params.subscribe((data) => {
      this.id = data;
      this.disablePickupCode=this.action =='edit'? true:false;
      if (Object.keys(this.id).length != 0 && data.id) {
        this.editForm=true;
        if (this.id.action == 'process') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getpartnerOrderToProcess(this.id);
        }
        if (this.id.action == 'vault') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getOrderPlacedInVault(this.id);
        }

        if (this.id.action == 'history') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getOrderInHistory(this.id);
        }
        if (this.id.action == 'ironing') {

          this.editForm = false;
          this.editBarcode = true;
        }
      }
    });
    if(this.action==='edit'){
      this.editResponse = this._router.getCurrentNavigation()?.extras?.state?.data;
     
      if(!this.editResponse){
        this._router.navigate(['/partnertools/newOrder']);
      }
      this.getEmail= this.editResponse?.email;
      this.customerObj={
        firstName:this.editResponse?.customers?.firstName,
        lastName:this.editResponse?.customers?.lastName ,
        Email:this.editResponse?.customers?.email ,
        phoneNumber:this.editResponse?.customers?.phoneNumber
      };
    }
  }


  ngOnInit(): void {
    this.meApiCall();

    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
    this._partnerService.editSubject.subscribe(res => {
      if(this.action !== 'edit'){
        this.editResponse = res;
      }
    });

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.userInfo = user;
      this.partnerId = this.userInfo.partner.id ? this.userInfo.partner.id : 1;
      if (this.editResponse && this.editResponse.orderDate && this.action !== 'add') {
        const orderDateval = this.editResponse.orderDate;
        // this.overviewDate = orderDateval ? orderDateval.split('Z').join('') : '';
        //this.overviewDate = this.editResponse.orderDate;
        this.overviewDate = moment.utc(orderDateval).local().format('DD-MM-YYYY HH:mm');
      } else {
        const currentTime = moment.utc().local().format('DD-MM-YYYY HH:mm');
        // let currentDateVal = momenttimezone(currentTime).tz("Europe/Berlin").format("YYYY-MM-DD HH:mm:ss");
        this.overviewDate = currentTime;
      }
      if (this.editResponse && this.action && (this.action == 'process' || this.action == 'vault' || this.action == 'edit')) {
      // displaying 'when to collect' value when action is = process,vault, edit
        let resFromMoment = null;
        let resToMoment = null;
        const resFrom = this.editResponse.reservePeriodFrom ? this.editResponse.reservePeriodFrom.split('T')[0] : '';
        resFromMoment = resFrom ? moment(resFrom, 'YYYY-MM-DD') : '';
        const resTo = this.editResponse.reservePeriodTo ? this.editResponse.reservePeriodTo.split('T')[0] : '';
        resToMoment = resTo ? moment(resTo, 'YYYY-MM-DD') : '';
        if (resFromMoment && resToMoment) {
          this.whentocollectvalue = moment(resFromMoment).format('DD-MM-YYYY') + ' - ' + moment(resToMoment).format('DD-MM-YYYY');
        } else {
          this.whentocollectvalue = resFrom + ' - ' + resTo;
        }
      }
      this.todayDate = new Date();
      const todate = new Date();
      todate.setDate(todate.getDate() + 5);
      this.maxDate = todate;
      const tomDate = new Date();
      tomDate.setDate(tomDate.getDate() + 1);
      // let reserveTodateDefault = tomDate
      this.tomorrowDate = tomDate;
      this._partnerService.emailSubject.subscribe(res => {
        if(!res && this.action === 'add'){
          this._router.navigate(['/partnertools/newOrder']);
        }
        this.getEmail = res;
      });

      this.partenOrderForm = new FormGroup({
        noPackage: new FormControl(1, []),
        // amount: new FormControl('', []),
        reference: new FormControl('', []),
        location: new FormControl('', [Validators.required]),
        // queue: new FormControl('', []),
        reservePeriodFrom: new FormControl(this.todayDate, [Validators.required]),
        // reservePeriodFromTime: new FormControl('', []),
        reservePeriodTo: new FormControl(this.maxDate, [Validators.required]),
        // reservePeriodToTime: new FormControl('', []),
        barcode: new FormControl('', []),
        customerBarcode: new FormControl('', [Validators.pattern(/^[A-Z0-9]{6}$/)]),
        // customerBarcode: new FormControl('',[]),
        length: new FormControl(100, []),
        width: new FormControl(100, []),
        height: new FormControl(100, [])

        // add: this.FB.array([
        //   // new FormControl(null),
        // ])

      });
      
      this.setLocation();
      if (this.action == 'add') {
        if (this.action == 'add' && this.id.id) {
          this.editForm = false;
          this.editBarcode = false;

          this.partenOrderForm.get('barcode').setValue(this.id.id);
          this.extractValue(this.id.id);
        }
      }

      if (this.action == 'edit') {
        this.editBarcode = false;
        // this.disableJnJInput=true;
        this.patchEditValue();
      }
      if (this.id.action == 'ironing') {
        this.partenOrderForm.get('barcode').setValue(this.id.id);
      }
    });
  }
  meApiCall(){
    this._userservice.me().subscribe((res:any) => {
   
      if(this.action == 'add'){
        this.getSettingDetails(res.partner.slug);
      }
    });
  }
  onInputChange(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  getSettingDetails(slug:string) {
    let dimension={} ;
    if (slug !== 'j&j'){
      this._spinner.show();
      this._partnerService.getsettingInfo().subscribe((res: any) => {
        this._spinner.hide();
        const result = res[0];
        if (result.length > 0) {
          dimension={ l:result.length ? result.length : '', w:result.width ? result.width : '', h:result.height ? result.height : ''};
          this.patchDefaultValues(dimension);
        } else {
          dimension={ l:100, w:100, h:100};
        }
      }, err => {
        console.error(err);
        this._spinner.hide();
      });
    } else {
      const parcelSize = this.id.id.match(/(MD|HG|LG)U\d+/);
      dimension =
        parcelSize[1] === 'MD' ? { l: 405, w: 300, h: 610 } :
          parcelSize[1] === 'HG' ? { l: 405, w: 1000, h: 610 } :
            parcelSize[1] === 'LG' ? { l: 405, w: 400, h: 610 } : null;
    }

    this.patchDefaultValues(dimension);

    // this._partnerService.getsettingInfo().subscribe((res: any) => {
    //   if (res.length != 0) {
    //     this.patchDefaultValues(res[0]);
    //   }
    // })
  }

  patchDefaultValues(result: any) {
    this.partenOrderForm.patchValue({
      length: result.l,
      width: result.w,
      height: result.h,
    });
  }

  patchEditValue() {
    
    this.partenOrderForm.patchValue({
      reference: this.editResponse.reference,
      location: this.editResponse.locationId,
      reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom,'YYYY-MM-DD hh:mm:ss').local().format('DD-MM-YYYY HH:mm'),
      reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo,'YYYY-MM-DD hh:mm:ss').local().format('DD-MM-YYYY HH:mm'),
      barcode: this.editResponse.barcode,
      customerBarcode: this.editResponse.pickupCode,
      length: this.editResponse['length'],
      width: this.editResponse.width,
      height: this.editResponse.height,
    });
    this.todayDate = new Date (this.editResponse.reservePeriodFrom);
    const date = new Date ();
    date.setDate(this.todayDate.getDate() + 5);
    this.maxDate = date;
  }

  setLocation() {
    if(!this.userInfo.partner.id){
      return;
    }
    this._partnerService.fetchPartnerDeliverypointDetails(this.userInfo.partner.id).subscribe(
      (res: any) => {
        const locationKeys = Object.keys(res);
        const data = [];
        // for (let key in res) {
        for (let i=0;i<res.dpdsByAcCode.list.length;i++) {
          this.locationData.push({ id: res.dpdsByAcCode.list[i].deliveryPointId, name: res.dpdsByAcCode.list[i].lockerName,lockerCode:res.dpdsByAcCode.list[i].deliveryPointId});
        }
        // }
        if (this.action == 'edit') {
          this.setLocationName(this.editResponse.locationId);
        }
      },
      err => {

        console.error(err);
      }
    );
  }
  setLocationName(val: any) {
    const selectedlocation = this.locationData.find((element) =>

      element.lockerCode == val
    );

    this.checkLockerSpace(selectedlocation.lockerCode);
    this.selectedLocationname = selectedlocation.name;
  }

  checkLockerSpace(val: any) {
    this._deliverypointservice.fetchwithId(val, {
      includeTerminals: true,
      includeCompartments: true
    }).then((res: any) => {
      const terminals = res.terminals;
      let count = 0;
      for (let i = 0; i < terminals.length; i++) {
        for (let j = 0; j < terminals[i].compartments.length; j++) {
          if (terminals[i].compartments[j].active) {
            if (terminals[i].compartments[j].status == null || terminals[i].compartments[j].status == 'free' || terminals[i].compartments[j].status == '') {
              count = count + 1;
              break;
            }
          }
        }
        if (count != 0) {
          break;
        }
      }
      if (count == 0) {
        this.lockerNotFree = true;
      }
      else {
        this.lockerNotFree = false;
      }
    })
      .catch(ex => {
        console.error(ex);
        this.lockerNotFree = false;
      });
  }


  saveDetails(orderType: string) {
    this.submitted = true;
    if (this.partenOrderForm.valid) {
      this._spinner.show();
      // if (this.partenOrderForm.value.customerBarcode == '') {

      //   let sixDigitAlphaNumeric = this.generateRandomString(6);
      //   //this.partenOrderForm.controls['customerBarcode'].setValue(sixDigitAlphaNumeric)
      // }

      let body: any;
      if (this.action == 'add') {
        const userData = this.userInfo;
        const userEmail = userData.email || userData.Email;
        body = {
          'pickupCode': this.partenOrderForm.value.customerBarcode,
          'noPackage': this.partenOrderForm.value.noPackage,
          'reference': this.partenOrderForm.value.reference,
          'locationId': parseInt(this.partenOrderForm.value.location),
          'reservePeriodFrom': this.getDateUtc(this.partenOrderForm.value.reservePeriodFrom.toUTCString()),
          'reservePeriodTo': this.getDateUtc(this.partenOrderForm.value.reservePeriodTo.toUTCString()),
          'barcode': this.partenOrderForm.value.barcode,
          'email': this.getEmail,
          'length': parseInt(this.partenOrderForm.value.length),
          'width': parseInt(this.partenOrderForm.value.width),
          'height': parseInt(this.partenOrderForm.value.height),
          'language': this.language ? this.language.toLowerCase() : window.localStorage.getItem('currentLang')
        };
      }
      else if (this.action == 'edit') {
        body = {
          'partnerId': this.partnerId,
          'noPackage': this.partenOrderForm.value.noPackage,
          'reference': this.partenOrderForm.value.reference,
          'locationId': this.partenOrderForm.value.location,
          'reservePeriodFrom': this.getDateUtc(this.partenOrderForm.value.reservePeriodFrom.toUTCString()),
          'reservePeriodTo': this.getDateUtc(this.partenOrderForm.value.reservePeriodTo.toUTCString()),
          'barcode': this.partenOrderForm.value.barcode,
          'email': this.getEmail,
          'orderId': this.editResponse.id,
          'pickupCode': this.partenOrderForm.value.customerBarcode,
          'length': this.partenOrderForm.value.length,
          'width': this.partenOrderForm.value.width,
          'height': this.partenOrderForm.value.height,
          'language': window.localStorage.getItem('currentLang')
        };
      }
      
      const utcToDate = moment();
      const reservePeriodTo = moment(this.partenOrderForm.value.reservePeriodTo, 'DD-MM-YYYY HH:mm:ss');
      if (moment(reservePeriodTo).isBefore(utcToDate)){
        this._spinner.hide();
        return this._toastr.error(this._translate.instant('Reservation Expiry date cannot be in the past'), this._translate.instant('sorry'));
      }
      //new trial logic for announce and reserve
      if (orderType == 'placeorder') {
        this.executePlaceOrderWorkFlow(body);
      } else {
        this._partnerService.saveNewpartnerOrder(body, orderType).subscribe((res: any) => {
          this._spinner.hide();
          if (res && res.error && res.error == 'barcodeexists') {
            return this._toastr.error(this._translate.instant('barcodeexists'), this._translate.instant('sorry'));
          }
          if (res && res.error && res.error == 'pickupcodeexists') {
            return this._toastr.error(this._translate.instant('Pick up code already used by another active order'), this._translate.instant('sorry'));
          }
          this._toastr.success(this._translate.instant('Partner order saved !!'), this._translate.instant('Success'));
          this._router.navigate(['/partnertools/newOrder']);
        },
        err => {
          this._spinner.hide();
          this._toastr.error(this._translate.instant('Failed to save partner order !!'), this._translate.instant('Failed'));
          console.error(err);
        }
        );
      }

    }

  }

  getDateUtc(date:string){
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }

  todateChange(event){
    this.partenOrderForm.controls['reservePeriodTo'].setValue(event);
  }
  get add() {
    return this.partenOrderForm.controls['add'] as FormArray;
  }

  get formValidators() {
    return this.partenOrderForm.controls;
  }

  // Example usage


  // addPackage() {
  //   this.btnclick = true;
  //   // (<FormArray>this.partenOrderForm.get('add')).push(new FormControl(null));
  //   const addForm = this.FB.group({
  //     package: ['', Validators.required],

  //   });
  //   this.add.push(addForm);
  // return this.partenOrderForm.get('add') as FormArray;
  // const control= new FormControl(null);
  // (<FormArray>this.partenOrderForm.get('add')).push(control);

  // }
  // deletePkg(i: number) {
  //   this.add.removeAt(i);
  //   console.log(i);

  // }
  featureHide() {
    this.isShow = false;
  }

  generatePdf() {
    if (this.selectedLocationname == undefined || this.partenOrderForm.value.barcode == '') {
      this._toastr.warning(this._translate.instant('please enter the location and barcode'), this._translate.instant('sorry'));
    }
    else {
      const element = document.getElementById('contentToConvert');
      const pdf = new jsPDF('l');
      html2canvas(element).then((canvas) => {

      });

      html2canvas(element, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('contentToConvert').style.display = 'block';
        }
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 10, 10, 400, 50);
        pdf.setProperties({
          title: 'barcode'
        });

        // pdf.save("test")
        const fileurl = URL.createObjectURL(pdf.output('blob'));
        window.open(fileurl, '_blank');
        const link = document.createElement('a');
        link.href = fileurl;
        link.download = 'barcode.pdf';
        link.click();
        // Save or open the PDF as needed
        // pdf.output('dataurlnewwindow');
        //your onrendered function code here
      });
    }
  }
  // generatePDF(){
  //   console.log("hii")
  //   const element = document.getElementById('contentToConvert');
  //   console.log(element)
  //   // Configuration for html2pdf
  //   const options = {
  //     margin: 10,
  //     filename: 'generated-document.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //   };

  //   // Generate PDF
  //   html2pdf().from(element).set(options).outputPdf((pdf: any) => {
  //     console.log(pdf)
  //     // You can save the PDF or handle it as needed
  //     const blob = new Blob([pdf], { type: 'application/pdf' });
  //     const url = URL.createObjectURL(blob);

  //     // Open the PDF in a new tab
  //     window.open(url, '_blank');
  //   });
  // }
  getpartnerOrderToProcess(id) {
    this._partnerService.getPartnerOrderToProcess().subscribe((res: any) => {
      this.commonObject(res, id);
    });
  }
  commonObject(res, id) {
    let matchedObject = res.find(obj => obj.id == id.id);
    if (matchedObject) {
      this.partenOrderForm.patchValue({
        noPackage: matchedObject.noPackage,
        reference: matchedObject.reference,
        location: matchedObject.locationId,
        reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom).local().format('DD-MM-YYYY hh:mm'),
        reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo).local().format('DD-MM-YYYY hh:mm'),
        barcode: matchedObject.barcode,
        customerBarcode: matchedObject.pickupCode,
        length: matchedObject.length,
        width: matchedObject.width,
        height: matchedObject.height
      });
     
      this.customerObj={
        firstName:matchedObject?.customers?.firstName,
        lastName:matchedObject?.customers?.lastName,
        Email:matchedObject?.customers?.email,
        phoneNumber:matchedObject?.customers?.phoneNumber
       }
      this.todayDate = new Date (matchedObject.reservePeriodFrom);
      const date = new Date ();
      date.setDate(this.todayDate.getDate() + 5);
      this.maxDate = date;
    }

    //reserve period from to date fix
    if (matchedObject && this.action && (this.action == 'process' || this.action == 'vault' || this.action == 'history' )) {
      let resFromMoment = null;
      let resToMoment = null;
      const resFrom = matchedObject.reservePeriodFrom ? matchedObject.reservePeriodFrom.split('T')[0] : '';
      resFromMoment = resFrom ? moment(resFrom, 'YYYY-MM-DD') : '';
      const resTo = matchedObject.reservePeriodTo ? matchedObject.reservePeriodTo.split('T')[0] : '';
      resToMoment = resTo ? moment(resTo, 'YYYY-MM-DD') : '';
      if (resFromMoment && resToMoment) {
        this.whentocollectvalue = moment(resFromMoment).format('DD-MM-YYYY') + ' - ' + moment(resToMoment).format('DD-MM-YYYY');
      } else {
        this.whentocollectvalue = resFrom + ' - ' + resTo;
      }
    }
  }

  getOrderPlacedInVault(id) {
    this._partnerService.getPartnerVault().subscribe((res: any) => {
      this.orderToProcess = res;
      this.commonObject(res, id);
      // let matchedObject = res.find(obj => obj.id == id.id)
      // console.log(matchedObject, "OBJECT")
      // if (matchedObject) {
      //   this.partenOrderForm.setValue({
      //     noPackage: matchedObject.noPackage,
      //     reference: matchedObject.reference,
      //     location: matchedObject.locationId,
      //     reservePeriodFrom: this.datepipe.transform(matchedObject.reservePeriodFrom, 'yyyy-MM-dd'),
      //     reservePeriodTo: this.datepipe.transform(matchedObject.reservePeriodTo, 'yyyy-MM-dd'),
      //     barcode: matchedObject.barcode,
      //     customerBarcode: matchedObject.pickupCode,
      //   })

      // }
      // this.reservationCheck = moment(this.todayDate) >= moment(matchedObject.reservePeriodTo) ? true : false
      // console.log("reservationCheck", this.reservationCheck)
    });
  }

  getOrderInHistory(id) {
    this._partnerService.getPartnerOrderHistory().subscribe((res: any) => {
      this.orderToProcess = res;
      this.commonObject(res, id);
      // let matchedObject = res.find(obj => obj.id == id.id)
      // console.log(matchedObject, "OBJECT")
      // if (matchedObject) {
      //   this.partenOrderForm.setValue({
      //     noPackage: matchedObject.noPackage,
      //     reference: matchedObject.reference,
      //     location: matchedObject.locationId,
      //     reservePeriodFrom: this.datepipe.transform(matchedObject.reservePeriodFrom, 'yyyy-MM-dd'),
      //     reservePeriodTo: this.datepipe.transform(matchedObject.reservePeriodTo, 'yyyy-MM-dd'),
      //     barcode: matchedObject.barcode,
      //     customerBarcode: matchedObject.pickupCode,
      //   })

      // }
      // this.reservationCheck = moment(this.todayDate) >= moment(matchedObject.reservePeriodTo) ? true : false
      // console.log("reservationCheck", this.reservationCheck)
    });
  }

  executePlaceOrderWorkFlow(body: any){
    //attempt reservation
    if (!body.locationId || body.locationId == '') {
      this._spinner.hide();
      return this._toastr.warning(this._translate.instant('selectlocation'), this._translate.instant('sorry'));
      //return this._toastr.warning(`Please select 'Location'`,'Warning');
    }
    const announceBarcode = { ...body, action: 'announce', email: this.getEmail };
    this.attemptSaveOrder(announceBarcode,body);
  }
  attemptSaveOrder(announceBarcode,body){
    this._partnerService.saveNewpartnerOrder(announceBarcode, 'placeorder').subscribe((res: any) => {
      //barcode exists scenario
      if (res && res.error){
        this._spinner.hide();
        if(res.error == 'barcodeexists'){
          return this._toastr.error(this._translate.instant('barcodeexists'), this._translate.instant('sorry'));
        }
        if(res.error == 'pickupcodeexists'){
          return this._toastr.error(this._translate.instant('Pick up code already used by another active order'), this._translate.instant('sorry'));
        }
        this._toastr.error(this._translate.instant('System error'), this._translate.instant('sorry'));
      }
      // After barcode exist need to perform reservation
      else{
        this.reserveOrder(body,res);
      }
    },
    (err) => {
      this._spinner.hide();
      this._toastr.error(this._translate.instant('Failed to announce order !!'), this._translate.instant('Failed'));
      console.error(err);
    }
    );
  }
  reserveOrder(body,res){
    const reserveBarcode = { ...body, action: 'reserve', barcode: res?.barcode };
    this._partnerService.saveNewpartnerOrder(reserveBarcode, 'placeorder').subscribe((result: any) => {
      this._spinner.hide();
      if (result && result.reservationFail) {
        this._toastr.warning(this._translate.instant('Announcement success, reservation failed'), this._translate.instant('Success'));
      } else {
        this._toastr.success(this._translate.instant('Partner order saved and reserved!!'), this._translate.instant('Success'));
        this._router.navigate(['/partnertools/newOrder']);
      }
    },(err) => {
      this._spinner.hide();
      console.error(err);
      this._toastr.success(this._translate.instant('Place order initiated !!'), this._translate.instant('Success'));
    });
  }
  extractValue(barcode) {
    const regex = /U(.*?)U/;
    const codeFetch = barcode.match(regex);
    this._partnerService.getPartnerCustomer(codeFetch[1]).subscribe((res: any) => {
      if (res.length == 0) {
        this.langaugeFetch(this.translateData, 'No customers were found');
        this._router.navigate(['partnertools/createBarcode']);
      }
      else {
        const idArray = res.filter(obj => obj.clientNumber == parseInt(codeFetch[1]));
        this.getEmail = idArray[0].Email;
        this.language = idArray[0].Language;
      }
    });
  }

  openDatePicker(v) {
    if (v == 'reserveDateToInput'){
      this._renderer.selectRootElement(this.reserveDateToInput.nativeElement).click();
    }


  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }
}
