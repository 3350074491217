import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
@Component({
  selector: 'app-usergroupaddedit',
  templateUrl: './usergroupaddedit.component.html'
})
export class UsergroupaddeditComponent implements OnInit {
  title: any = '';
  groupId: any='';
  checklist: any;
  titleKeys: any;
  groupHeading: any;
  selectAll: any = false;
  constructor(
    private readonly _user: UserService,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    private readonly _spinner: SpinnerService,
    public _authorizationService: AuthorizationService
  ) { }
  ngOnInit(): void {
    const currentAction = this._activatedRoute.snapshot.paramMap.get('action');
    if (currentAction == 'edit') {
      const group = this._activatedRoute.snapshot.paramMap.get('group');
      this._user.getUsergroup().subscribe(res => {
        const groupData: any = res;
        const filteredVal = groupData.filter((element) => {
          if (element.userGroups != null) {
            if (element.userGroups.title == group) {
              return element;
            }
          }
        });

        const defaultChecklist = this._user.checklistData;

        const groupPermissions = filteredVal[0].userGroups.checklistData.map((group) => {
          const key = Object.keys(group)[0];
          const defaultGroup = defaultChecklist.find((defaultGroup) => Object.keys(defaultGroup)[0] === key);
          // Merge the default permissions with the user group permissions
          group[key] = Object.assign({}, defaultGroup[key], group[key]);
          return group;
        });

        this.checklist = groupPermissions;

        this.title = filteredVal[0].userGroups.title;
        this.groupId = filteredVal[0].id;
        this.setTitleKeys();
      },
      err => {
        console.error('THE ERROR IS', err);
      });
    }
    else {
      this.checklist = this._user.checklistData;
      this.setTitleKeys();
    }
  }
  setTitleKeys() {
    this.titleKeys = this.checklist.map((obj) => {
      for (const i in obj) {
        return i;
      }
    });
    this.groupHeading = {};
    for (let i = 0; i < this.titleKeys.length; i++) {
      this.groupHeading[this.titleKeys[i]] = false;
    }

    this.checklist.forEach((object, index) => {
      let falseCount = 0;
      for (const key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] == false) {
          falseCount = falseCount + 1;
        }

      }
      if (falseCount == 0) {
        this.groupHeading[this.titleKeys[index]] = true;
      }

    });

    this.checkForSelectAll();


  }

  checkForSelectAll() {
    let checkFalse = 0;
    for (const key in this.groupHeading) {
      if (this.groupHeading[key] == false) {
        checkFalse = checkFalse + 1;
      }
    }
    if (checkFalse == 0) {
      this.selectAll = true;
    }
  }
  innerGroup(value: any, index: any, event: any) {
    if (!event.target.checked) {
      this.groupHeading[value] = false;
      this.selectAll = false;
    }
    else {
      let falseCount = 0;
      for (const key in this.checklist[index][value]) {
        if (this.checklist[index][value][key] == false) {
          falseCount = falseCount + 1;
        }
      }
      if (falseCount == 0) {
        this.groupHeading[value] = true;
      }
      this.checkForSelectAll();
    }
  }
  selectGroup(value: any, index: any, event: any) {
    if (event.target.checked) {
      for (const key in this.checklist[index][value]) {
        this.checklist[index][value][key] = true;
      }
      this.checkForSelectAll();
    }
    else {
      this.selectAll = false;
      for (const key in this.checklist[index][value]) {
        this.checklist[index][value][key] = false;
      }
    }
  }
  getObjectKeys(item: any, index: any): any[] {
    return Object.keys(item[this.titleKeys[index]]);
  }
  selectall(event: any) {
    if (event.target.checked) {
      this.checklist.forEach((obj, index) => {
        for (const key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = true;
        }
      });
      for (const j in this.groupHeading) {
        this.groupHeading[j] = true;
      }
    }
    else {
      this.checklist.forEach((obj, index) => {
        for (const key in obj[this.titleKeys[index]]) {
          obj[this.titleKeys[index]][key] = false;
        }
      });
      for (const j in this.groupHeading) {
        this.groupHeading[j] = false;
      }
    }
  }
  saveDetails() {
    let falseKey = true;
    let trueCount = 0;
    this.checklist.forEach((object, index) => {

      for (const key in object[this.titleKeys[index]]) {
        if (object[this.titleKeys[index]][key] == true) {
          trueCount = trueCount + 1;
        }
      }

    });
    if (trueCount == 0) {
      falseKey = false;
    }
    const body = {
      title: this.title || null,
      groupId: this.groupId || null,
      checklistData: this.checklist,
      allPrivilegesFalse: falseKey
    };
    this._spinner.show();
    this._user.addUsergroup(body).subscribe(
      res => {
        this.setGroups();
        this._spinner.hide();
        // this.router.navigate(['user/usergroup'])
      },
      err => {
        this._spinner.hide();
        console.error('THE ERROR IS', err);

      });
  }
  setGroups() {
    let userEmail: any;

    let userData: any;
    this._authorizationService.currentDdsUser.subscribe(user => {
      userData = user;
   

      if (userData.email == null || userData.email == undefined) {
        userEmail = userData.mail || userData.Email;
      }
      else {
        userEmail = userData.email; 
      }
      const filterVal= {
        email: userEmail.toLowerCase(),
        name: undefined,
        groups: undefined,
      };
      this._spinner.show();
      forkJoin([this._user.fetchMany(filterVal), this._user.getUsergroup()]).subscribe((res: any[]) => {
        this._spinner.hide();
        const userRole: any = res[0];
        const userDetails = userRole.find(o => o.email === userEmail);

        const currentUserRoles = userDetails.groups.map(element => element.name);
      
        this._router.navigate(['user/usergroup']);
        //  if(currentUserRoles.includes("partnertoolgroup"))
        // {
        //   if(currentUserRoles.length==1)
        //   {
        //     this.router.navigate(['partnertools']);
        //   }
        //   else{
        //     this.router.navigate(['parcels']);
        //   }

      // }
      // else{
      //   this.router.navigate(['parcels']);
      // }
      },err => {
        console.error(err);
        this._spinner.hide();
      });

    });
  }
  cancel = () => {
    this._router.navigate(['/user/usergroup']);
  };
}
