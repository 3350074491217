<app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>

<div #myDiv [appViewController]="{deliveryPoints:'showLogs'}">
  <form [formGroup]="filterForm" >
    <div class="row">
      <div class="col-6">
        <label for="acCode" class="form-label">{{'Event'|translate}}</label>
        <select class="form-select" formControlName="logEvent" name="logEvent">
          <option selected value="">{{'Choose Event'|translate}}</option>
          <option value="BARCODE_UNKNOWN">{{'BARCODE UNKNOWN'|translate}}</option>
          <option value="SWEEP_LOGIN">{{'SWEEP LOGIN'|translate}}</option>
          <option value="CLEAN_LOGIN">{{'CLEAN LOGIN'|translate}}</option>
          <option value="POSTMAN_LOGIN">{{'POSTMAN LOGIN'|translate}}</option>
          <option value="POSTMAN_FLOW">{{'POSTMAN FLOW'|translate}}</option>
          <option value="SCANNED">{{'SCANNER/SCREEN'|translate}}</option>
          <option value="DOOROPENED">{{'DOOR OPENED'|translate}}</option>
        </select>
      </div>
      <div class="col-6">
        <label for="orderDate" class="form-label">{{'Log date'|translate}}</label>
        <div>
          <div class="row">
            <div class="input-group">
              <input
                type="text"
                placeholder="dd-mm-yyyy"
                class="form-control"
                formControlName="logDateFrom"
                #orderDateFromInput
                bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateFromInput')"></i>
                </span>
              </div>
              <input
                type="text"
                placeholder="dd-mm-yyyy"
                class="form-control ms-3"
                formControlName="logDateTo"
                #orderDateToInput
                bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateToInput')"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="selectBothdates" class="text-danger">Please select both the dates</span>
        <span *ngIf="invalidDateRange" class="text-danger">{{'Please add a valid from and to date'|translate}}</span>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col text-center mb-3">
          <button class="btn btn-danger me-3 mt-2" type="submit" (click)="onSubmit()">{{'Filter'|translate}}</button>
          <button class="btn btn-outline-danger mt-2" type="submit" (click)="onReset()">{{'Reset'|translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="table-responsive pl-4 pr-4">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="table table-sm table-bordered table-hover row-border hover text-center" >
    <caption></caption>
    <thead *ngIf="logdetails" class="table-dark">
      <tr>
        <th scope="col" class="event">{{'Event'|translate}}</th>
        <th scope="col" class="action">{{'Action'|translate}}</th>
        <th scope="col" class="description">{{'Description'|translate}}</th>
        <th scope="col">{{'Agent'|translate}}</th>
        <th scope="col">{{'Date'|translate}}</th>
        <th scope="col" class="column">{{'Meta'|translate}}</th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let res of logdetails;">
        <td class="event" *ngIf="!res.event">-</td>
        <td class="event" *ngIf="res.event">{{res.event}}</td>

        <td class="action" *ngIf="res.action">{{res.action}}</td>
        <td class="action" *ngIf="!res.action">-</td>

        <td class="description" *ngIf="res.description">{{res.description}}</td>
        <td class="description" *ngIf="!res.description">-</td>
        <td *ngIf="!res.agent">-</td>
        <td *ngIf="res.agent">{{res.agent}}</td>
        <td *ngIf="!res.eventDate">-</td>
        <td *ngIf="res.eventDate"><span class="d-none">{{res.eventDate}}</span>{{res.eventDate | utctolocaldate:'DD/MM/YYYY HH:mm'}}</td>
        <td class="column" data-bs-toggle="tooltip"[attr.title]="getMeta(res.meta)">{{getMeta(res.meta)}}</td>
      </tr>
    </tbody>
  </table>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
