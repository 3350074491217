import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { PartnerService } from 'src/app/Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-order-vault',
  templateUrl: './partner-order-vault.component.html'
})
export class PartnerOrderVaultComponent implements OnInit {

  vaultResponse: any;
  orderFound = true;
  constructor(
    private readonly _partnerservice: PartnerService,
    private readonly _router: Router,
    private readonly _spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this.getPartnerVault();
  }
  getPartnerVault() {
    this._spinner.show();
    this._partnerservice.getPartnerVault()
      .pipe(map((orders: Array<any>) => {
        this._spinner.hide();
        return orders.map((order: any) => {
          const retObj = { ...order };
          retObj.reservePeriodFrom = order.reservePeriodFrom ? 
            order.reservePeriodFrom.split('Z').join('') : '';
          retObj.reservePeriodTo = order.reservePeriodTo ? 
            order.reservePeriodTo.split('Z').join('') : '';
            let loadedAt = order.loadedAt ?
            moment(order.loadedAt.split('Z').join('')).format('DD-MM-YYYY HH:mm:ss') : '';
          retObj.loadedAt = moment.utc(loadedAt).local().format('DD-MM-YYYY HH:mm:ss');
          return retObj;
        });
      }))
      .subscribe(res => {
        if (res.length === 0) {
          this.orderFound = false;
        }
        else {
          this.orderFound = true;
        }
        this.vaultResponse = res;
      }, err => {
        this._spinner.hide();
        console.error(err);
      });
  }

  viewPage(obj) {
    this._partnerservice.setEmail(obj.email);
    this._partnerservice.setEdit(obj);
    this._router.navigate(['/partnertools/partnerOrder/vault', +obj.id]);

  }

}
