<div class="modal-content">
    <div class="modal-body">
        <p *ngIf="currentCommand != 'restart' ">{{'Are you sure you want to' | translate }} {{Command}} {{'the compartment' | translate}}?</p>
        <p *ngIf="currentCommand == 'restart' ">{{'Are you sure you want to' | translate }} {{Command}} {{'the Locker' | translate}}?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">{{'Cancel'| translate}}</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="confirm()">{{'Confirm'| translate}} </button>
    </div>
</div>
