<app-header></app-header>
<div #myDiv [appViewController]="{parcels:'showDetails'}">
  
  <div *ngIf="!loading" class="container">
    <div class="row px-3 mb-3 d-flex align-items-center justify-content-between">
      <h4>
        <i class="fa fa-barcode" aria-hidden="true"></i>
        {{result.barcode}}
      </h4>
      
      <div class="text-right">
        <div class="dropdown" *ngIf="result.deliveryPointAssigned.provider=='Lean' && result.isActive===true"
          [appViewController]="{parcels:'changeParcelstatus'}">
          <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{'Change parcel status'|translate}} </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a href="#" class="dropdown-item grey-class" data-toggle="modal" (click)="changeParcelStatusSet(bdeStatus)"
              data-target="#changeParcelCompartmentModal">{{'Delivered by postman'|translate}}</a>
            <a *ngFor="let status of changeParcelStatuses" href="#" class="dropdown-item grey-class" data-toggle="modal"
              (click)="changeParcelStatusSet(status)" data-target="#changeParcelStatusModal">{{status.description}}</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item grey-class" data-toggle="modal" (click)="changeParcelCompartment()"
              data-target="#changeParcelCompartmentModal" [appViewController]="{parcels:'assignCompartment'}">{{'Assign
              a compartment'|translate}}</a>
          </div>
        </div>

        <!-- classic locker change parcel status dropdown start-->
        <div class="dropdown" *ngIf="result.deliveryPointAssigned.provider!='Lean' && result.isActive===true "
          [appViewController]="{parcels:'changeParcelstatus'}">
          <button
            class="btn btn-danger dropdown-toggle mt-3"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{'Change parcel status'|translate}}</button>
          <div class="dropdown-menu dropdown-menu-right">
            <div>
              <span [matTooltip]="getTooltipTextLM()" matTooltipPosition='right'>
                <a href=" #" class="dropdown-item" [ngClass]="getDropdownClsName()" data-toggle="modal"
                  (click)="changeParcelStatusSet(bdeStatus)" data-target="#changeParcelStatusModal">{{'Delivered by
                  postman'|translate}}</a>
              </span>
            </div>

            <div>
              <span [matTooltip]="status.toolTipval" matTooltipPosition='right'
                *ngFor="let status of changeParcelStatuses">
                <a [ngClass]="status.valClass" href="#" class="dropdown-item " data-toggle="modal"
                  (click)="changeParcelStatusSet(status)"
                  data-target="#changeParcelStatusModal">{{status.description |translate}}</a>
              </span>
            </div>

            <div>
              <span [matTooltip]="getTooltipTextForFM()" matTooltipPosition='right'>
                <a href="#" class="dropdown-item" [ngClass]="getDropdownClsNameForFM()" data-toggle="modal"
                  (click)="changeParcelStatusSet(druStatus)" data-target="#changeParcelStatusModal">{{'Delivered by
                  customer'|translate}}</a>
              </span>
            </div>

            <div class="dropdown-divider"></div>
            <div [matTooltip]="isEligibleForChangeCompartment ? null: toolTipval"
              matTooltipPosition='right'>
              <a href="#" class="dropdown-item" data-toggle="modal"
                [ngClass]="isEligibleForChangeCompartment ? 'white-class' : 'grey-class'"
                (click)="changeParcelCompartment()" data-target="#changeParcelCompartmentModal"
                [appViewController]="{parcels:'assignCompartment'}">{{'Assign a compartment'|translate}}</a>
            </div>
          </div>
        </div>

        <!-- classic locker change parcel status dropdown end-->

        <br>
        <div class="dropdown" *ngIf="result.deliveryPointAssigned.provider=='Lean' && result.isActive===true">
          <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" *ngIf="showOption">
            {{'Options'|translate}}
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a [ngClass]="type ? 'white-class' : 'grey-class'" href="#" class="dropdown-item" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false"
              [appViewController]="{parcels:'optionQrPickup'}" >{{'QR / Pickup code'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="collapse" data-target="#collapseExpiryDate"
              [ngClass]="isEligibleForExpiryUpdate ? 'white-class' : 'grey-class'" aria-expanded="false"
              [appViewController]="{parcels:'optionChangeExpirationDate'}">{{'Change expiration date'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="modal" data-target="#processManuallyModal"
              [ngClass]="isEligible? 'white-class' : 'grey-class'"
              [appViewController]="{parcels:'optionProcessManually'}">{{'Process manually'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="modal" data-target="#flagFraudModal"
              [appViewController]="{parcels:'optionMarkFraud'}" >{{'Trigger fraud'|translate}}</a>

            <a *ngIf="(result.reservation.id != null && result.deliveryPointOriginal.provider == 'Lean' && iscancelled == false)||(result.deliveryPointOriginal.provider != 'Lean' && iscancelled == false); else grey"
              href="#" class="dropdown-item" data-toggle="modal" data-target="#cancelOrderModal"
              [appViewController]="{parcels:'optionCancelParcel'}">{{'Cancel order'|translate}}</a>
            <ng-template #grey><a class="dropdown-item text-muted user-select-none"
                [appViewController]="{parcels:'optionCancelParcel'}">{{'Cancel order'|translate}}</a></ng-template>

            <a href="#" class="dropdown-item" data-toggle="modal"
              [ngClass]="result?.reservation?.id != null &&  result.deliveryPointOriginal.provider == 'Lean' && isEligibleForExpiryUpdate ? 'white-class' : 'grey-class'"
              data-target="#resendEmailModel" [appViewController]="{parcels:'optionResendMail'}">{{'Resend
              email'|translate}}</a>
          </div>
        </div>
        <div class="dropdown" *ngIf="result.deliveryPointAssigned.provider!='Lean' && result.isActive===true">
          <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"  *ngIf="showOption">
            {{'Options'|translate}}</button>
          <div class="dropdown-menu dropdown-menu-right">
            <a [ngClass]="type ? 'white-class' : 'grey-class'" href="#" class="dropdown-item" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false"
              [appViewController]="{parcels:'optionQrPickup'}">{{'QR / Pickup code'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="collapse" data-target="#collapseExpiryDate"
              [ngClass]="isEligibleForExpiryUpdate ? 'white-class' : 'grey-class'" aria-expanded="false"
              [appViewController]="{parcels:'optionChangeExpirationDate'}">{{'Change expiration date'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="modal" data-target="#processManuallyModal"
              [ngClass]="isEligibleForProcessManually ? 'white-class' : 'grey-class'"
              [appViewController]="{parcels:'optionProcessManually'}">{{'Process manually'|translate}}</a>
            <a href="#" class="dropdown-item" data-toggle="modal" data-target="#flagFraudModal"
              [ngClass]="!isFraud ? 'white-class' : 'grey-class'"
              [appViewController]="{parcels:'optionMarkFraud'}">{{'Trigger fraud'|translate}}</a>
            <a *ngIf="(result.reservation.id != null && result.deliveryPointOriginal.provider == 'Lean' && !iscancelled)||(result.deliveryPointOriginal.provider != 'Lean' && !iscancelled); else grey"
              href="#" class="dropdown-item" data-toggle="modal" data-target="#cancelOrderModal"
              [appViewController]="{parcels:'optionCancelParcel'}">{{'Cancel order'|translate}}</a>
            <ng-template #grey><a class="dropdown-item text-muted user-select-none"
                [appViewController]="{parcels:'optionCancelParcel'}">{{'Cancel order'|translate}}</a></ng-template>

            <a href="#" class="dropdown-item" data-toggle="modal"
              [ngClass]="(result.deliveryPointOriginal.provider == 'Cubee'|| result.deliveryPointOriginal.provider == 'bpost') && isEligibleForExpiryUpdate ? 'white-class' : 'grey-class'"
              data-target="#resendEmailModel" [appViewController]="{parcels:'optionResendMail'}">{{'Resend
              email'|translate}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isLockerEmpty">
      <div class="col-8 offset-2 lockeremptystyle">{{'The delivery person did not find a package! The package maybe in
        another safe'|translate}}</div>
    </div>
    <div class="row" *ngIf="isFraud">
      <div class="col-8 offset-2 lockeremptystyle">{{'The package has been marked FRAUD'|translate}}</div>
    </div>

    <div class="row">
      <div class="col-12 mb-3 collapse" id="collapseExpiryDate">
        <div class="card">
          <div class="card-header">
            {{'Expiration date Update'|translate}}
          </div>
          <div class="card-body" *ngIf="parcelType=='Lean' && isEligibleForExpiryUpdate">
           
            <!-- new code jj-->

            <form [formGroup]="expiryRequestForm">
              <div class="row">
                <div class="col-8">
                  <input type="radio" id="exp24" name="expiryHours" formControlName="expiryHours" value="24"
                    (click)="onRadioSelect(24)">
                  <label for="exp24">{{'24 Hours'|translate}}</label>&nbsp;
                  <input type="radio" id="exp28" name="expiryHours" formControlName="expiryHours" value="48"
                    (click)="onRadioSelect(48)">
                  <label for="exp28">{{'48 Hours'|translate}}</label>&nbsp;
                  <input type="radio" id="exp0" name="expiryHours" formControlName="expiryHours" value="0"
                    (click)="onRadioSelect(0)">
                  <label for="exp0">{{'Immediate expiry'|translate}}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-6" [ngStyle]="{'display': shouldDisplayPickupInstruction() ? 'block':'none'}">
                  <div class="form-group">
                    <label for="pickupInstruction">{{'Pickup instruction'|translate}}</label>
                    <select name="pickupInstruction" formControlName="pickupInstruction" class="form-control">
                      <option value="1">{{'Return to sender'|translate}}</option>
                      <option value="2">{{'Home delivery requested'|translate}}</option>
                      <option value="3">{{'Bring to nearest manned service point'|translate}}</option>
                      <option value="4">{{'Deliver to sorting hub'|translate}}</option>
                      <option value="5">{{'Contact customer service'|translate}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="comment">{{'Comments'|translate}}</label>
                    <input type="text" class="form-control" formControlName="comment">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button id="updateExpirationDate" type="submit" class="btn btn-primary"
                    (click)="updateExpirationDateNew()">{{'Update expiration date'|translate}}</button>
                </div>
              </div>
            </form>
            <!--end new code jj-->

          </div>
          <div class="card-body"
            *ngIf="(parcelType=='Cubee'|| parcelType=='bpost') && isEligibleForExpiryUpdate && historicalDetails?.length > 0">
            <ngx-slider [(lowvalue)]="lowvalue" [(highValue)]="highValue" [options]="sliderOptions"
              (mouseup)="sliderValChange($event.value)">
            </ngx-slider>
          </div>
          <div class="card-body" *ngIf="!isEligibleForExpiryUpdate">
            <h3>{{'Not eligible for expiry update'|translate}}</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3 collapse" id="collapseExample">
        <div class="card">
          <div class="card-header">
            {{ 'QR / Pickup code'|translate}}
          </div>
          <div class="card-body" *ngIf="!pickupCode">
            {{'There is no QR / Pickup code available.'|translate}}
          </div>
          <div class="card-body" *ngIf="pickupCode">
            <div class="row px-3 d-flex align-items-center justify-content-between">
              <div class="col text-center" >
                <qrcode [qrdata]="pickupCode" [size]="256" [level]="'M'" [margin]=1 [scale]="6"></qrcode>
                <div id="copyPickupCode">{{pickupCode}}</div>
              </div>
              <div class="col">
                <button class="btn btn-info" (click)="copytoClipboardPickupCode()" type="button"
                  [disabled]="copyBtn == 'Copied'">
                  {{copyBtn}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">

      <div class="col-12 mb-3">
        <div class="card-group">
          <div class="card">
            <div class="card-header">
              {{'General details'|translate}}
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4">{{'Delivery date'|translate}}</div>
                <div *ngIf="result.date && currentParcelStatus!='LM_RESERVATION_FAILED'" class="col-8 text-right">{{result.date | date:'dd-MM-yyyy'}}</div>
                <div *ngIf="result.firstmileDate && currentParcelStatus!='FM_RESERVATION_FAILED'" class="col-8 text-right">{{result.firstmileDate | date:'dd-MM-yyyy'}}</div>
                <div *ngIf="!result.date" class="col-8 text-right"></div>
              </div>

              <div class="row">
                <div class="col-4">{{'Expiration date'|translate}}</div>
                <div class="col-8 text-right">{{result.expirationDate | utctolocaldate:'DD-MM-yyyy hh:mm:ss'}}
                </div>
                <div *ngIf="!result.expirationDate" class="col-8 text-right"></div>
              </div>

              <div class="row">
                <div class="col-4 pr-0">{{'Reservation type'|translate}}</div>
                <div class="col-8 text-right"><span class="badge badge-success">{{result.reservation.type}}</span>
                </div>
                <div *ngIf="!result.reservation.type" class="col-8 text-right"></div>
              </div>

              <div class="row">
                <div class="col-4 pr-0">{{'Locker type'|translate}}</div>
                <div class="col-8 text-right"><span
                    class="badge badge-success">{{result.deliveryPointOriginal.provider}}</span>
                </div>
                <div *ngIf="!result.deliveryPointOriginal.provider" class="col-8 text-right">
                </div>
              </div>
              <div class="row">
                <div class="col-4 pr-0">{{'Partner name'|translate}}</div>
                <div class="col-8 text-right"><span  class="badge badge-success">{{partnerName}}</span> 
                </div>
              </div>
              <div class="row">
                <div class="col-4 pr-0">{{'Active Status'|translate}}</div>
                <div class="col-8 text-right"><span  class="badge badge-success">{{result.isActive}}</span> 
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              {{'Locations details'|translate}}
            </div>
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="col-6 pr-0">{{'Compartment number'|translate}}</div>
                  <div class="col-6 text-right">
                    {{result.dds.comparment?.number}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 pr-0">{{'Dimensions'|translate}}</div>
                  <div class="col-6 text-right" *ngIf="result.physics.height">
                    <span class="badge badge-primary">{{result.physics.height}}mm</span> x
                    <span class="badge badge-primary">{{result.physics.depth}}mm</span> x
                    <span class="badge badge-primary">{{result.physics.width}}mm</span> x
                    <span *ngIf="result.physics.weight" class="badge badge-warning">{{result.physics.weight}}gr</span>
                    <span *ngIf="!result.physics.weight" class="badge badge-warning">? gr</span>
                  </div>
                  <div *ngIf="!result.physics" class="col-4 text-right">
                    ?
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 pr-0">{{'Original AC code'|translate}}</div>
                  <div class="col-6 text-right">
                    {{result.deliveryPointOriginal.acCode}}
                  </div>
                  <div *ngIf="!result.deliveryPointOriginal.acCode" class="col-6 text-right">
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 pr-0">{{'Original locker name'|translate}}</div>
                  <div class="col-6 text-right">
                    {{result.deliveryPointOriginal.lockerName}}
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 pr-0">{{'Assigned AC code'|translate}}</div>
                  <div class="col-6 text-right">
                    {{result.deliveryPointAssigned.acCode}}
                  </div>
                  <div *ngIf="!result.deliveryPointAssigned.acCode" class="col-6 text-right">
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 pr-0">{{'Assigned locker name'|translate}}</div>
                  <div class="col-6 text-right">
                    {{result.deliveryPointAssigned.lockerName}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card-group">
          <div class="card">
            <div class="card-header">
              {{'Sender details'|translate}}
            </div>
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">{{'Name'|translate}}</div>
                  <div class="col-6 text-right">{{result.sender.senderName}}</div>
                </div>

                <div class="row">
                  <div class="col-6">{{'company'|translate}}</div>
                  <div class="col-6 text-right">{{result.sender.company}}</div>
                </div>
                <div class="row">
                  <div class="col-6">{{'Email'|translate}}</div>
                  <div class="col-6 text-right">{{result.sender.email}}</div>
                </div>
                <div class="row">
                  <div class="col-6">{{'Phone number'|translate}}</div>
                  <div class="col-6 text-right">{{result.sender.phone}}</div>
                </div>

              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              {{'Receiver details'|translate}}
            </div>
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="col-6">{{'Name'|translate}}</div>
                  <div class="col-6 text-right">{{result.receiver.firstName}}</div>
                </div>

                <div class="row">
                  <div class="col-6">{{'company'|translate}}</div>
                  <div class="col-6 text-right">{{result.receiver.lastName}}</div>
                </div>

                <div class="row">
                  <div class="col-6">{{'Email'|translate}}</div>
                  <div class="col-6 text-right">{{result.receiver.email}}</div>
                </div>

                <div class="row">
                  <div class="col-6">{{'Phone number'|translate}}</div>
                  <div class="col-6 text-right">{{result.receiver.phone}}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="changeParcelCompartmentModal" tabindex="-1" role="dialog"
      aria-labelledby="changeParcelCompartmentModal" aria-hidden="true">
      <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{'Change parcel compartment'|translate}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p>
                  {{'Select below the compartment you want to assign to the parcel.'|translate}}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <dl *ngIf="currentLocker">
                  <dt>{{'Locker'|translate}}</dt>
                  <dd>{{currentLocker.lockerName}} ({{currentLocker.acCode}})</dd>
                  <dt>{{'Address'|translate}}</dt>
                  <dd>{{currentLocker.address.street}} {{currentLocker.address.streetNumber}},
                    {{currentLocker.address.postalCode}}. {{currentLocker.address.municipality}}</dd>
                </dl>
              </div>
              <div class="col-6">
                <dl *ngIf="currentTerminal">
                  <dt>{{'Current terminal'|translate}}</dt>
                  <dd>{{currentTerminal.id}} ({{currentTerminal.externalId || 'no external id'}})</dd>
                  <dt>{{'Current compartment number'|translate}}</dt>
                  <dd>{{currentCompartment.number}}</dd>
                </dl>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <dl><dt>{{'Choose a Compartment'|translate}}</dt></dl>
                <select name="newCompartment" class="form-control" #newCompartment>
                  <optgroup *ngFor="let terminal of changeCompartmentOpts" label="{{terminal.label}}">
                    <option *ngFor="let compartment of terminal.compartments" value="{{compartment.value}}" [disabled]="checkforInactive(compartment.label)">
                      {{compartment.label}}</option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" (click)="changeParcelCompartmentTrigger(newCompartment.value)"
              data-dismiss="modal">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="changeParcelStatus" class="modal fade" id="changeParcelStatusModal" tabindex="-1" role="dialog"
      aria-labelledby="changeParcelStatusModal" aria-hidden="true">
      <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{'Change parcel status'|translate}} {{changeParcelStatus.description}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              <b>Opgelet!*</b> Voor lean lockers zorgt deze actie enkel voor een aanpassing in de bpost tracking !
            </p>
            <p>
              <b>Attention!*</b> pour les lean lockers, cette action n'entraîne qu'un ajustement dans le suivi de bpost
              !
            </p>
            <p>
              <b>Attention!*</b> For lean lockers this action only updates the status in bpost tracking!
            </p>
            <hr>
            <dl>
              <dt>{{'Event'|translate}}</dt>
              <dd>{{changeParcelStatus.eventCode}}-{{changeParcelStatus.eventSubCode}}</dd>
              <dt>{{'Description'|translate}}</dt>
              <dd>{{changeParcelStatus.description}}</dd>
            </dl>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" (click)="changeParcelStatusTrigger(changeParcelStatus)"
              data-dismiss="modal">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'Add comment'|translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="parcelForm">
              <div class="form-group">
                <label for="recipient-name" class="col-form-label">{{'Comments'|translate}}:</label>
                <textarea type="text-area" class="form-control" formControlName="comments" autofocus id="recipient-name"
                  (input)="onTextAreaInput($event)"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" (click)="sendComments()" data-dismiss="modal">{{'Save'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="cancelOrderModal" role="dialog" aria-labelledby="cancelOrderModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{'Cancel order'|translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to cancel the order?'|translate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelOrder()">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="resendEmailModel" role="dialog" aria-labelledby="resendEmailModelLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{'Resend email'|translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to resend the email?'|translate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal"
              (click)="updateEmail(result.barcode)">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="flagFraudModal" role="dialog" aria-labelledby="flagFraudModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{'Flag Fraud'|translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to flag the parcel fraudulent?'|translate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="flagFraud()">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="processManuallyModal" role="dialog"
      aria-labelledby="processManuallyModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{'Process manually'|translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to process the order manually?'|translate}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal"
              (click)="processManually()">{{'Confirm'|translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            {{'Timeline'|translate}}
          </div>

          <div class="card-body">
            <!-- Timeline start -->
            <div class="text-center">
              <input type="image" src="/assets/img/plus button.webp" width="50" height="50" alt="submit"
                class="m-auto" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"
                [appViewController]="{parcels:'editTimeline'}">
            </div>
            <div class="timeline">
              {{activity?.id | uppercase}}
              <div class="timeline-row" *ngFor="let res of activity;">
                <div class="timeline-content">
                  <p>
                    <span>{{res.text | uppercase}}<br></span>
                    <span *ngIf="res.meta.eventCode === 'BDE' && res.meta.eventSubCode === '23'">Delivered by
                      postman<br></span>
                    <span *ngIf="res.meta.eventCode === 'BDU' && res.meta.eventSubCode === '1'">Picked up by
                      customer<br></span>
                    <span *ngIf="res.meta.eventCode === 'ACE' && res.meta.eventSubCode === '2'">Picked up by
                      postman<br></span>
                    <span *ngIf="res.meta.eventCode === 'DRU' && res.meta.eventSubCode === '1'">Delivered by
                      customer<br></span>
                    <span *ngIf="res.meta.eventCode === 'NDE' && res.meta.eventSubCode === '12'">Postman Expired Pick Up
                      <br></span>
                    <span *ngIf="res.actor != 'USER' && res.actor != 'POSTMAN'">{{res.actor | uppercase}}<br></span>
                    <span *ngIf="res.meta.reservationattempted">reservation attempted:
                      {{res.meta.reservationattempted | uppercase}}<br></span>
                    <span *ngIf="res.meta.reservationattemptedat">reservation attempted at:
                      {{res.meta.reservationattemptedat | uppercase}}<br></span>
                    <span *ngIf="res.meta.reservationstatus">reservation status: {{res.meta.reservationstatus |
                      uppercase}}<br></span>
                    <span
                      *ngIf="!res.meta.reasonforfailure || res.meta.reservationstatus === 'SUCCESS' && !res.meta.reservationattempted"><br></span>
                    <span *ngIf="res.meta.reasonforfailure">reservation description:
                      {{res.meta.reasonforfailure | uppercase}}<br></span>
                    <span>
                      {{res.eventDate | date:'dd/MM/YYYY HH:mm' | uppercase}}
                      <br></span>
                  </p>

                </div>
              </div>
            </div>
            <!-- Timeline end -->
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="text-right">   
      <button class="btn btn-secondary  me-1" (click)="goToParcelList()" >{{'Back'|translate}}</button>&nbsp;
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
<div [appViewController]="{parcels:'optionCancelParcel'}" #optionCancel></div>
<div [appViewController]="{parcels:'optionQrPickup'}" #qr></div>
<div [appViewController]="{parcels:'optionChangeExpirationDate'}" #changeExpiry></div>
<div [appViewController]="{parcels:'optionProcessManually'}" #processManual></div>
<div [appViewController]="{parcels:'optionMarkFraud'}" #triggerFraud></div>
<div [appViewController]="{parcels:'optionResendMail'}" #resendEmail></div>
