import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParcelService } from 'src/app/Service/parcel.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { PartnerService } from 'src/app/Service/partner.service';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-fm-failed-report',
  templateUrl: './fm-failed-report.component.html',
  styleUrls: ['./fm-failed-report.component.scss']
})
export class FmFailedReportComponent implements OnInit {
  countries = [{
    id: 'BE', name: 'Belgium',
  },
  ];
  activities = [{
    id: 1, name: true
  },
  {
    id: 2, name: false
  }
  ];
  reserves = [{
    id: 1, name: true
  },
  {
    id: 2, name: false
  }
  ];
  businesses = [{
    id: 1, name: 'Bpost'
  },
  {
    id: 2, name: 'Dhm'
  },
  {
    id: 3, name: 'Dlm'
  }
  ];
  reportForm: FormGroup;
  submitted: boolean;
  partnerList: any;
  partnerNamedropdownList: any[];
  Partner: any[];
  lockerFetchfail: any;
  translateData: any;
  maxDate = new Date();
  @ViewChild('fromDateInput') fromDateInput: ElementRef;
  @ViewChild('toDateInput') toDateInput: ElementRef;
  constructor(
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _formBuilder: FormBuilder,
    private readonly _parcelService: ParcelService,
    public _datepipe: DatePipe,
    private readonly _partnerService: PartnerService,
    private readonly _toastr: ToastrService,
    private readonly _renderer: Renderer2,
    private readonly _spinner: SpinnerService
  ) {
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
    this.reportForm = this._formBuilder.group({
      country: ['', Validators.required],
      softReservation: [''],
      businessesPartner: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
    });
    this.fetchPartners();
    this.reportForm.patchValue({
      country: this.countries[0].id,
      softReservation: false

    });
  }
  exportButton() {
    const newArray = [];
    this.submitted = true;
    const obj = {
      country: this.reportForm.controls.country.value,
      softReserved: this.reportForm.controls.softReservation.value,
      businessPartner: this.reportForm.controls.businessesPartner.value,
      fromDate: this._datepipe.transform(
        this.reportForm.controls.fromDate.value, 'yyyy-MM-dd'),
      toDate: this._datepipe.transform(
        this.reportForm.controls.toDate.value, 'yyyy-MM-dd'),
    };

    if (this.reportForm.valid) {
      this._spinner.show();
      this._parcelService.firstMailFailedItems(obj).subscribe((res: any) => {
        this._spinner.hide();
        if (res.length > 0) {
          res.forEach(element => {
            const timeStamp = 
            moment.utc(element.firstMileReservationAttemptedAt).local().format(
              'YYYY-MM-DD HH:mm:ss');
            const newObj = {
              LocationTitle: 
              `${element.lockerMunicipality}|${element.lockerStreetName}|${element.lockerName}${element.acceptSoftReservations ? "|*" : "" }`,
              BarCode: element.barcode,
              Date: element.firstMileReservationAttemptedAt ? timeStamp : '-'
            };
            newArray.push(newObj);
          });
          
          this.Partner = this.partnerNamedropdownList.filter(
            item=>
              item.id==
            (this.reportForm.controls.businessesPartner.value)
          );
          const Today = this._datepipe.transform(new Date(), 'dd-MM-yyyy');
          const filename = 
          `FirstMileFailed_${this.Partner[0].name}_${Today}`;
          this.exportToExcel(newArray, filename);
        } else {
          this.langaugeFetch(this.translateData, 'No Records Found!');
        }
      }, err => {
        this._spinner.hide();
        console.error(err);
      });
    }
  }
  exportToExcel(data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);

  }
  get formValidators() {
    return this.reportForm.controls;
  }
  fetchPartners() {
    this._spinner.show();
    this._partnerService.fetchPartnersList().subscribe(
      (result) => {
        this._spinner.hide();
        this.partnerList = result;
        this.partnerNamedropdownList = this.partnerList.filter(
          item => item.active).map(item => ({ id: item.id, name: item.name }));

        if (this.partnerNamedropdownList.length > 0) {
          const defaultBusinessesPartner = this.partnerNamedropdownList.filter(
            (element) => element.name === 'Bpost');
          this.reportForm.patchValue({
            businessesPartner: defaultBusinessesPartner[0].id
          });
        }

      }, err => {
        this._spinner.hide();
        console.error(err);
      });
  }

  onReset() {
    this.submitted = false;
    this.reportForm.reset();
    Object.keys(this.reportForm.controls).forEach(key => {
      this.reportForm.get(key).setErrors(null);
    });
    this.reportForm.setValue({
      country: '',
      softReservation: '',
      businessesPartner: '',
      fromDate: '',
      toDate: ''
    });
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.warning(res);
      }
    );
  }
  openDatePicker(v) {
    if (v === 'fromDateInput') {
      this._renderer.selectRootElement(
        this.fromDateInput.nativeElement).click();
    }
    if (v === 'toDateInput') {
      this._renderer.selectRootElement(this.toDateInput.nativeElement).click();
    }
  }
}
