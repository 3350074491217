<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div *ngIf="userdetails">
  <div class="pl-4 pr-4">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
      <div class="card shadow p-3 mb-5 bg-white rounded">
        <div class="card-body p-3">
          <div>
            <div class="col text-right">
              <h3 class=" card-title col text-center">{{'USER DETAILS'|translate}}</h3>
            </div>
          </div>
          <div class="col-12 text-secondary">

            <div class="row mt-3">
              <div class="col-6 pr-0"><b>{{'ID'|translate}}</b></div>
              <div class="col-6 text-right pl-0">
                <div class="text-left">
                 {{userdetails.id}}
              </div>
              </div>
            </div>
            <div class="row mt-3 ">
              <div class="col-6 pr-0"><b>{{'Name'|translate}}</b></div>
              <div class="col-6 text-right pl-0">
                <div class="text-left">
                  {{userdetails.firstName+' '+userdetails?.lastName}}
              </div>
              </div>
            </div>
            <div class="row mt-3 ">
              <div class="col-6 pr-0"><b>E-mail</b></div>
              <div class="col-6 text-right pl-0">
                <div class="text-left">
                  {{userdetails.email}}
              </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pr-0"><b>{{'Groups'|translate}}</b></div>
              <div class="col-6 text-left pl-0">
                  <a *ngFor="let group of userdetails.groups;">
                    {{group.name}}</a>
              </div>
            </div>
            <div class="row mt-3 ">
              <div class="col-6 pr-0"><b>{{'Partner'|translate}}</b></div>
              <div class="col-6 text-right pl-0">
                <div class="text-left">
                  {{userdetails?.partner?.name}}
              </div>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-secondary  me-3 mt-2" (click)="goToUsersList()">{{'Back'|translate}}</button>
              <button
                [appViewController]="{users:'editUser'}"
                type="button" class="btn btn-danger me-3 mt-2"
                (click)="onEdit()">{{'EDIT'|translate}}</button>
              <button
                [appViewController]="{users:'deleteUser'}"
                data-toggle="modal"
                data-target="#deleteUserModal"
                type="button"
                class="btn btn-danger me-3 mt-2"
                data-toggle="modal"
                data-target="#deleteUserModal"
                >{{'DELETE'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete the user?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onDeleteConfirm()">Confirm</button>
        </div>
      </div>
    </div>
  </div>

</div>
