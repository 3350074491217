<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
  <br />
  <div class="row">
    <div class="col-3">
      <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
    </div>

    <div class="col-9">
      <h3>{{'Times' | translate}}</h3>
      <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="form-group">
            <label for="dwell">{{'DWELL time' | translate}} ({{'minutes' | translate}})</label>
            <input formControlName="dwell" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.dwell.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
        <div class="row">
          <div class="form-group">
            <label for="FM_RESERVATION_LIMIT">{{'FirstMile reservation limit' | translate}} ({{'minutes' |
              translate}})</label>
            <input formControlName="FM_RESERVATION_LIMIT" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.FM_RESERVATION_LIMIT.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
        <div class="row">
          <div class="form-group">
            <label for="LM_RESERVATION_LIMIT">{{'LastMile reservation limit' | translate}} ({{'minutes' |
              translate}})</label>
            <input formControlName="LM_RESERVATION_LIMIT" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.LM_RESERVATION_LIMIT.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
        <button class="btn btn-secondary  mr-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>
        &nbsp;
        <button type="submit" class="btn btn-danger">Submit</button>
      </form>
    </div>
  </div>
</div>