import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LangtransService {
  translateValue: any;

  constructor(
    private readonly _http: HttpClient
  ) {

  }
  TranslateData(lang: any,key:any){
    return this._http.get (`/assets/i18n/${lang}.json`).pipe(
      map((data: any)=>data[key])
    );
  }
}

