import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from '../Service/langtrans.service';
import { AuthorizationService } from '../Service/authorization.service';
@Component({
  selector: 'app-maintenance-staff',
  templateUrl: './maintenance-staff.component.html',
  styleUrls: ['./maintenance-staff.component.scss']
})
export class MaintenanceStaffComponent implements OnInit {
  maintenance = true;
  addUserForm: FormGroup;
  userService: any;
  submitted: boolean;
  list: { name: string; checked: boolean; }[];
  id: any;
  languagechar: any;
  idValue: string;
  header: string;
  inEditView = false;
  editPartnerObj=null;
  showPasswordOnPress:boolean;
  partnerList: any;
  loginIdValidateError = false;
  loginIdCopabilityMatched: any;
  showMissmatchError = false;
  userInfo: any;
  constructor(private readonly _fb: FormBuilder,
    private readonly _router: Router,
    private readonly _partnerService: PartnerService,
    private readonly _route: ActivatedRoute,
    private readonly _appService: AppService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _langtransService: LangtransService,
    private readonly _authorizationService: AuthorizationService
  ) {
    this.list = [
      { name: 'CAN_SWEEP', checked: false },
      { name: 'CAN_CLEAN', checked: false }
    ];
  }
  ngOnInit(): void {

    this.addUserForm = this._fb.group({
      name: ['', Validators.required],
      loginId: ['', Validators.required],
      capabilities: ['', Validators.required],
      password: ['', [Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/),
        Validators.required,Validators.minLength(8)]],
    });
    this.inEditView =false;
    this._route.params.subscribe((data) => {
      this.id = data;
    });
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.inEditView =true;
      this.languagechar = data;
      if(Object.keys(this.id).length=== 0){
        this.header = 'Add Maintenance User';
        this.idValue='';
      }else{
        this.header = 'Edit Maintenance User';
        this.editDataFetch(this.id);
        this.idValue=this.id.id;
      }
    });
  }
  get formValidators() {
    return this.addUserForm.controls;
  }
  editDataFetch(id){
    this._partnerService.fetchMaintenanceUserById(id.id).subscribe((res:any) => {
      this.addUserForm.setValue({
        name: res.name,
        loginId: res.loginId,
        capabilities: res.capabilities,
        password: res.password
      });
    });
  }
  submit() {
    this.submitted = true;
    if (this.addUserForm.valid) {
      this._authorizationService.currentDdsUser.subscribe(user => {
        this.userInfo = user;
        const loginID = this.addUserForm.controls.loginId.value.toUpperCase();
        if (loginID.endsWith('SWEEP') || loginID.endsWith('CLEAN')) {
          this.loginIdValidateError = false;
          // checking in loginId ends with SWEEP and capabilities=CAN_SWEEP or 
          //  loginId ends with CLEAN and capabilities=CAN_CLEAN
          if (loginID.endsWith('SWEEP') && this.addUserForm.controls.capabilities.value.includes('CAN_SWEEP')) {
            this.loginIdCopabilityMatched = true;
          }
          else if (loginID.endsWith('CLEAN') && this.addUserForm.controls.capabilities.value.includes('CAN_CLEAN')) {
            this.loginIdCopabilityMatched = true;
          }
          else {
            this.loginIdCopabilityMatched = false;
            this.showMissmatchError = true;
          }
        } else {
          this.loginIdValidateError = true;
        }
        //if loginId and capabilities are Matched then adding details.
        if (this.loginIdCopabilityMatched) {
          const obj = {
            name: this.addUserForm.controls.name.value,
            loginId: this.addUserForm.controls.loginId.value.toUpperCase(),
            capabilities: this.addUserForm.controls.capabilities.value,
            password: this.addUserForm.controls.password.value.toUpperCase(),
            language: this.languagechar.toUpperCase(),
            id: this.idValue,
            createdBy: this.userInfo.uid
          };
          this._spinner.show();
          this._partnerService.createMaintenanceUser(obj).subscribe((res: any) => {
            this._spinner.hide();
            if (res.code) {
              this.langaugeFetch(this.languagechar, 'Userid already taken !!', 'info');
              return;
            }
            this._router.navigate(['/maintenanceUser']);
          }, (err: any) => {
            this._spinner.hide();
            this.langaugeFetch(this.languagechar, 'Something bad happened, please try again later.', 'info');
          });
        }
      });  

      
      //checking loginId ends with SWEEP or CLEAN
  

      // if (Object.keys(this.id).length === 0) {
      //   this.partnerService.createMaintenanceUser(obj).subscribe((res) => {
      //     console.log(res, 44)
      //     if(res=='inserted successfully'){
      //       this.router.navigate(['/maintenanceUser'])
      //     }
      //   })
      // }
      // else {
      //   this.partnerService.updateMaintenanceUser(obj).subscribe((res) => {
      //     console.log(res, 63)
      //     this.router.navigate(['/maintenanceUser'])
      //   })
      // }

    }

  }

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus == 'info') {
          this._toastr.info(res);
        }
      }
    );
  }
  cancel = () => {
    this._router.navigate(['maintenanceUser']);
  };

}
