import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { PingBpostComponent } from './Components/callback/ping-bpost/ping-bpost.component';
import { PingExternalComponent } from './Components/callback/ping-external/ping-external.component';
import { LockerlistComponent } from './Components/lockers/lockerlist/lockerlist.component';
import { AuthGuard } from './auth.guard';
import { RoutingGuard } from './routing.guard';
import { LockerdetailsComponent } from './Components/lockers/lockerdetails/lockerdetails.component';
import { LogsComponent } from './Components/lockers/logs/logs.component';
import { TerminalsComponent } from './Components/lockers/terminals/terminals.component';
import { ParcelListComponent } from './Components/parcels/parcel-list/parcel-list.component';
import { ParcelDetailsComponent } from './Components/parcels/parcel-details/parcel-details.component';
import { UserlistComponent } from './Components/users/userlist/userlist.component';
import { ReservationComponent } from './Components/reservation/reservation.component';
import { UserDetailsComponent } from './Components/users/user-details/user-details.component';
import { ForbiddenUserComponent } from './Components/users/forbidden-user/forbidden-user.component';
import { UserDetailsPreviewComponent } from './Components/users/user-details-preview/user-details-preview.component';
import { UserDetailsUpdateComponent } from './Components/users/user-details-update/user-details-update.component';
import { SignInComponent } from './Components/sign-in/sign-in.component';
import { PartnerListComponent } from './Components/partners/partner-list/partner-list.component';
import { PartnerDetailsComponent } from './Components/partners/partner-details/partner-details.component';
import { PartnerEditDeliverypointsComponent } from './Components/partners/partner-edit-deliverypoints/partner-edit-deliverypoints.component';
import { PartnerEditInfoComponent } from './Components/partners/partner-edit-info/partner-edit-info.component';
import { PartnerEditPostmanComponent } from './Components/partners/partner-edit-postman/partner-edit-postman.component';
import { PartnerEditPostmanCodesComponent } from './Components/partners/partner-edit-postman-codes/partner-edit-postman-codes.component';
import { PartnerEditTimesComponent } from './Components/partners/partner-edit-times/partner-edit-times.component';

import { ConnectivityLogsComponent } from './Components/lockers/connectivity-logs/connection-logs.component';
import { PartnerToolsComponent } from './Components/partner-tools/partner-tools.component';
import { PartnerHomeComponent } from './Components/partner-home/partner-home.component';
import { PartnerNewOrderComponent } from './Components/partner-new-order/partner-new-order.component';
import { PartnerOrdertoProcessComponent } from './Components/partner-orderto-process/partner-orderto-process.component';
import { PartnerOrderVaultComponent } from './Components/partner-order-vault/partner-order-vault.component';
import { PartnerHistoryComponent } from './Components/partner-history/partner-history.component';
import { PartnerCreateNewOrderComponent } from './Components/partner-create-new-order/partner-create-new-order.component';
import { PartnerOrderComponent } from './Components/partner-order/partner-order.component';
import { LockerUploadComponent } from './Components/lockers/locker-upload/locker-upload.component';
import { PostmanCodeComponent } from './Components/postman-code/postman-code.component';
import { UsergroupComponent } from './Components/users/usergroup/usergroup.component';
import { UsergroupaddeditComponent } from './Components/users/usergroupaddedit/usergroupaddedit.component';
import { LockerdownreportComponent } from './Components/lockerdownreport/lockerdownreport.component';
import { FmFailedReportComponent } from './Components/fm-failed-report/fm-failed-report.component';
import { UseroperationsComponent } from './Components/useroperations/useroperations.component';
import { MaintenanceStaffListComponent } from './maintenance-staff-list/maintenance-staff-list.component';
import { MaintenanceStaffComponent } from './maintenance-staff/maintenance-staff.component';
import { PartnerCustomerComponent } from './partner-customer/partner-customer.component';
import { PartnerAddComponent } from './Components/partners/partner-add/partner-add.component';
import { PartnerSettingsComponent } from './Components/partner-settings/partner-settings.component';
import { PartnertoolauthGuard } from './partnertoolauth.guard';
import { IroningBarcodeComponent } from './Components/ironing-barcode/ironing-barcode.component';
import { SettingsComponent } from './Components/lockers/settings/settings.component';
import { CameraComponent } from './Components/lockers/camera/cameraoperations.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'signin',
    component: SignInComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login/bpost/callback',
    component: PingBpostComponent
  },
  {
    path: 'login/external/callback',
    component: PingExternalComponent
  },
  {
    path: 'parcels',
    component: ParcelListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parcels/:id',
    component: ParcelDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reservation',
    component: ReservationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reservation/:action',
    component: ReservationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/search',
    component: UserlistComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/details',
    component: UserDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/forbidden',
    component: ForbiddenUserComponent
  },
  {
    path: 'user/usergroup',
    component: UsergroupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/usergroupaddedit/:action',
    component: UsergroupaddeditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/usergroupaddedit/:action/:group',
    component: UsergroupaddeditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'userdetails/:id',
    component: UserDetailsPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'updateuser/:id',
    component: UserDetailsUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners',
    component: PartnerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'createPartners',
    component: PartnerAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id',
    component: PartnerDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id/deliverypoints',
    component: PartnerEditDeliverypointsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id/edit',
    component: PartnerEditInfoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id/times',
    component: PartnerEditTimesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id/postmans',
    component: PartnerEditPostmanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partners/:id/postmans-codes',
    component: PartnerEditPostmanCodesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'locker-upload',
    component: LockerUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lockerdownreport',
    component: LockerdownreportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partnertools',
    component: PartnerToolsComponent,
    canActivate: [PartnertoolauthGuard],
    children: [
      { path: '', component: PartnerHomeComponent },
      { path: 'newOrder', component: PartnerNewOrderComponent },
      { path: 'ordersToProcess', component: PartnerOrdertoProcessComponent },
      { path: 'ordersPlacedInVault', component: PartnerOrderVaultComponent },
      { path: 'history', component: PartnerHistoryComponent },
      { path: 'createOrder', component: PartnerCreateNewOrderComponent },
      { path: 'partnerOrder/:action', component: PartnerOrderComponent },
      { path: 'partnerOrder/:action/:id', component: PartnerOrderComponent },
      { path: 'customer', component: PartnerCustomerComponent },
      { path: 'settings', component: PartnerSettingsComponent },
      { path: 'createBarcode', component: IroningBarcodeComponent }
    ]

  },
  {
    path: 'postman',
    component: PostmanCodeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fm-failed',
    component: FmFailedReportComponent,
    canActivate: [AuthGuard]
  }, {

    path: 'userOperation',
    component: UseroperationsComponent,
    canActivate: [AuthGuard]
  },
  {

    path: 'maintenanceUser',
    component: MaintenanceStaffListComponent,
    canActivate: [AuthGuard]
  },
  {

    path: 'maintenanceAddUser',
    component: MaintenanceStaffComponent,
    canActivate: [AuthGuard]
  },
  {

    path: 'maintenanceEditUser/:id',
    component: MaintenanceStaffComponent,
    canActivate: [AuthGuard]
  },
  {

    path: 'ironing',
    component: IroningBarcodeComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'deliverypoints',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: LockerlistComponent,
      },
      {
        path: ':id',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: TerminalsComponent,
            canActivate: [RoutingGuard, AuthGuard]
          },
          {
            path: 'settings',
            component: SettingsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'details',
            component: LockerdetailsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'logs',
            component: LogsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'connectivity',
            component: ConnectivityLogsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'camera',
            component: CameraComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
