<div class="bg-info">
    <nav class="navbar navbar-expand-lg navbar-dark">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item" [appViewController]="{deliveryPoints:'showTerminals'}">
                    <button type="button" class="p-2 mx-auto text-white btn" (click)="goToTerminals()">{{'Doors'|translate}}</button>
                </li>
                <li class="nav-item" [appViewController]="{deliveryPoints:'showDetails'}">
                    <button type="button" class="p-2 mx-auto text-white btn" (click)="goToDetails()">{{'Details'|translate}}</button>
                </li>
                <li class="nav-item" [appViewController]="{deliveryPoints:'showLogs'}">
                    <button type="button" class="p-2 mx-auto text-white btn" (click)="goToLogs()">{{'Logs'|translate}}</button>
                </li>
                <li class="nav-item" [appViewController]="{deliveryPoints:'showDowntime'}">
                    <button type="button" class="p-2 mx-auto text-white btn" (click)="filterConnectionLogs()">{{'Downtime'|translate}}</button>
                </li>
                <li class="nav-item" [appViewController]="{deliveryPoints:'allowAction'}">
                  <button type="button" class="p-2 mx-auto text-white btn" (click)="goToSettings()">{{'Settings'|translate}}</button>
              </li>
              <li class="nav-item" *ngIf="lockerdetails && lockerdetails.features?.camera" [appViewController]="{deliveryPoints:'enableCamera'}">
                <button type="button" class="p-2 mx-auto text-white btn" (click)="goToCamera()">{{'Camera'|translate}}</button>
            </li>
            </ul>
        </div>
    </nav>
</div>
