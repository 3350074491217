import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './Service/authorization.service';
import { AppCookieService } from './Service/app.cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _authorizationService: AuthorizationService,
    private router: Router,
    private cookie: AppCookieService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let t = window.localStorage.getItem('token');
    let flag: boolean = false;
    if (t) {
      flag = true;
    }

    if (this._authorizationService.isLoggedIn || flag) {
      let getUserRole: any;
      let user = this._authorizationService.currentUser;
      if (user) {
        getUserRole = user.groups.map(element => element.name)
      } else {
        this._authorizationService.loadDdsUser()
      }

      if (getUserRole && getUserRole.includes("partnertoolgroup") && getUserRole.length == 1) {
        this.router.navigate(['user/forbidden'])
        return false
      }

      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate([''])
    return false;
  }
}
