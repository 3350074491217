<app-header></app-header>
<div [appViewController]="{partner:'showList'}">
  <ng-template
    #tipContentType>
      <b>DELIVERY</b>&nbsp;<i>Can make use of the network</i><br>
      <b>OWNER</b>&nbsp;<i>Owns a piece of the network</i>
  </ng-template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col float-end">
        <input
          type="image"
          src="/assets/img/filter.png"
          name="submit"
          width="30"
          height="30"
          alt="submit"
          data-toggle="collapse"
          data-target="#collapseExpiryDate"
          aria-expanded="false"
          aria-controls="collapseExpiryDate" />
      <input
        type="image"
        src="/assets/img/redIcon.png"
        name="submit"
        width="28"
        height="28"
        alt="submit"
        (click)="downloadAll()" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="col-12 collapse" id="collapseExpiryDate">
          <form [formGroup]="filterForm" class="pl-4 pr-4">
            <div class="card shadow p-3 mx-auto bg-white rounded mt-0 w-75">
              <div class="card-body p-3">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="groups">{{'Partner'|translate}}</label>
                    <ng-multiselect-dropdown formControlName="partnerName" [placeholder]="'-All-'|translate"
                      [settings]="bussinessPartnerSettings" [data]="FilterPartnersList">
                    </ng-multiselect-dropdown>
                  </div>

                  <div class="form-group col-md-6 mt-4">
                    <div class="col">
                      <button class="btn btn-outline-danger  mb-3 me-3" type="submit"
                        (click)="onSubmit()">{{'Filter'|translate}}</button>
                      <button class="btn btn-outline-danger  mb-3" (click)="onReset()">{{'Reset'|translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive pl-4 pr-4 mt-3 mb-4">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-sm table-bordered table-hover row-border hover text-center">
            <thead class="table-dark">
              <tr>
                <th scope="col">{{'Id'|translate}}</th>
                <th scope="col">{{'Name'|translate}}</th>
                <th scope="col">{{'Country'|translate}}</th>
                <th scope="col">{{'Type'|translate}} <i class="fa fa-info-circle info-icon-background tooltip_head"
                    [ngbTooltip]="tipContentType" placement="right"></i></th>
                <th scope="col">{{'Active'|translate}}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngIf="partnersList?.length != 0">
              <tr *ngFor="let partner of partnersList;">
                <td> {{partner.id}}</td>
                <td>
                  {{partner.name}}
                </td>
                <td>{{partner.country}}</td>
                <td>{{partner.type}}</td>
                <td>{{partner.active}}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm me-2 btn-success"
                    [routerLink]="['/partners/', partner.id]"
                    [appViewController]="{partner:'editPartner'}">{{'EDIT'| translate}}</button>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="partnersList?.length == 0">
              <tr>
                <td colspan="4" class="no-data-available">{{'No data!'|translate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div #partnerEdit [appViewController]="{partner:'editPartner'}"></div>
