<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9">
            <div class="head-name">{{'Deliverypoint schemas'|translate}}</div>
        
            <button data-toggle="modal" data-target="#addAcCodeModal" class="btn btn-info pull-right mb-3"
               ><i class="fa fa-plus" aria-hidden="true"></i> {{'Add Deliverypoint'|translate}}</button>
             
            <table class="table table-sm table-bordered table-hover row-border hover mt-3">
                <caption></caption>
                
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">{{'DP Id'|translate}}</th>
                        <th scope="col">{{'Locker Name'|translate}}</th>
                        <th scope="col">{{'NL Code'|translate}}</th>
                        <th scope="col">{{'LM Capacity'|translate}}</th>
                        <th scope="col">{{'Action'|translate}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="dpdsByAcCodeData?.length > 0">
                    <tr *ngFor="let partner of dpdsByAcCodeData;let i=index">
                        <td (click)="getDeliverypointDetails(partner.id)" *ngIf="partner?.deliveryPointId"> {{partner.deliveryPointId}}</td>
                        <td *ngIf="partner?.deliveryPointId">{{partner.lockerName}}</td>
                        <td *ngIf="partner?.deliveryPointId">
                            <div *ngIf="!partner.isShowInput">{{partner.nlCode}}</div>
                            <div *ngIf="partner.isShowInput"><input [(ngModel)]="partner.nlCode" class="form-control"></div>
                        </td>
                        <td *ngIf="partner?.deliveryPointId">
                            <div *ngIf="!partner.isShowInput">{{partner.LMCapacity}}</div>
                            <div *ngIf="partner.isShowInput"><input [(ngModel)]="partner.LMCapacity" class="form-control"
                                    appLengthLimit [maxLength]="3"></div>
                        </td>
                        <td class="text-center" *ngIf="partner?.deliveryPointId">
                            <div *ngIf="partner.isShowInput">
                                <i class="fa fa-floppy-o px-2 text-dark btn" aria-hidden="true" title="save"
                                     (click)='saveTableData(partner)'></i>
                                <i class="fa fa-times-circle px-2 text-dark btn" title="Cancel"
                                    aria-hidden="true" (click)='resetTableData(partner)'></i>
                            </div>
                            <div *ngIf="!partner.isShowInput">
                                <i class="fa fa-pencil-square-o px-2 text-dark btn" aria-hidden="true" title="Edit"
                                   (click)='editTableData(partner)'></i>
                                <i class="fa fa-trash-o px-2 text-danger btn"
                                data-toggle="modal" data-target="#deleteDeliverypointsModal" title="Delete"
                                    aria-hidden="true" (click)='saveId(partner)'></i>
        
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="dpdsByAcCodeData?.length == 0">
                    <tr>
                        <td colspan="4" class="no-data-available">{{'No data!'|translate}}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <button class="btn btn-secondary  me-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>
        </div>
    </div>
</div>


<div class="modal fade" #addAcCodeModal tabindex="-1" id="addAcCodeModal" role="dialog"
    aria-labelledby="addAcCodeModalLabel" aria-hidden="true">
    <form [formGroup]="addAcCodeForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-3 align-items-center">
                    <h3 class="modal-title align-items-center m-0">{{'Add Deliverypoint' | translate}}</h3>
                    <button type="button" class="close" (click)="resetInputValues()" data-dismiss="modal"
                        aria-label="Close" #closeAcCodeModal>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label for="lockerAcCode">{{'Add Deliverypoint'|translate}}</label>
                            <ng-multiselect-dropdown formControlName="lockerAcCode"
                                [placeholder]="'Select Deliverypoint'|translate" [settings]="dropdownSettings"
                                [data]="lockerNamedropdownList" [(ngModel)]="selectedItems">
                            </ng-multiselect-dropdown>
                          
                            <small *ngIf="submitted &&  formValidators.lockerAcCode.errors?.required"
                                class="form-text text-muted col-sm-8">{{'Please select add deliverypoint!' | translate}}
                            </small>
                        </div>
                        <div class="text-warning p-2">
                            <strong>Warning!</strong> {{'partnerDeliverypointAssginmentWarning' | translate}}
                        </div>
                        <div class="text-danger p-2" *ngIf="errorInAddDp">
                            {{errorInAddDp}}
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-secondary me-1" data-dismiss="modal"
                            (click)="resetInputValues()">{{'Cancel'|translate}}</button>
                        <button class="btn btn-danger" (click)="addAcCode()">{{'Add'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" tabindex="-1" id="postalCodeModal" role="dialog" aria-labelledby="postalCodeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'Add Postal Code' | translate}}</h5>
                <button type="button" (click)="resetInputValues()" class="close" data-dismiss="modal" aria-label="Close"
                    #closePostalCodeModal>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form name="form" (ngSubmit)="postalCodeForm.form.valid && addPostalCode()" #postalCodeForm="ngForm"
                    novalidate>
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>{{'Postal Code'| translate}}</label>
                            <input type="text" maxlength="4" name="postalCode" class="form-control"
                                [(ngModel)]="model.postalCode" #postalCode="ngModel" pattern="[0-9 ]{4}" required>
                            <div [hidden]="!postalCode.errors?.required">{{'Postal Code is required!' | translate}}</div>
                            <div [hidden]="!postalCode.errors?.pattern">{{'Please enter valid Postal Code!' | translate}}</div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-secondary mr-1" data-dismiss="modal"
                            (click)="resetInputValues()">{{'Cancel' | translate}}</button>
                        <button class="btn btn-primary" [disabled]="postalCode.invalid">{{'Add' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="deleteDeliverypointsModal" role="dialog" aria-labelledby="deleteDeliverypointsModal"
aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">{{'Delete'| translate}}</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="text-left">{{'partnerDeliverypointRemoveConfirmMessage'| translate}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel' | translate}}</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)='removeTableData()'>{{'Confirm' | translate}}</button>
    </div>
  </div>
</div>
</div>
