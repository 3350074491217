<app-header></app-header>
<app-loader *ngIf="showSpinner"></app-loader>
<app-lockers-menu [id]="id"></app-lockers-menu>
<br>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      
   
<form class="pl-4 pr-4" [formGroup]="DpSettingsForm" (ngSubmit)="onSubmit()">
  <div class="flex items-center justify-start">
    <input type="checkbox" id="checkbox" class="me-3 checkbox" formControlName="lidl_image">
    <label for="checkbox" class="h5">Lidl_image</label>
      <button type="submit" class="btn btn-danger" >{{'Save'|translate}}</button>
  </div>
</form>
</div>
</div>
</div>
