<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>

        <div class="col-9">
            <div class="head-name">{{'Postmans'|translate}}</div>

            <button *ngIf="currentPartnerId === 1" data-toggle="modal" data-target="#addPartnerPostmanModal" class="btn btn-danger pull-right mb-3"
                (click)="addPostman('Add')"><i class="fa fa-plus" aria-hidden="true" ></i> {{'Add Postman'|translate}}</button>

            <table class="table table-sm table-bordered table-hover row-border hover mt-3">
                <caption></caption>
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">{{'Id'|translate}}</th>
                        <th scope="col">{{'Name'|translate}}</th>
                        <th scope="col">{{'Username'|translate}}</th>
                        <th scope="col">{{'Active'|translate}}</th>
                        <th scope="col">{{'Language'|translate}}</th>
                        <th scope="col" *ngIf="currentPartnerId === 1">{{'Action'|translate}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="driverData?.length > 0">
                    <tr *ngFor="let partner of driverData;let i=index">
                        <td><div>{{partner.id}}</div></td>
                        <td><div class="text-capitalize">{{partner.name}}</div></td>
                        <td><div>{{partner.username}}</div></td>
                        <td><div>{{partner.active}}</div></td>
                        <td><div class="text-capitalize">{{partner.langDesc}}</div></td>

                        <td class="text-center" *ngIf="currentPartnerId === 1">
                            <div>
                                <button data-toggle="modal" data-target="#addPartnerPostmanModal" class="editBtn" (click)='editTableData(partner,"Edit")'><i class="fa fa-pencil-square-o px-2 text-dark" aria-hidden="true" title="Edit"
                                 ></i></button>
                                
                                <i class="fa fa-trash-o px-2 text-danger" data-toggle="modal" data-target="#deleteUserModal" title="Delete" type="button"
                                    aria-hidden="true" ></i>
                            </div>
                        </td>
                    </tr>
                    
                </tbody>
                <tbody *ngIf="driverData?.length == 0 && currentPartnerId === 1">
                    <tr>
                        <td colspan="6" class="no-data-available">{{'No data!'|translate}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="driverData?.length == 0 && currentPartnerId !== 1">
                    <tr>
                        <td colspan="5" class="no-data-available">{{'No data!'|translate}}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <button class="btn btn-secondary me-1" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>
        </div>
    </div>
</div>

<div class="modal fade" #addPartnerPostmanModal tabindex="-1" id="addPartnerPostmanModal" role="dialog"
    aria-labelledby="addPartnerPostmanModal" aria-hidden="true">
    <form [formGroup]="addPartnerPostmanForm">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title mb-0">{{btnAction +' '+'Postman' | translate}}</h3>
                    <button type="button" class="close" (click)="resetInputValues()" data-dismiss="modal"
                        aria-label="Close" #closePostmanModal>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>{{'Capabilities'|translate}}</label>
                            <ng-multiselect-dropdown 
                            [formControl]="addPartnerPostmanForm.controls['capabilities']"
                            [settings]="capabilitiesDropdownSetting" 
                            [data]="dropdownList"
                            [(ngModel)]="selectedItems" >
                            </ng-multiselect-dropdown>
                        
                            <small class="row">
                                <small *ngIf="submitted &&  formValidators.capabilities.errors?.required"
                                    class="form-text text-muted col-sm-12">{{'Please select capabilities' | translate}}
                                </small>
                            </small>
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>{{'Name'|translate}}</label>

                            <input type="text" formControlName="name" class="form-control" />
                            <small class="row">
                                <small *ngIf="submitted &&  formValidators.name.errors?.required"
                                    class="form-text text-muted col-sm-12">{{'Please enter name' | translate}}
                                </small>
                            </small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>{{'Username'|translate}}</label>

                            <input type="text" formControlName="username" class="form-control" />
                            <small class="row">
                                <small *ngIf="submitted && formValidators.username.errors?.required"
                                    class="form-text text-muted col-sm-12">{{'Please enter username' | translate}}
                                </small>
                                <small *ngIf="submitted && formValidators.username.errors?.pattern"
                                    class="form-text text-muted col-sm-12">{{'Please enter valid username' | translate}}
                                </small>
                            </small>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Add'">
                        <div class="form-group col-12">
                            <label>{{'Code'|translate}}</label>

                            <input type="text" formControlName="code" class="form-control" [maxLength]="6"/>
                            <small class="row">
                                <small *ngIf="submitted &&  formValidators.code.errors?.required"
                                    class="form-text text-muted col-sm-12">{{'Please enter code or check below generate secret option' | translate}}
                                </small>
                                <small *ngIf="submitted &&  formValidators.code.errors?.pattern"
                                    class="form-text text-muted col-sm-12">{{'Please enter numbers only' | translate}}
                                </small>
                            </small>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Edit'">
                        <div class="form-group col-12">
                            <label>{{'Code'|translate}}</label>

                            <input type="text" formControlName="code" class="form-control" [maxLength]="6" (input)="onInputChange()"/>
                            <small class="row">
                                <small *ngIf="submitted &&  formValidators.code.errors?.pattern"
                                    class="form-text text-muted col-sm-12">{{'Please enter numbers only' | translate}}
                                </small>
                            </small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>{{'Language'|translate}}</label>
                            <select name="language" class="form-control" formControlName='language'>
                            <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                            <option *ngFor="let item of languageList" [value]="item.language" class="text-capitalize">{{item.description}}</option>
                        </select>
                        <small class="row">
                            <small *ngIf="submitted &&  formValidators.language.errors?.required"
                                class="form-text text-muted col-sm-12">{{'Please select language' | translate}}
                            </small>
                        </small>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Add'">
                        <div class="form-group col-12">
                            <label>{{'Secret Validity'|translate}}</label>

                            <select name="secretValidity" class="form-control" formControlName='secretValidity'>
                            <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                            <option value="standard" class="text-capitalize">Standard</option>
                            <option value="1day" class="text-capitalize">1 Day</option>
                        </select>
                        <small class="row">
                            <small *ngIf="submitted &&  formValidators.secretValidity.errors?.required"
                                class="form-text text-muted col-sm-12">{{'Please select Secret Validity' | translate}}
                            </small>
                        </small>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Edit'">
                        <div class="form-group col-12">
                            <label>{{'Secret Validity'|translate}}</label>

                            <select name="secretValidity" class="form-control" formControlName='secretValidity'>
                            <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                            <option value="standard" class="text-capitalize">Standard</option>
                            <option value="1day" class="text-capitalize">1 Day</option>
                        </select>
                        <small class="row">
                            <small *ngIf="submitted &&  formValidators.secretValidity.errors?.required"
                                class="form-text text-muted col-sm-12">{{'Please select Secret Validity' | translate}}
                            </small>
                        </small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            
                            <input type="checkbox" class="addDriverCheckBox" formControlName="active"  />
                            <label >{{'Active'|translate}}</label>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Add'">
                        <div class="form-group col-12">
                            <input type="checkbox" class="addDriverCheckBox" formControlName="generateSecret"  (change)="onCheckboxChangeAddOption()"/>
                            <label >{{'Generate secret'|translate}}</label>
                        </div>
                    </div>
                    <div class="form-row" *ngIf= "btnAction ==='Edit'">
                        <div class="form-group col-12">
                            <input type="checkbox" class="addDriverCheckBox" formControlName="generateSecret" (change)="onCheckboxChange()"/>
                            <label>{{'Regenerate secret'|translate}}</label>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-secondary me-1" data-dismiss="modal"
                            (click)="resetInputValues()">{{'Cancel'|translate}}</button>
                        <button *ngIf= "btnAction ==='Add'" class="btn btn-danger" (click)="addPartnerPostman()">{{'Add'|translate}}</button>
                        <button *ngIf= "btnAction ==='Edit'" class="btn btn-danger" (click)="updatePartnerPostman()">{{'Update'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">{{'Delete'| translate}}</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <p class="text-left">{{'Are you sure you want to delete the user'| translate}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)='removeTableData()'>Confirm</button>
    </div>
  </div>
</div>
</div>