import { Component } from '@angular/core';
import { AuthorizationService } from '../../../Service/authorization.service';

@Component({
  selector: 'app-forbidden-user',
  templateUrl: './forbidden-user.component.html'
})
export class ForbiddenUserComponent {

  constructor(public _authorizationService: AuthorizationService ) { }


}
