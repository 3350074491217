import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';

@Component({
  selector: 'app-locker-upload',
  templateUrl: './locker-upload.component.html',
  styleUrls: ['./locker-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LockerUploadComponent implements OnInit {

  acCode: any;
  translateData: any;
  uploadForm: FormGroup;
  constructor(
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _toastr: ToastrService,
    public _router: Router,
    private readonly _formBuilder: FormBuilder, 
    private readonly _appService: AppService, 
    private readonly _langtransService: LangtransService) {

    this.uploadForm = this._formBuilder.group({
      acceptSoftReservations: ['', Validators.required],
      livedate: ['', Validators.required],
      file: ['', Validators.required],
      lockerName: [''],
      lockerPostalCode: [''],
      acCode: ['', Validators.required],
      provider:['', Validators.required],
      language: ['', Validators.required]
    });
  }

  invalid = false;
  xmlString: any;
  selectedFile: any;
  selectedFilePath: any;
  public errorTxt = false;
  acCodeList: any = [];
  selectedAccode: any;
  showUnauthorized = false;
  submitted: boolean;
  languagechar: any;
  deliveryPointList: any;
  lockerName: any;
  filteredList1: any;
  variables: any;
  fileSizeExceeded: boolean;
  showSpinner = false;
  fileType: boolean;

  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  onFileChange(event: any): void {
    this.errorTxt = false;
    this.selectedFile = event.target.files[0];
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const validationData = this.validateFile(file);
      if (!validationData) {
        this.convertXMLToString(file);
      }

    } else {
      this.errorTxt = true;
    }
  }

  convertXMLToString(file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.xmlString = e.target.result as string;
    };
    reader.readAsText(file);
  }

  validateFile(file: File): boolean {
    this.fileSizeExceeded = false;
    this.fileType = false;
    const allowedTypes = ['text/xml'];
    if (!allowedTypes.includes(file.type)) {
      this.invalid = true;
      this.fileType = true;

    } else if (file.size > 5242880) {
      this.invalid = true;
      this.fileSizeExceeded = true;
    }
    else {
      this.invalid = false;
    }
    return this.invalid;
  }

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'success') {
          this._toastr.success(res);
        }
        if (toastrstatus === 'error') {
          this._toastr.error(res);
        }
      }
    );
  }

  onUpload() {
    this.submitted = true;
    for (const control in this.uploadForm.controls) {
      if (this.uploadForm.controls.hasOwnProperty(control)) {
        this.uploadForm.get(control).markAsTouched();
      }
    }
    if (this.uploadForm.valid) {
      this.showSpinner = true;
      const reqObj = {
        fileReq: this.xmlString,
        acceptSoftReservations: 
        this.uploadForm.controls.acceptSoftReservations.value,
        acCode: this.uploadForm.controls.acCode.value,
        livedate: this.uploadForm.controls.livedate.value,
        provider: this.uploadForm.controls.provider.value,
        language: this.uploadForm.controls.language.value
      };
      if (this.xmlString && this.selectedFile && this.invalid === false) {

        this._deliverypointsService.uploadXml(reqObj).subscribe(
          res => {
            if (res) {
              this.showSpinner = false;
            }
            const response = JSON.stringify(res);
            const parsedRes = JSON.parse(response);
            if (parsedRes.success) {
              this.langaugeFetch(this.translateData, 'Fileuploaded', 'success');
              this.uploadForm.reset();
              this.uploadForm.get('acCode').setErrors(null);
              this.uploadForm.get('file').setErrors(null);
              this.uploadForm.patchValue({
                'acceptSoftReservations': '',
                'lockerName': '',
                'lockerPostalCode': ''
              });
            } else if (parsedRes?.failure?.reason === 'PARCEL_AVAILABLE') {
              this.showSpinner = false;
              this.langaugeFetch(this.translateData, 
                'FailedtouploadDueToExistingParcel', 'error');
              console.error(parsedRes.failure);
            } else {
              this.showSpinner = false;
              this.langaugeFetch(this.translateData, 'Failedtoupload', 'error');
              console.error(parsedRes);
            }
          },
          err => {
            this.showSpinner = false;
            this.langaugeFetch(this.translateData, 'Failedtoupload', 'error');
            console.error(err);
          }
        );
      } else {
        this.errorTxt = this.invalid === true ? false : true;
        this.showSpinner = false;
        if (this.fileSizeExceeded) {
          this.langaugeFetch(this.translateData, 
            'XMLfileSizeExceeded', 'error');
        } else if (this.fileType) {
          this.langaugeFetch(this.translateData, 'InvalidFile', 'error');
        } else {
          this.langaugeFetch(this.translateData, 'Failedtoupload', 'error');
        }
      }
    }
  }

  ngOnInit(): void {
    this.showSpinner=true;
    this._deliverypointsService.fetchMany({}).then(res => {
      this.showSpinner=false;
      this.deliveryPointList = res;
      let code;
      this.deliveryPointList.map(val => {
        code = val.acCode;
        this.acCodeList.push(code);
      });

      this.variables = this.acCodeList;
      this.filteredList1 = this.variables.slice();
    },err => {
      this.showSpinner=false;
      console.error(err);
    });

    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
  }

  get formValidators() {
    return this.uploadForm.controls;
  }

  onChangeCode(e, value) {
    this.acCode = value;
    const filteredVal = this.deliveryPointList.filter((element) => {
      if (element.acCode === this.acCode) {
        return element;
      }
    });

    this.uploadForm.patchValue({
      'lockerName': filteredVal[0].lockerName,
      'lockerPostalCode': filteredVal[0].address.postalCode
    });
  }

  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }
  disableDate() { 
    return false; 
  } 
}

