<app-header></app-header>
<div [appViewController]="{parcels:'list'}" #myDiv>
  <div class="container-fluid">
    <div class="mt-3" *ngIf="showDownload">
      <div class="row float-end">
        <div class="col-12">
          <input
            type="image"
            src="/assets/img/redIcon.png"
            style="margin-right:20px; margin-top:10px; margin-bottom: 10px;"
            name="submit"
            width="28"
            height="28"
            alt="submit"
            (click)="exportToCSV()"
            [appViewController]="{parcels:'exportList'}">
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <form [formGroup]="parcelForm">
          <div class="row mb-2">
            <div class="col-2">
              <label for="barcode" class="form-label">{{'Barcode'|translate}}</label>
              <input
                class="form-control"
                formControlName="barcode"
                name="barcode"
                placeholder="code" />
            </div>
            <div class="col-3">
              <label for="parcelLockerName" class="form-label">{{'Parcel Locker Name'|translate}}</label>
              <ng-multiselect-dropdown
                class="form-control"
                formControlName="parcelLockerName"
                [placeholder]="'-All-'|translate"
                [settings]="parcelLockerNamedropdownSettings"
                [data]="parcelLockerNamedropdownList"
                [(ngModel)]="parcelLockerNameselectedItems">
              </ng-multiselect-dropdown>
            </div>
            <div class="col-3">
              <label for="orderDate" class="form-label">{{'Order Date'|translate}}</label>
              <div class="card">
                <div formGroupName="orderDate" class="row card-body p-0">
                  <div class="form-group col-6">
                    <div class="row">
                      <div class="input-group">
                        <input
                          type="text"
                          placeholder="dd-mm-yyyy"
                          class="form-control"
                          formControlName="orderDateFrom"
                          #orderDateFromInput
                          bsDatepicker
                          [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                          <span class="input-group-text">
                            <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateFromInput')"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="text" placeholder="dd-mm-yyyy" class="form-control"
                        formControlName="orderDateTo" #orderDateToInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateToInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="f.orderDate && f.orderDate.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
            </div>
            <div class="col-2">
              <label for="orderType" class="form-label">{{'Order Type'|translate}}</label>
              <ng-multiselect-dropdown
                class="form-control"
                formControlName="orderType"
                [placeholder]="'-All-'|translate"
                [settings]="orderTypedropdownSettings"
                [data]="orderTypedropdownList"
                [(ngModel)]="orderTypeselectedItems"
                 ></ng-multiselect-dropdown>
            </div>
            <div class="col-2">
              <label for="product" class="form-label">{{'Product'|translate}}</label>
              <select
                class="form-control mr-sm-2"
                formControlName="product"
                name="product">
                <option selected value="">{{'Choose Option...'|translate}}</option>
                <option value="REDIRECTED">{{'Redirected'|translate}}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3">
              <label for="dateOfPickupCustomer" class="form-label">{{'Date of Pickup Customer'|translate}}</label>
              <div class="card">
                <div formGroupName="dateOfPickup" class="row">
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfPickupCustomerFrom"
                        #dateOfPickupCustomerFromInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}"
                        placement="bottom left" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCustomerFromInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfPickupCustomerTo"
                        #dateOfPickupCustomerToInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCustomerToInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="f.dateOfPickup && f.dateOfPickup.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
            </div>
            <div class="col-3">
              <label for="dateOfPickupCourier" class="form-label">{{'Date Courier Pickup'|translate}}</label>
              <div class="card">
                <div formGroupName="dateOfPickupCourier" class="row">
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfPickupCourierFrom" 
                        #dateOfPickupCourierFromInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCourierFromInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfPickupCourierTo"
                        #dateOfPickupCourierToInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCourierToInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="f.dateOfPickupCourier && f.dateOfPickupCourier.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
            </div>
            <div class="col-3">
              <label for="dateOfDrop" class="form-label">{{'Date Courier Delivery'|translate}}</label>
              <div class="card">
                <div formGroupName="dateOfDrop" class="row">
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfDropFrom"
                        #dateOfDropFromInput
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfDropFromInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="dateOfDropTo"
                        #dateOfDropToInput
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfDropToInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="f.dateOfDrop && f.dateOfDrop.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
            </div>
    
            <div class="form-group col-md-3" *ngIf="partnerDropdown">
              <label for="partnerName" class="form-label">{{'Partner'|translate}}</label>
              <ng-multiselect-dropdown
                class="form-control"
                formControlName="partnerName" 
                [placeholder]="'Partner'|translate"
                [settings]="partnerNamedropdownSettings"
                [data]="partnerNamedropdownList"
                [(ngModel)]="partnerNameselectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="row collapse" id="collapseExample">
            <div class="row mb-2">
              <div class="col-3">
                <label for="customerName" class="form-label">{{'Customer Name'|translate}}</label>
                <input
                  class="form-control"
                  formControlName="customerName"
                  name="customerName"
                  placeholder="eg: %Fred%" />
              </div>
    
              <div class="col-3">
                <label for="customerEMail" class="form-label">{{'Customer E-mail'|translate}}</label>
                <input
                  class="form-control"
                  formControlName="customerEMail"
                  name="customerEMail"
                  placeholder="eg: %Fred@hotmail.com%" />
              </div>
              <div class="col-3">
                <label for="senderEmail" class="form-label">{{'Sender Email'|translate}}</label>
                <input
                  class="form-control"
                  formControlName="senderEmail"
                  name="senderEmail"
                  placeholder="eg: %Fred@hotmail.com%" />
              </div>
              <div class="col-3">
                <label for="senderName" class="form-label" >{{'Sender Name'|translate}}</label>
                <input
                  class="form-control"
                  formControlName="senderName"
                  name="senderName"
                  placeholder="eg: %zalando%" />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="reservationRequest" class="form-label">{{'Reservation Request Successful'|translate}}</label>
                <select
                  class="form-control"
                  formControlName="reservationRequest"
                  name="reservationRequest">
                    <option selected value="">{{'Choose Option...'|translate}}</option>
                    <option value="true">{{'Yes'|translate}}</option>
                    <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
              <div class="col-3">
                <label for="lastStatus" class="form-label">{{'Last Status of Parcel'|translate}}</label>
                <ng-multiselect-dropdown
                  class="form-control"
                  formControlName="lastStatus"
                  [placeholder]="'-All-'|translate"
                  [settings]="lastStatusdropdownSettings"
                  [data]="lastStatusdropdownList"
                  [(ngModel)]="lastStatusselectedItems">
                </ng-multiselect-dropdown>
              </div>
              <div class="col-3 elemMargin">
                <label for="excludeCancelledOrders" class="form-label">{{'Exclude Cancelled Orders'|translate}}</label>
                <select
                  class="form-control"
                  formControlName="excludeCancelledOrders"
                  name="excludeCancelledOrders">
                    <option selected value="">{{'Choose Option...'|translate}}</option>
                    <option value="true">{{'Yes'|translate}}</option>
                    <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
              <div class="form-group col-md-3 elemMargin">
                <label for="lockerType" class="form-label">{{'Locker Type'|translate}}</label>
                <select class="form-control" formControlName="lockerProvider"
                  name="lockerProvider">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="Lean">{{'Lean'|translate}}</option>
                  <option value="Cubee">{{'Classic'|translate}}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <button
        class="btn btn-danger me-2"
        type="submit"
        (click)="onSubmit()">{{'Filter'|translate}}</button>
      <button
        class="btn btn-danger me-2"
        type="button"
        data-toggle="collapse"
        data-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample">
          {{'Advanced Filters'|translate}}</button>
      <button
        class="btn btn-outline-danger"
        type="submit"
        (click)="onReset()">{{'Reset'|translate}}</button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="col-12">
      <div class="table-responsive pl-2 pr-2">
        <table
          datatable 
          [dtOptions]="dtOptions"
          *ngIf="result"
          class="table table-sm table-bordered table-hover row-border hover text-center">
          <thead class="table-dark">
            <tr>
              <th scope="col">{{'Barcode'|translate}}</th>
              <th scope="col">{{'Order Date'|translate}}</th>
              <th scope="col">{{'Parcel Locker Name'|translate}}</th>
              <th scope="col">{{'View Status in Etracker'|translate}}</th>
              <th scope="col">{{'Status'|translate}}</th>
            </tr>
          </thead>
          <tbody *ngIf="result?.length != 0">
            <tr *ngFor="let res of result;">
                <td>
                  <a [routerLink]="['/parcels/', res.id]">{{res.barcode}}</a>
                </td>

                <td *ngIf="res.reservation.date"><span class="d-none">{{res.reservation.date}}</span>
                  {{res.reservation.date | utctolocaldate:'DD/MM/YYYY HH:mm:ss' }}</td>
                <td *ngIf="!res.reservation.date">-</td>

                <td *ngIf="res.deliveryPoint.name">{{res.deliveryPoint.name}}</td>
                <td *ngIf="!res.deliveryPoint.name">-</td>

                <td><a *ngIf="res.dds?.partrner?.id == 1" href="{{getetrackerUrl(res.barcode)}}" target="_blank" rel="noopener">VIEW DETAILS</a></td>

                <td *ngIf="res.physicalStatus.eventCode && res.physicalStatus.eventSubCode">
                  {{res.physicalStatus.eventCode}}</td>
                <td *ngIf="!res.physicalStatus.eventCode || !res.physicalStatus.eventSubCode">-</td>
              </tr>
          </tbody>
          <tbody *ngIf="result?.length == 0">
            <tr>
              <td colspan="5" class="no-data-available">No data!</td>
            </tr>
          <tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>