import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from 'src/app/Service/langtrans.service';

@Component({
  selector: 'app-maintenance-staff-list',
  templateUrl: './maintenance-staff-list.component.html',
  styleUrls: ['./maintenance-staff-list.component.scss']
})
export class MaintenanceStaffListComponent implements OnInit {
  addUserForm: any;
  submitted: boolean;
  partnerList: any;
  deleteId: string;
  langKey: any;
  partnerName: any;
  constructor(private readonly _fb: FormBuilder,
     private readonly _router: Router,
     private readonly _partnerService: PartnerService,
     private readonly _langtransService: LangtransService,
     private readonly _appService: AppService,
     private readonly _toastr: ToastrService, 
     private readonly _spinner: SpinnerService) {
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    });
    this._spinner.show();

    this._partnerService.fetchMaintenanceUser().subscribe((res) => {
      this._spinner.hide();
      this.partnerList = res;
    }, (err: any) => {
      console.error(err);
      this._spinner.hide();
    });
  }
  getModalId(id, name) {
    this.deleteId = id;
    this.partnerName = name;
  }
  onDelete() {
    const obj = {
      id: this.deleteId
    };
    this._partnerService.deleteMaintenanceUser(obj).subscribe((res) => {
      if (res === 'Deleted successfully') {
        this.translator(this.langKey, 'User deleted successfully', true);
      }
      this.partnerList = 
      this.partnerList.filter(item => item.id !== this.deleteId);
    });
  }
  onEdit(partner) {
    this._router.navigate(['/maintenanceEditUser/', partner.id]);
  }
  addMaintenanceUser() {
    this._router.navigate(['/maintenanceAddUser']);
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }
  translator(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this._toastr.success(res);
      } else {
        this._toastr.error(res);
      }
    });
  }
}
