<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div class="container pt-5">
    <div class="row">
        <div class="col-2"></div> 
<div class="col-8 card" [appViewController]="{partner:'addPartner'}" >
    <div class="col-12 px-4 py-2">
        <h2 class="card-title text-center">{{'Add Partner'|translate}}</h2>
        <form [formGroup]="partnerForm">
            <div class="form-group">
                <div class="row">
                    <label class="form-label col-3 required" for="partnerName">{{'Name'|translate}}</label>
                    <div class="col-9">
                    <input type="text" class="form-control col-sm-9" formControlName="partnerName" id="partner name"
                        [placeholder]="'partner name' | translate">
                    </div>
                </div>

                <small class="row ">
                    <small class="col-3 ms-4"></small>
                    <small *ngIf="submitted &&  formValidators.partnerName.errors?.required"
                        class="form-text text-muted col-9">{{'Please enter partner name' | translate}}
                    </small>
                </small>
            </div>

            <div class="my-3">
                <div class="row">
                    <label class="form-label col-3 required" for="country">{{'Country'|translate}}</label>
                    <div class="col-9">
                    <select class="form-select" formControlName="country" name="country">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="belgium">Belgium</option>
                    </select>
                </div>
                </div>
                <small class="row">
                    <small class="col-3 ms-4"></small>
                    <small *ngIf="submitted &&  formValidators.country.errors?.required"
                        class="form-text text-muted col-9">{{'Please select country' | translate}}
                    </small>
                </small>

            </div>
            <div class="my-3">
                <div class="row">
                    <label class="form-label col-3 required" for="active">{{'Active'|translate}}</label>
                    <div class="col-9">
                    <select class="form-select" formControlName="active" name="active">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                </div>
                <small class="row">
                    <small class="col-3"></small>
                    <small *ngIf="submitted &&  formValidators.active.errors?.required"
                        class="form-text text-muted col-9">{{'Please select active option' | translate}}
                    </small>
                </small>

            </div>
            <div class="my-3">
                <div class="row">
                    <label class="form-label col-3 required" for="active">Partner type</label>
                    <div class="col-9">
                    <select class="form-select" formControlName="courier" name="courier">
                        <option selected value="">{{'Choose Option...'|translate}}</option>
                        <option value="DELIVERY">DELIVERY</option>
                        <option value="OWNER">OWNER</option>
                    </select>
                </div>
                </div>
                <small class="row">
                    <small class="col-3 ms-4"></small>
                    <small *ngIf="submitted &&  formValidators.active.errors?.required"
                        class="form-text text-muted col-9">{{'Please select active option' | translate}}
                    </small>
                </small>

            </div>
            <div class="my-3">
                <div class="row">
                    <label class="form-label col-3" for="prsId">{{'PRS ID'|translate}}</label>
                    <div class="col-9">
                    <input type="text" class="form-control" formControlName="prsId" id="prsId"
                        [placeholder]="'PRS ID' | translate">
                        </div>
                </div>

                <small class="row">
                    <small class="col-3"></small>
                    <small *ngIf="submitted &&  formValidators.prsId.errors?.pattern"
                        class="form-text text-muted col-9">{{'Please enter numbers only' | translate}}
                    </small>
                </small>
            </div>
            </form>
            <div class="text-center pb-4">
                <button class="btn btn-danger" type="Submit" (click)="onSubmit()">{{'Submit'|translate}}</button>
                <button class="btn btn-outline-danger ms-3" (click)="cancel()">{{'Cancel'|translate}}</button>
            </div>

    </div>
</div>
    </div>
</div>
