<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9">
            <h2 class="card-title text-center">{{'Edit Partner'|translate}}</h2>
            <form [formGroup]="partnerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <div class="row">
                        <label class="required col-sm-3 ms-4" for="partnerName">{{'Name'|translate}}</label>
                        <input type="text" class="form-control col-sm-8" formControlName="partnerName" id="partner name"
                            placeholder="{{'partner name'|translate}}" readonly>
                    </div>

                    <small class="row">
                        <small class="col-sm-3 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.partnerName.errors?.required"
                            class="form-text text-muted col-sm-8">{{'Please fill partnerName' | translate}}
                        </small>
                    </small>
                </div>

                <div class="form-group">
                    <div class="row">
                        <label class="required col-sm-3 ms-4" for="country">{{'Country'|translate}}</label>
                        <select class="form-control col-sm-8" formControlName="country" name="country" disabled>
                            <option selected value="">{{'Choose Option...'|translate}}</option>
                            <option value="belgium">Belgium</option>
                        </select>

                    </div>
                    <small class="row">
                        <small class="col-sm-3 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.country.errors?.required"
                            class="form-text text-muted col-sm-8">{{'Please select country' | translate}}
                        </small>
                    </small>

                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="required col-sm-3 ms-4" for="active">{{'Active'|translate}}</label>
                        <select class="form-control col-sm-8" formControlName="active" name="active">
                            <option selected value="">{{'Choose Option...'|translate}}</option>
                            <option value="true">{{'Yes'|translate}}</option>
                            <option value="false">{{'No'|translate}}</option>
                        </select>
                    </div>
                    <small class="row">
                        <small class="col-sm-3 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.active.errors?.required"
                            class="form-text text-muted col-sm-8">{{'Please select active option' | translate}}
                        </small>
                    </small>

                </div>

                <div class="form-group">
                    <div class="row">
                        <label class="required col-sm-3 ms-4" for="partnerName">{{'PRS ID'|translate}}</label>
                        <input type="text" class="form-control col-sm-8" formControlName="prsId" id="prsId"
                            placeholder="{{'PRS ID'|translate}}">
                    </div>

                    <small class="row">
                        <small class="col-sm-3 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.prsId.errors?.pattern"
                            class="form-text text-muted col-sm-8">{{'Please enter numbers only' | translate}}
                        </small>
                    </small>
                </div>
                <div class="text-center pb-4">
                    <button class="btn btn-danger" type="Submit">{{'Submit'|translate}}</button>
                    <button class="btn btn-outline-danger ms-3" (click)="cancel()">{{'Cancel'|translate}}</button>

                </div>

            </form>
        </div>
    </div>
</div>