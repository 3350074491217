import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './Service/authorization.service';
import { UserService } from './Service/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingGuard implements CanActivate {
  userInfo: any;
  constructor(
    private readonly _userService: UserService,
    private readonly _router: Router,
    private readonly _authorizationService: AuthorizationService
  ) { }
  canActivate(  
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): 
    Observable<boolean> | Promise<boolean> | boolean {

    this._authorizationService.currentDdsUser.subscribe(user => { 
    this.userInfo = user;
    const urlParts = state.url.split('/');

    // Only related to /deliverypoints/123456
    const inDeliverypointMain = 
    urlParts.length === 3 && urlParts[1] === 'deliverypoints';

    if (!inDeliverypointMain) { return true; }

    const canAccessMain = 
    this._userService.checkPermission(this.userInfo, 'deliveryPoints', 'showTerminals' );
    const canAccessDetails = 
    this._userService.checkPermission(this.userInfo, 'deliveryPoints', 'showDetails');
    const canAccessLogs = 
    this._userService.checkPermission(this.userInfo, 'deliveryPoints', 'showLogs', );
    const canAccessDowntime = 
    this._userService.checkPermission(this.userInfo, 'deliveryPoints', 'showDowntime');

    if (canAccessMain) { return true; }

    if (!canAccessMain && canAccessDetails) {
      this._router.navigate([state.url + '/details']);
      return false;
    }

    if (!canAccessMain && canAccessLogs) {
      this._router.navigate([state.url + '/logs']);
      return false;
    }

    if (!canAccessMain && canAccessDowntime) {
      this._router.navigate([state.url + '/connectivity']);
      return false;
    }

    return true;
  });
  return
  }
}
