import { Injectable, Inject } from '@angular/core';
import {
  AuthorizationServiceConfigurationJson,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  FetchRequestor,
  LocalStorageBackend,
  DefaultCrypto,
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE
} from '@openid/appauth';
import {NoHashQueryStringUtils} from './noHashQueryStringUtils';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthorizationConfig } from './authorization_config';
import { AuthorizationService } from '../Service/authorization.service';
import { UserService } from './user.service';
import { authEnvironmentExternal } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  configuration: AuthorizationServiceConfigurationJson = null;
  error: any = null;
  authorizationHandler: any = null;
  tokenHandler: any = null;
  notifier: any = null;
  request: any = null;
  response: any = null;
  code: string;

  constructor(private readonly _route: ActivatedRoute, 
    private readonly _router: Router, 
    private readonly _userService: UserService,
    private readonly  _authorizationService: AuthorizationService,
  ) {
    this.authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );

    this.tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    this.notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        this.request = request;
        this.response = response;
        this.code = response.code;

        let extras = null;
        if (this.request && this.request.internal) {
          extras = {};
          extras.code_verifier = this.request.internal.code_verifier;
        }

        const tokenRequest = new TokenRequest({
          client_id: authEnvironmentExternal.client_id,
          redirect_uri: authEnvironmentExternal.redirect_uri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: this.code,
          refresh_token: undefined,
          extras
        });

        AuthorizationServiceConfiguration.fetchFromIssuer(authEnvironmentExternal.issuer_uri, new FetchRequestor())
          .then((oResponse: any) => {
            this.configuration = oResponse;
            return this.tokenHandler.performTokenRequest(this.configuration, tokenRequest);
          })
          .then((oResponse) => {
            window.localStorage.setItem('token', oResponse.accessToken);
            const res = this._authorizationService.completeAuthorizationRequest(oResponse.accessToken);
            this._authorizationService.loadDdsUser();

            this._authorizationService.setPrivilegeValue(res);
            this._authorizationService.currentDdsUser.subscribe(user => {
              if (user?.partner?.type?.toUpperCase() === 'OWNER') { this._router.navigate(['partnertools']); }
              else { this._router.navigate(['parcels']); }
            });;
          })
          .catch(oError => {
            this.error = oError;
          });
      }
    });
  }



  handleCode() {
    this.code = this._route.snapshot.queryParams.code;

    if (!this.code) {
      this.error = 'Unable to get authorization code';
      return;
    }
    this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }
}
