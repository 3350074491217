

<form [formGroup]="postmanLoginForm" class="container mb-3 mt-3">
    <div class="form-group">
        <label class="required" for="Capabilities">Capabilities</label>
        <app-multi-select-dropdown
         [list]="list"
         formContolale="Capabilities"
         (shareCheckedList)="shareCheckedList($event)" >
        </app-multi-select-dropdown>
     <small *ngIf="!dropSuccess" class="form-text text-muted">please fill name field</small>
        </div>
    <div class="form-group">
      <label class="required" for="Name">Name</label>
      <input type="text" formControlName="name" class="form-control" id="Name" aria-describedby="nameHelp" placeholder="Enter Name">
      
      <small *ngIf="submitted &&  formValidators.name.errors?.required" class="form-text text-muted">please fill name field</small>
    </div>
    <div class="form-group">
      <label class="required" for="matriculeID">Matricule ID</label>
      <input type="text" formControlName="matriculeID" class="form-control" id="matriculeID" placeholder="Matricule ID">
      <small *ngIf="submitted &&  formValidators.matriculeID.errors?.required" class="form-text text-muted">please fill Matricule ID</small>
    </div>
    <div class="form-group">
        <label class="required" for="acCode">Ac Code</label>
        <textarea class="form-control" (keyup)="textareaValues($event.target.value)" formControlName="acCode" placeholder="please enter format like 36996,36995" id="acCode" rows="3"></textarea>
        <small *ngIf="submitted &&  formValidators.acCode.errors?.required" class="form-text text-muted">please fill Ac Code</small>
      </div>
      <button type="submit" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="submit" class="btn btn-primary float-right" (click)="submitData()">Submit</button>
  </form>
