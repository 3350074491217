<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div #myDiv [appViewController]="{deliveryPoints:'showOnboard'}">
  <div class="container pt-5">
    <div class="card row">
      <div class="col-12 px-4 py-2">
      <h2 class="card-title text-center">{{'Upload File'|translate}}</h2>

      <form [formGroup]="uploadForm" (ngSubmit)="onUpload()" class="px-4">
        <div class="row mb-2">
          <label class="required col-3 col-form-label" for="acceptSoftReservations">{{'Soft Reservation'|translate}}</label>
          <div class="col-9">
            <select
              class="form-control"
              formControlName="acceptSoftReservations"
              name="acceptSoftReservations"
            >
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="true">{{'Yes'|translate}}</option>
              <option value="false">{{'No'|translate}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 col-form-label"></div>
          <div
            *ngIf="uploadForm.get('acceptSoftReservations').hasError('required') && uploadForm.get('acceptSoftReservations').touched"
            class="form-text text-muted col-9">{{'please select soft Reservation'|translate}}</div>
        </div>
        <div >
          <div class="row mb-2">
            <label class="required col-3 col-form-label" for="Date">{{'Live Date'|translate}}</label>
            <div class="col-9">
              <input class="form-control" formControlName="livedate"
                name="livedate" type="date" [min]="getToday()" (keydown)="disableDate()"/>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div
              *ngIf="uploadForm.get('livedate').hasError('required') && uploadForm.get('livedate').touched"
              class="form-text text-muted col-9">{{'please select date' |translate}}
            </div>
          </div>
        </div>
        <div>
          <div class="row mb-2">
            <label class="required col-3" for="File">{{'Select File'|translate}}</label>
            <input type="file" formControlName="file" class="h-auto w-100"
              class=" btn btn-outline-danger col-9" [(ngModel)]="selectedFilePath" (change)="onFileChange($event)"
              accept=".doc,.xml,.txt" />
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div
              *ngIf="invalid && errorTxt === false && fileSizeExceeded == false"
              class="form-text text-muted col-9">{{'Unsupported file format. Please upload an XML file!'|translate}}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div *ngIf="uploadForm.get('file').hasError('required') && uploadForm.get('file').touched"
              class="form-text text-muted col-9">{{'Please select file'|translate}}</div>
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div *ngIf="invalid && errorTxt === false && fileSizeExceeded == true"
              class="form-text text-muted col-9">{{'File exceeds the 5 MB size limit!'|translate}}</div>
          </div>
        </div>
        <div>
          <div class="row mb-2">
            <label class="required col-3 col-form-label" for="code">{{'Ac Code'|translate}}</label>
            <mat-form-field class="col-9">
              <mat-select placeholder="{{'Select Ac code'|translate}}" formControlName="acCode">
                <mat-select-filter [array]="variables" (filteredReturn)="filteredList1=$event"></mat-select-filter>
                <mat-option
                  *ngFor="let item of filteredList1"
                  [value]="item"
                  (onSelectionChange)="onChangeCode($event,item)">
                  {{item}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="uploadForm.get('acCode').hasError('required') && uploadForm.get('acCode').touched">{{'Ac ccode is required!'|translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div>
          <div class="row mb-2">
            <label class="required col-3 col-form-label" for="language">{{'Language'|translate}}</label>
            <div class="col-9">
              <select class="form-select " formControlName="language" name="language">
                <option selected value="">{{'Choose Option...'|translate}}</option>
                <option value="EN">EN</option>
                <option value="FR">FR</option>
                <option value="NL">NL</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div
              *ngIf="uploadForm.get('language').hasError('required') && uploadForm.get('language').touched"
              class="form-text text-muted col-9">{{'please select language'|translate}}</div>
          </div>
        </div>
        <div>
          <div class="row mb-2">
            <label class="required col-3 col-form-label" for="provider">{{'Locker Provider'|translate}}</label>
            <div class="col-9">
              <select class="form-select " formControlName="provider" name="provider">
                <option selected value="">{{'Choose Option...'|translate}}</option>
                <option value="bpost">Bpost</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-3"></div>
            <div
              *ngIf="uploadForm.get('provider').hasError('required') && uploadForm.get('provider').touched"
              class="form-text text-muted col-9">{{'please select locker provider'|translate}}</div>
          </div>
        </div>
        <div>
          <div class="row mb-2">
            <label class="col-3 col-form-label" for="lockername">{{'Locker Name'|translate}}</label>
            <div class="col-9">
              <input type="text" class="form-control col-12" formControlName="lockerName" id="acCode" readonly
                placeholder="{{'Locker Name'|translate}}" />
            </div>
          </div>
        </div>
        <div>
          <div class="row mb-2">
            <label class="col-3" for="lockerPostalCode">{{'Postal Code'|translate}}</label>
            <div class="col-9">
              <input
                type="text"
                class="form-control"
                formControlName="lockerPostalCode"
                id="acCode"
                readonly
                placeholder="{{'Postal Code'|translate}}" />
            </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-danger" type="Submit">{{'Upload'|translate}}</button>
        </div>
      </form>
    </div>
  </div>
  <app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
</div>