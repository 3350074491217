<app-header></app-header>
<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive  pl-4 pr-4 mt-5">
          <table class="table table-sm table-bordered table-hover row-border hover">
            <caption></caption>
            <tr>
              <th scope="row">{{'Title'|translate}}</th>
              <td scope="col">
                <input type="text" id="title" name="title" [(ngModel)]="title">
              </td>
            </tr>
            <tr>
              <th scope="row">{{'Select all functions'|translate}}</th>
              <td scope="col">
                <input type="checkbox" id="selectall" name="selectall" (change)="selectall($event)"
                  [(ngModel)]="selectAll">
              </td>
            </tr>
            <tr>
              <th scope="row">{{'Functions'|translate}}</th>
              <td scope="col" class="d-flex flex-row flex-wrap" >
                <div *ngFor="let item of checklist; let i = index" class="mb-3 col-md-4">
                  <label for="titleKeys[i]">
                    <strong>{{titleKeys[i]}}</strong>
                  </label>
                  <input type="checkbox" id="titleKeys[i]" name="titleKeys[i]" class="ms-2"
                    (change)="selectGroup(titleKeys[i],i,$event)" [(ngModel)]="groupHeading[titleKeys[i]]">
                  <div *ngFor="let key of getObjectKeys(item,i)">
                    <label for="key">{{key}}</label>
                    <input type="checkbox" id="key" name="key" class="ms-2"
                      [(ngModel)]="checklist[i][titleKeys[i]][key]" (change)="innerGroup(titleKeys[i],i,$event)">
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <br>
          <div class="text-right pb-4">
            <button class="btn text-right btn-danger"  (click)="saveDetails()">{{'Save'|translate}}</button>
            <button class="btn btn-outline-danger ms-3" (click)="cancel()">{{'Cancel'|translate}}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
