<body class="bg-dark text-white py-5">
     <div class="container py-5">
          <div class="row">
               <div class="col-md-2 text-center">
                    <p><i class="fa fa-exclamation-triangle fa-5x"></i><br/>{{'Restricted'|translate}}</p>
               </div>
               <div class="col-md-10">
                    <h3>{{'ACCESS FORBIDDEN'|translate}}</h3>
                    <p>{{'Sorry, you dont have access to this website.'|translate}}<br/>{{'Please contact the administrators to provide access to the site.'|translate}}</p>
                    <a class="btn btn-danger" (click)="_authorizationService.signOut()">{{'Go Back'|translate}}</a>
               </div>
          </div>
     </div>
</body>
