import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { UserService } from 'src/app/Service/user.service';

@Component({
  selector: 'app-partner-tools',
  templateUrl: './partner-tools.component.html',
  styleUrls: ['./partner-tools.component.scss']
})
export class PartnerToolsComponent implements OnInit {
  isJJCustomer: boolean;

  constructor(

    private readonly _authorizationService: AuthorizationService
  ) { }

  async ngOnInit() {
    const Ironing = ['J&J'];
    this._authorizationService.currentDdsUser.subscribe(user => {
      this.isJJCustomer = Ironing.includes(user?.partner?.name); 
    });
  }

}
