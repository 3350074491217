import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'utctolocaldate'
})

export class UtcToLocalDate implements PipeTransform {

  transform(date: any, format: string): any {
    if(!date){
      return '';
    }
    const dateInLocal = moment.utc(date).toDate();
    return moment(dateInLocal,'YYYY-MM-DD HH:mm:ss').format(format);
  }
}