import { Component } from '@angular/core';
import { AuthorizationService } from '../../Service/authorization.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  public userInfo;
  public authorized: boolean;
  public email: string;
  constructor(
    public _authorizationService: AuthorizationService
  ) {

  }

  loginReuseService(): void
  {
    this._authorizationService.loginReuseService();
  }

  authorizeExternal(): void
  {
    this._authorizationService.authorizeExternal();
  }
}
