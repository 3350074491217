import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppService } from '../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {
  @Input() currentCommand: any;
  onClose: Subject<any>;
  lockerFetchfail: any;
  translateData: any;
  Command: any;

  constructor(
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
    const currentComm = this.currentCommand;
    this.langaugeFetch(this.translateData, currentComm);
    this.onClose = new Subject();
  }

  close() {
    this._bsModalRef.hide();
  }

  confirm() {
    this.onClose.next('confirm');
    this._bsModalRef.hide();
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.Command = res;
      }
    );
  }

}
