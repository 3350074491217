<div class="box-container row" [appViewController]="{deliveryPoints:'displayLayout'}">
    <div *ngFor="let outerArrayItem of terminals; let i=index">
        <div class="array-index">{{i+1}}</div>
        <div *ngFor="let innerArray of outerArrayItem.compartments" class="locker-block">
            <div class="rectangular-box">
                <div class="compartment box-content" [style.height.px]="innerArray.height/3"
                    [style.width.px]="innerArray.width/2">
                    {{innerArray.number}}
                </div>
            </div>
        </div>
    </div>
</div>