import { Injectable } from '@angular/core';
import { losEnvironment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private readonly _http: HttpClient) {
    
  }

  lockerDown(body) {
    return this._http.post(
      `${losEnvironment.baseUrl}/reports/lockerdowntime`, body).toPromise();
  }
}