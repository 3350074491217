import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl, FormGroup } from '@angular/forms';
import { ParcelService } from '../../Service/parcel.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { AppService } from '../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-useroperations',
  templateUrl: './useroperations.component.html',
  styleUrls: ['./useroperations.component.scss']
})

export class UseroperationsComponent implements OnInit {
  eventTypedropdownList = [];
  eventTypedropdownSettings: IDropdownSettings;
  showSpinner = true;
  eventTypeSelected = [];

  public userOperationForm;
  result: any;
  list: any[];
  dropdownValue: any[];
  dataExport: any;
  operation: any;
  translateData: any;
  noFound: string;
  selectFields: string;
  isFromDate = false;
  isToDate = false;
  @ViewChild('dateFromInput') dateFromInput: ElementRef;
  @ViewChild('dateToInput') dateToInput: ElementRef;
  maxDate = new Date();
  constructor(
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _toastr: ToastrService,
    private readonly _ParcelService: ParcelService,
    private readonly _renderer: Renderer2,
    private readonly _datePipe: DatePipe,
    private readonly _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });

    this.userOperationForm = new FormGroup({
      dateFrom: new FormControl('', []),
      dateTo: new FormControl('', []),
      eventType: new FormControl('', [])
    });
  }
  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    return Promise.resolve(state);
  }
  onExportApiCall(obj) {
    const newArray = [];
    let newObj = {};
    const lockerLocation = this._translate.instant('Locker location');
    const imageCaptureTimestamp = 
    this._translate.instant('Image capture Timestamp');
    const downloadTimestamp = this._translate.instant('Download Timestamp');
    const userAccount = this._translate.instant('User Account');
    const date = this._translate.instant('Date');
    const UserEmail= this._translate.instant('Email');
    const Name= this._translate.instant('Name');
    const Location= this._translate.instant('Location');
    const AcCode= this._translate.instant('AcCodes');
    const Barcode= this._translate.instant('Barcode');
    const Event_Type= this._translate.instant('Event Type');
    this._ParcelService.fetchUserOperation(obj).subscribe((res: any) => {
      res.forEach(element => {
        if(this.userOperationForm.controls.eventType.value === 'CAMERA'){
          newObj[lockerLocation]= element.meta?.lockerName;
          newObj[imageCaptureTimestamp]= element?.eventDate;
          newObj[downloadTimestamp]= moment.utc(element.date).local().format(
            'YYYY-MM-DD HH:mm:ss');
          newObj[userAccount] = `${element.meta?.email},${element.meta?.uid}`;
        }
        if(this.userOperationForm.controls.eventType.value === 'LOCKER OPENED'){
          newObj[date] = (element?.eventDate)
            ?(moment.utc(element.eventDate).local().format(
              'YYYY-MM-DD HH:mm:ss')):'';
          newObj[UserEmail] = element.meta?.email;
          newObj[Name] =  element.meta?.name;
          newObj[Location] = element.meta?.lockerName;
          newObj[AcCode] = element.meta?.lockerAcCode;
          newObj[Barcode] = element.meta?.barcode;
          newObj[Event_Type] = element?.event;
        }
        newArray.push(newObj);
        newObj = {};
      });
      if (newArray.length > 0) {
        const datevar = moment().tz('Europe/Berlin').format('DD-MM-YYYY');
        this.exportToExcel(newArray, 'user_operation_report_' + datevar);
        this.noFound = '';
      } else {
        this.noFound = 'NorecordsFound';
        this.selectFields = '';
        this.langaugeFetching(this.translateData, this.noFound);
      }
    });
  }
  onExport() {
    this.selectFields = '';
    this.noFound ='';
    this.isFromDate = false;
    this.isToDate = false;
    const obj = {
      eventType: this.userOperationForm.controls.eventType.value,
      dateFrom: 
      this._datePipe.transform(this.userOperationForm.controls.dateFrom.value, 
        'yyyy-MM-dd'),
      dateTo: 
      this._datePipe.transform(this.userOperationForm.controls.dateTo.value, 
        'yyyy-MM-dd'),
    };
    if ((!obj.dateFrom && !obj.dateTo) && obj.eventType) {
      this.onExportApiCall(obj);
      this.selectFields = '';
    }
    else if ((obj.dateFrom && obj.dateTo) && !obj.eventType) {
      this.onExportApiCall(obj);
      this.selectFields = '';
    }
    else if ((obj.dateFrom && obj.dateTo) && obj.eventType) {
      this.onExportApiCall(obj);
      this.selectFields = '';
    }
    else if (!obj.dateFrom && !obj.dateTo && !obj.eventType){
      this.isFromDate = false;
      this.isToDate = false;
      this.selectFields = 'selectFieldUserOperation';
      this.langaugeFetching(this.translateData, this.selectFields);
    } else if (!obj.dateFrom ){
      this.isFromDate = true;
      this.isToDate = false;
    } else if (!obj.dateTo){
      this.isFromDate = false;
      this.isToDate = true;
    }
  }

  exportToExcel(data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);

  }

  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }

  onReset() {
    this.isFromDate = false;
    this.isToDate = false;
    this.noFound ='';
    this.selectFields='';
    this.userOperationForm.reset();
    this.userOperationForm.controls['eventType'].setValue('');
    this.userOperationForm.updateValueAndValidity();
  }
  langaugeFetching(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.noFound === 'NorecordsFound' && 
          this.selectFields !== 'selectFieldUserOperation') {
          this._toastr.warning(res);
          this.selectFields='';
        }
        if (this.noFound !== 'NorecordsFound' && 
          this.selectFields === 'selectFieldUserOperation') {
          this.noFound ='';
          this._toastr.error(res);
        }
      }
    );
  }
  openDatePicker(v) {
    if (v === 'dateFromInput'){
      this.isFromDate = false;
      this._renderer.selectRootElement(
        this.dateFromInput.nativeElement).click();
    }
    if (v === 'dateToInput'){
      this.isToDate = false;
      this._renderer.selectRootElement(this.dateToInput.nativeElement).click();
    }
  }
}

