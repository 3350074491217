import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as moment from 'moment';

@Component({
  selector: 'app-partner-history',
  templateUrl: './partner-history.component.html'
})
export class PartnerHistoryComponent implements OnInit {

  uploader: FileUploader = new FileUploader({ url: 'upload-url' });
  orderHistoryResponse: any;
  orderFound = true;
  constructor(
    private readonly _partnerservice: PartnerService,
    private readonly _router: Router,
    private readonly _spinner:SpinnerService
  ) { }

  ngOnInit(): void {
    this.getPartnerOrderHistory();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.uploader.addToQueue([file]);
    }
  }

  uploadFile(): void {
    this.uploader.uploadAll();
    this.uploader.onCompleteAll().subscribe(() => {
      console.log('File uploaded successfully');
    });
  }

  getPartnerOrderHistory() {
    this._spinner.show();
    this._partnerservice.getPartnerOrderHistory().pipe(map((
      orders: Array<any>) => {
      return orders.map((order: any) => {
        
        const retObj = { ...order };
      
        let pickedAt = order.pickedAt ?
          moment(order.pickedAt.split('Z').join('')).format('DD-MM-YYYY HH:mm:ss') : '';
          retObj.pickedAt = moment.utc(pickedAt).local().format('DD-MM-YYYY HH:mm:ss');
        return retObj;
      });
    })).subscribe(res => {
      if (res.length === 0) {
        this.orderFound = false;
      }
      else {
        this.orderFound = true;
      }
      this.orderHistoryResponse = res;
      this._spinner.hide();
    }, err => {
      this._spinner.hide();
      this.orderFound = false;
      console.error(err);
    });
  }

  viewPage(obj) {
    this._router.navigate(['/partnertools/partnerOrder/history', +obj.id]);
  }
}
