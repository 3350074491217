import { Component, OnInit ,ElementRef,Renderer2,ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Config, Api } from 'datatables.net';
import { DataTableDirective } from 'angular-datatables';

import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-camera',
  templateUrl: './cameraoperations.component.html',
  styleUrls: ['./cameraoperations.component.scss']
})
export class CameraComponent implements OnInit {
  id: string;
  showSpinner = true;
  public cameraOperationForm;
  noFound: string;
  selectFields: string;
  translateData: any;
  dtOptions:Config ={};
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;

  @ViewChild('dateFromInput') dateFromInput: ElementRef;
  maxDate = new Date();

  refreshInterval: any;

  constructor(
    private readonly _langtransService: LangtransService,
    public _activatedRoute: ActivatedRoute,
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _appService: AppService,
    private readonly _toastr: ToastrService,
    private readonly _renderer: Renderer2,
    private readonly _datePipe: DatePipe,
    private readonly _spinner : SpinnerService,
    private readonly _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this.fetchRecords();

    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });

    this.cameraOperationForm = new FormGroup({
      dateFrom: new FormControl('', []),
      download: new FormControl('', []),
      reset: new FormControl('', [])
    });
  }
  
  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    if(state === true){
      this._spinner.show();
    }else{
      this._spinner.hide();
    }
    return Promise.resolve(state);
  }
  
  fetchRecords() {
    let hasPendingJobs = false;

    let lastPage = 0;
    this.loading(true);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      searching: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        lastPage = dataTablesParameters.start;
        const id = this.id;
        const length = dataTablesParameters.length;
        const start = dataTablesParameters.start;

        this._deliverypointsService.getJobs(id, length, start)
          .then((resp) => {
            const totalCount = resp.headers.get('X-Total-Count');

            resp.body.forEach((job: any) => {
              job.completed = job.status === 'COMPLETED';
              job.cancelled = job.status === 'CANCELLED';
              job.scheduled = job.status === 'SCHEDULED';
              job.inProgress = job.status === 'IN_PROGRESS';
              job.failed = job.status === 'FAILED';
              job.pending = job.status === 'SCHEDULED' || job.status === 'IN_PROGRESS';
            });

            if (resp.body.length > 0) {
              hasPendingJobs = resp.body.some((job: any) => job.pending);
            }

            if (!hasPendingJobs && this.refreshInterval) {
              clearInterval(this.refreshInterval);
            }

            if (hasPendingJobs) {
              if (this.refreshInterval) {
                clearInterval(this.refreshInterval);
              }
              this.refreshInterval = setInterval(() => {
                this.dtElement.dtInstance.then((dtInstance: any) => {
                  dtInstance.ajax.reload();
                });
              }, 3000);
            }

            callback({
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: resp.body
            });
          })
          .finally(() => {
            this.loading(false);
          });
      },
      columns: [
        { data: 'id', title: 'ID' },
        { data: 'user.email', title: 'Requested by' },
        { data: 'options.dateFrom', title: 'Date' },
        { data: 'createdAt', title: 'Requested at' },
        { data: 'status', title: 'Status' },
        {
          data: null,
          defaultContent: '',
          render: (data, type, row) => {
            if (row.completed) {
              return `<a
                href="/lockers/${row.lockerId}/jobs/${row.id}/download"
                class="btn btn-sm btn-primary download-btn"
                data-id="${row.id}">Download</a>`;
            }
            return '';
          }
        }
      ],
      order: [[0, 'desc']]
    };

    // Attach event listener to download buttons
    $(document).on('click', '.download-btn', function (event) {
      event.preventDefault();
      const jobId = $(this).data('id');
      // const downloadUrl = `/lockers/${id}/jobs/${jobId}/download`;
      // window.open(downloadUrl, '_blank');
    });
  }

  onReset() {
    this.cameraOperationForm.reset();
  }

  onSubmit() {
    this.loading(true);
    const obj = {
      dateFrom: this._datePipe.transform(
        this.cameraOperationForm.controls.dateFrom.value, 'yyyy-MM-dd')
    };

    if (!obj.dateFrom) {
      this.selectFields = 'selectFieldCameraOperations';

      this.langaugeFetching(this.translateData, this.selectFields);
      this.loading(false);
    }
    else {
      return this._deliverypointsService.createJob(this.id, obj);
    }
  }

  openDatePicker(v) {
    if (v === 'dateFromInput') {
      this._renderer.selectRootElement(
        this.dateFromInput.nativeElement).click();
    }
  }
  
  langaugeFetching(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.noFound === 'NorecordsFound') {
          this._toastr.warning(res);
        }
        if (this.selectFields === 'selectFieldCameraOperations') {
          this._toastr.error(res);
        }
      }
    );
  }
}
