import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { PartnerService } from 'src/app/Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-home',
  templateUrl: './partner-home.component.html',
  styleUrls: ['./partner-home.component.scss']
})
export class PartnerHomeComponent implements OnInit {
  storedData: any;
  orderResult: any;
  vaultResponse: any;
  orderToProcess: any;
  locationData: any = [];
  lockerNotFree = false;
  loadingData = true;
  userInfo:any;
  constructor(
    public _router: Router,
    private _partnerservice: PartnerService,
    private _deliverypointservice: DeliverypointsService,
    private _spinner: SpinnerService,
    public _authorizationService: AuthorizationService
  ) { }

  ngOnInit(): void {
    this.storedData = JSON.parse(window.localStorage.getItem('storedValue'));
    this.getPartnerResponse();
    this.getPartnerVault();
    this.getpartnerOrderToProcess();
    this.setLocation();
  }
  getPartnerResponse() {
    this._spinner.show();
    this._partnerservice.getPartnerInfo()
      .pipe(map((orders:Array<any>)=>{
        return orders.map((order:any)=>{
          const retObj = {...order};
          retObj.reservePeriodFrom = order.reservePeriodFrom ? 
            order.reservePeriodFrom.split('Z').join(''):'';
          retObj.reservePeriodTo = order.reservePeriodTo ? 
            order.reservePeriodTo.split('Z').join(''):'';
          return retObj;
        });
      }))
      .subscribe(res=>{
        this._spinner.hide();
        this.orderResult=res;
      });
  }

  getPartnerVault() {
    this._spinner.show();
    this._partnerservice.getPartnerVault()
      .pipe(map((orders:Array<any>)=>{
        return orders.map((order:any)=>{
          const retObj = {...order};
          retObj.reservePeriodFrom = order.reservePeriodFrom ? 
            order.reservePeriodFrom.split('Z').join(''):'';
          retObj.reservePeriodTo = order.reservePeriodTo ? 
            order.reservePeriodTo.split('Z').join(''):'';
          return retObj;
        });
      }))
      .subscribe(res=>{
        this._spinner.hide();
        for (let i = 0; i < res.length; i++) {
          if (res[i].currentStatus.toUpperCase() === 
          'LASTMILE_DELIVERED_BY_POSTMAN') {
            res[i].currentStatus = 'Parcel delivered to the safe';
          }
        }
        this.vaultResponse=res;
      });
  }

  getpartnerOrderToProcess() {
    this._spinner.show();
    this._partnerservice.getPartnerOrderToProcess()
      .pipe(map((orders:Array<any>)=>{
        return orders.map((order:any)=>{
          const retObj = {...order};
          retObj.reservePeriodFrom = order.reservePeriodFrom ? 
            order.reservePeriodFrom.split('Z').join(''):'';
          retObj.reservePeriodTo = order.reservePeriodTo ? 
            order.reservePeriodTo.split('Z').join(''):'';
          return retObj;
        });
      }))
      .subscribe(res=>{
        this._spinner.hide();
        for (let i = 0; i < res.length; i++) {
          if (res[i].currentStatus.toUpperCase() === 'LM_RESERVED') {
            res[i].currentStatus = 'Safe Reserved';
          }
        }
        this.orderToProcess=res;
      });
  }

  goToNewOrder(){
    this._router.navigate(['/partnertools/newOrder']);
  }

  goToPlacedOrder(){
    this._router.navigate(['/partnertools/ordersToProcess']);
  }

  goToVault(){
    this._router.navigate(['/partnertools/ordersPlacedInVault']);
  }

  setLocation() {
    const obj = {checkCapacity:1};
    this._spinner.show();

    this._authorizationService.currentDdsUser.subscribe(user => {
      if(user){
      this.userInfo = user;

      this._partnerservice.fetchPartnerDeliverypointDetailswithPartnerId(this.userInfo.partner.id, obj).subscribe(
        (res: any) => {
          this._spinner.hide();
          if(res.hasCapacity){
            this.lockerNotFree=false;
          }
          else{
            this.lockerNotFree=true;
          }
          this.loadingData=false;
        },
        err => {
          this.loadingData=false;
          if(err.error.code==='DDS_NOT_FOUND'){
            this.lockerNotFree=true;
          }
          this._spinner.hide();
          console.error(err);
        }
      );
      }
    
    });
 
  }

}
