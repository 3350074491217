import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ddsEnvironment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  fetchLogs(id: any) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`);
  }
  filterLogs(id: any, queryString?: any) {
    Object.keys(queryString).forEach(key => queryString[key] === undefined ? 
      delete queryString[key] : {});
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, {
        params: queryString
      });
  }
  lockerDown(id: any, body: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, body);
  }
}
